import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import SnacksManagerContent from "./SnacksManagerContent";




function SnacksManager(){
 return(
    <>
    <SideNav />
    <SnacksManagerContent/>
    <Footer/>
    </>
 );
}
export default SnacksManager;
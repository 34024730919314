import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import ManagerContent from "./ManagerContent";


function Manager(){
 return(
    <>
    <SideNav />
    <ManagerContent />
    <Footer/>
    </>
 );
}
export default Manager;
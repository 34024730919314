import {React,useState,useEffect} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';

function EmployeesContent(){
  const navigate = useNavigate();
  const [recordid,setRecordID]=useState('');
  const [empid,setEmpID]=useState('');
  const [fname,setfName]=useState('');
  const [mname,setmName]=useState('');
  const [lname,setlName]=useState('');
  const [bdate,setbDate]=useState('');
  const [add1,setAdd1]=useState('');
  const [add2,setAdd2]=useState('');
  const [city,setCity]=useState('');
  const [zip,setZip]=useState('');
  const [datehired,setdateHired]=useState('');
  const [dailyrate,setdailyRate]=useState('');
  const [phealth,setpHealth]=useState('');
  const [pagibig,setpagIbig]=useState('');
  const [sss,setsss]=useState('');
  const [tin,settin]=useState('');
  const [mobile,setMobile]=useState('');
  const [emergency,setEmergency]=useState('');
  const [brachassigned,setBranchassigned]=useState('');
  const [userlevel,setUserlevel]=useState('');
  const [email,setEmail]=useState('');
  const [password,setPassword]=useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [branches, setBranches] = useState([]);
  const [levels, setLevels] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [empstatus, setEmpstatus] = useState('');
  const [emplevel, setEmplevel] = useState('');
  const handleemplevel=(e)=>{
    setEmplevel(e.target.value);
  };
  const handlefname=(e)=>{
    setfName(e.target.value);
  };
  const handlemname=(e)=>{
    setmName(e.target.value);
  };
  const handlelname=(e)=>{
    setlName(e.target.value);
  };
  const handledbdate=(e)=>{
    setbDate(e.target.value);
  };
  const handleadd1=(e)=>{
    setAdd1(e.target.value);
  };
  const handleadd2=(e)=>{
    setAdd2(e.target.value);
  };
  const handlecity=(e)=>{
    setCity(e.target.value);
  };
  const handlezip=(e)=>{
    setZip(e.target.value);
  };
  const handledatehired=(e)=>{
    setdateHired(e.target.value);
  };
  const handledailyrate=(e)=>{
    setdailyRate(e.target.value);
  };
  const handlephealth=(e)=>{
    setpHealth(e.target.value);
  };
  const handlepagibig=(e)=>{
    setpagIbig(e.target.value);
  };
  const handlesss=(e)=>{
    setsss(e.target.value);
  };
  const handletin=(e)=>{
    settin(e.target.value);
  };
  const handlemobile=(e)=>{
    setMobile(e.target.value);
  };
  const handleemergency=(e)=>{
    setEmergency(e.target.value);
  };
  const handlebranchassigned=(e)=>{
    setBranchassigned(e.target.value);
  };
  const handleuserlevel=(e)=>{
    setUserlevel(e.target.value);
  };
  const handleemail=(e)=>{
    setEmail(e.target.value);
  };
  const handlepassword=(e)=>{
    setPassword(e.target.value);
  };
  const handlestatus=(e)=>{
    setEmpstatus(e.target.value);
  };
  
  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
        
      validate();
    }

    fetchAllLevels();
    fetchAllBranches();
    fetchAllEmployees();
  }, [myToken,myTokenID]);
  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllBranches = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBranches.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         //console.log(response.data);
        setBranches(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllLevels = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllLevels.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setLevels(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllEmployees = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllEmployees.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setEmployees(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  const handleSubmit=async()=>{
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('fname', fname);
    formData.append('mname', mname);
    formData.append('lname', lname);
    formData.append('bdate', bdate);
    formData.append('add1', add1);
    formData.append('add2', add2);
    formData.append('city', city);
    formData.append('zip', zip);
    formData.append('datehired', datehired);
    formData.append('dailyrate', dailyrate);
    formData.append('phealth', phealth);
    formData.append('pagibig', pagibig);
    formData.append('sss', sss);
    formData.append('tin', tin);
    formData.append('mobile', mobile);
    formData.append('emergency', emergency);
    formData.append('branchassigned', brachassigned);
    formData.append('userlevel', userlevel);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('empID', empid);
    formData.append('status', empstatus);
    formData.append('emplevel', emplevel);
    setisSubmitting(true);

    try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/updateEmployee.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
        fetchAllEmployees();
        Swal.fire({
          title: "Great!",
          text: "Changes Saved",
          icon: "success"
        });
      }else{
        Swal.fire({
          title: "Ops!",
          text: response.data['error'],
          icon: "info"
        });
       
      }
      setisSubmitting(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }

  };
const handleGet=(ids,empIDs,fnames,mnames,lnames,bdates,add1s,add2s,citys,zips,datehireds,dailyrates,phealths,
  pagibigs,ssss,tins,mobiles,emergencys,branchassigneds,userlevels,emails,statuss,emplevels)=>{
    setEmplevel(emplevels);
  setRecordID(ids);
  setEmpID(empIDs);
  setfName(fnames);
  setmName(mnames);
  setlName(lnames);
  setbDate(bdates);
  setAdd1(add1s);
  setAdd2(add2s);
  setCity(citys);
  setZip(zips);
  setdateHired(datehireds);
  setdailyRate(dailyrates);
  setpHealth(phealths);
  setpagIbig(pagibigs);
  setsss(ssss);
  settin(tins);
  setMobile(mobiles);
  setEmergency(emergencys);
  setBranchassigned(branchassigneds);
  setUserlevel(userlevels);
  setEmail(emails);
  setEmpstatus(statuss);

  
};
const bg = empstatus === 1 ? "bg-success" : "bg-danger";
const [searchQuery, setSearchQuery] = useState('');

 // Filtered ticket orders based on the search query
 const filteredOrders = employees.filter(item =>
   item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
   item.middle_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
   item.last_name.toLowerCase().includes(searchQuery.toLowerCase())
 );
return(
    <>
    <main id="main" className="main">
            <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Employee Information</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row">

                      
                        <div class="col-xxl-6 col-md-6">
                          <div class="card info-card sales-card">

                            

                          <div class="card">
                            <div class="card-body">
                              <h5 class="card-title">Personal Information</h5>

                            
                              <div class="row g-3">
                                <div class="col-md-6">
                                  <label for="fName" class="form-label">First Name</label>
                                  <input type="text" value={fname} onChange={handlefname} class="form-control" id="fName" name="fName"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="mName" class="form-label">Middle Name</label>
                                  <input type="text" value={mname} onChange={handlemname} class="form-control" id="mName" name="mName"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="lName" class="form-label">Last Name</label>
                                  <input type="text" value={lname} onChange={handlelname} class="form-control" id="lName" name="lName"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="bdate" class="form-label">Birthdate</label>
                                  <input type="date" value={bdate} onChange={handledbdate} class="form-control" id="bdate" name="bdate"/>
                                </div>
                                
                                
                                <div class="col-12">
                                  <label for="inputAddress5" class="form-label">Address</label>
                                  <input type="text" value={add1} onChange={handleadd1} class="form-control" id="inputAddres5s" placeholder="1234 Main St"/>
                                </div>
                                <div class="col-12">
                                  <label for="inputAddress2" class="form-label">Address 2</label>
                                  <input type="text" value={add2} onChange={handleadd2} class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="inputCity" class="form-label">City</label>
                                  <input type="text" value={city} onChange={handlecity} class="form-control" id="inputCity"/>
                                </div>
                                
                                <div class="col-md-2">
                                  <label for="inputZip" class="form-label">Zip</label>
                                  <input type="text" value={zip} onChange={handlezip} class="form-control" id="inputZip"/>
                                </div>
                                <div class="col-md-4">
                                  <label for="dhired" class="form-label">Date Hired</label>
                                  <input type="date" value={datehired} onChange={handledatehired} class="form-control" id="dhired" name="dhired"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="rate" class="form-label">Daily Rate</label>
                                  <input type="text" value={dailyrate} onChange={handledailyrate} class="form-control" id="rate" name="rate"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="rate" class="form-label">Status</label>
                                  
                                  <select id="status" className={`form-control text-light ${bg}`} value={empstatus} onChange={handlestatus} name="status">
                                      <option key={1} value={1}>Active</option>
                                      <option key={0} value={0}>Inactive</option>
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label for="rate" class="form-label">Employee Level</label>
                                  <select value={emplevel} onChange={handleemplevel} class="form-control">
                                    <option key={0} value={0}>Select Employee Level</option>
                                    <option key={1} value={1}>Regualar</option>
                                    <option key={2} value={2}>Probationary</option>
                                    <option key={3} value={3}>Intern</option>
                                  </select>
                                 
                                </div>
                                
                              </div>

                            </div>
                          </div>

                          </div>
                        </div>

                      
                        <div class="col-xxl-6 col-md-6">
                          <div class="card info-card sales-card">
                          <div class="card">
                            <div class="card-body">
                              

                            
                              <div class="row g-3">
                              <h5 class="card-title">Government Mandatories</h5>
                                <div class="col-md-6">
                                  <label for="phealth" class="form-label">PhilHealth</label>
                                  <input type="text" value={phealth} onChange={handlephealth} class="form-control" id="phealth" name="phealth"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="pagibig" class="form-label">Pag-ibig</label>
                                  <input type="text" value={pagibig} onChange={handlepagibig} class="form-control" id="pagibig" name="pagibig"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="sss" class="form-label">SSS</label>
                                  <input type="text" value={sss} onChange={handlesss} class="form-control" id="sss" name="sss"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="tin" class="form-label">TIN</label>
                                  <input type="text" value={tin} onChange={handletin} class="form-control" id="tin" name="tin"/>
                                </div>
                                
                              <div class="row g-3">
                              <h5 class="card-title">Contact</h5>
                                <div class="col-md-6">
                                  <label for="mobile" class="form-label">Mobile Number</label>
                                  <input type="text" value={mobile} onChange={handlemobile} class="form-control" id="mobile" name="mobile"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="incase" class="form-label">In Case of Emergency</label>
                                  <input type="text" value={emergency} onChange={handleemergency} class="form-control" id="incase" name="incase"/>
                                </div>
                              </div>
                                
                              <h5 class="card-title">System Role</h5>
                              <div class="row g-3">
                                <div class="col-md-6">
                                  <label for="branch" class="form-label">Branch</label>
                                  <select value={brachassigned} onChange={handlebranchassigned} class="form-control" id="branch" name="branch">
                                  <option key={0} value={0} disabled selected>select branch</option>
                                    {branches.map(item=>(
                                      <option key={item.id} value={item.id}>{item.branch_name}</option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label for="ulevel" class="form-label">User Level</label>
                                  <select value={userlevel} onChange={handleuserlevel} class="form-control" id="ulevel" name="ulevel">
                                    <option key={0} value={0} disabled selected>select user level</option>
                                    {levels.map(item=>(
                                      <option key={item.id} value={item.id}>{item.user_level}</option>
                                    ))}
                                  </select>
                                </div>
                                <div class="col-md-6">
                                  <label for="inputEmail5" class="form-label">Email</label>
                                  <input type="email" value={email} onChange={handleemail} class="form-control" id="inputEmail5"/>
                                </div>
                                <div class="col-md-6">
                                  <label for="inputPassword5" class="form-label">Password</label>
                                  <input type="password" onChange={handlepassword} class="form-control" id="inputPassword5"/>
                                </div>
                                
                              </div>
                              </div>

                            </div>

                            
                          </div>
                         
                          

                          </div>
                        </div>

                      </div>
                                    
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting?true:false} >Save changes {isSubmitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Employees</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Employees</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-lg-8"><h5 className="card-title">Registered</h5></div>
                    <div className="col-lg-4"><input type="text" id="search" 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="search by name" name="search" className="form-control"/></div>
                </div>
            
            </div>
           
            <div className="card-body" style={{overflowY:'scroll', maxHeight:450}}>
             
                
              <table className="table datatable">
                <thead>
                  <tr>
                    <th scope="col">ID #</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mobile #</th>
                    <th scope="col">Daily Rate</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>

                {isLoading ? (
                            
                            <tr>
                               <th scope="row"><div className="waving col-lg-12"></div></th>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td>
                                <div className="waving col-lg-4"></div>
                                </td>
                            </tr>
                        ) : (
                          filteredOrders.map(item => {
                            let status = "";
                            let badgeClass = "";
        
                            if (item.status === 1) {
                              status = "Active";
                              badgeClass = "bg-success";
                            } else {
                              status = "Inactive";
                              badgeClass = "bg-danger";
                            }
        
                            return (
                              <tr key={item.id}>
                                <th scope="row">{item.empID}</th>
                                <td>{item.first_name} {item.middle_name} {item.last_name}</td>
                                <td><a href={`tel:${item.mobile}`} className="text-primary">{item.mobile}</a></td>
                                <td>&#8369;{item.daily_rate}</td>
                                <td><span className={`badge ${badgeClass}`}>{status}</span></td>
                                <td>
                                  <button className="btn btn-default" onClick={()=>handleGet(item['id'],item['empID'],item['first_name'],item['middle_name'],item['last_name'],item['birthdate'],item['address1'],item['address2'],item['city'],item['zip'],item['datehired'],item['daily_rate'],item['philHealth'],item['pagibig'],item['sss'],item['tin'],item['mobile'],item['incase_of_emergency'],item['branchID'],item['user_level'],item['email'],item['status'],item['employee_level'])} data-bs-toggle="modal" data-bs-target="#verticalycentered"><i className="fa fa-eye"></i></button>
                                  
                                </td>
                              </tr>
                            );
                          })
                        )}
               

                 
                  
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default EmployeesContent;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import ServerContent from "./ServerContent";


function Server(){
 return(
    <>
    <SideNav />
    <ServerContent/>
    <Footer/>
    </>
 );
}
export default Server;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import DecoratorContent from "./DecoratorContent";



function Decorator(){
 return(
    <>
    <SideNav />
    <DecoratorContent />
    <Footer/>
    </>
 );
}
export default Decorator;
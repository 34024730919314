import {React,useState,useEffect} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';

function ManagerContent(){
    const navigate = useNavigate();
    const link='https://megaxsolutions.com/api/middleware/uploads/';
    const [branches, setBranches] = useState([]);
    const [branchselect, setBranchselect] = useState([]);
    const [cakes, setCakes] = useState([]);
    const [deliveredinventory, setDeliveredinventory] = useState([]);
    const [deliveryinventory, setDeliveryinventory] = useState([]);
    const [bakerinventory, setBakerinventory] = useState([]);
    const [icinginventory, setIcinginventory] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [prodinvID, setprodinvID] = useState('');
    const [cakesizeselect, setSizeselect] = useState('');
    const [cakeidselect, setCakeselect] = useState('');
    const [quantityselect, setQuantityselect] = useState('');
    const [myToken, setToken] = useState('');
    const [myTokenID, setTokenID] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [caketype, setCaketype] = useState('');
    const [cakesize, setCakesize] = useState('');
    const [currentquantity, setCurrentquantity] = useState('');
    const [decorID, setDecorID] = useState('');
    const [deliveryID, setDeliveryID] = useState('');
    const [damage, setDamage] = useState('');
    useEffect(() => {
      // Get cookie by name
      const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      };
  
      // Get token from cookie
    
     const token = getCookie('token');
     const tokenID = getCookie('tokenID');
     setToken(token);
     setTokenID(tokenID);
      if (token) {
         
        validate();
      }
      fetchAllCakes();
      fetchBakerinventory();
      fetchAllBranches();
    }, [myToken,myTokenID]);
    const validate = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      setisLoading(true);
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
           console.log(response.data);
          
          if(response.data[0]['success']==1){
           
          }else{
            navigate('/login');
          }
          setisLoading(false);
      } catch (error) {
          console.error('Error submitting form', error);
      }
    };
      

      const handleQuantityChange=(e)=>{
        var quantitytoput=e.target.value;

        setQuantity(e.target.value);
        setCurrentquantity(quantityselect-quantitytoput);
        console.log(currentquantity);
      };
      const handleDamageChange=(e)=>{
        setDamage(e.target.value);
      };
      
     
    
      const handleSubmit = async (e) => {
          e.preventDefault();
          setSubmitting(true);
          const formData = new FormData();
          formData.append('damage', damage);
          formData.append('deliveryID', deliveryID);
          formData.append('decorID', decorID);
          formData.append('prodinvID', prodinvID);
          formData.append('quantity', quantity);
          formData.append('cakeid', cakeidselect);
          formData.append('cakesize', cakesizeselect);
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
        
        
        if(currentquantity<=0){
          alert("All Cakes has already been set to received");
          setSubmitting(false);
          return;
        }
        if(damage==null || damage==''){
            alert("set 0 if no damage or lacking");
            setSubmitting(false);
            return;
          }
        if(quantity>quantityselect){
            alert("Quantity should not be greater than number of cakes to receive");
            setSubmitting(false);
            return;
        }
        if(quantity==0 || quantity==null){
            alert("Please input quantity");
            setSubmitting(false);
            return;
        }
        
          try {
              const response = await axios.post('https://megaxsolutions.com/api/middleware/addbranchinventory.php', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              });
              console.log(response.data);
              if(response.data['success']){
                Swal.fire({
                  title: "Great!",
                  text: response.data['success'],
                  icon: "success"
                });
                setQuantity("");
                setSubmitting(false);
                fetchDeliveryinventory();
                setCurrentquantity(currentquantity-quantity);
                console.log(currentquantity);
              }else{
                Swal.fire({
                  title: "Ops!",
                  text: response.data['error'],
                  icon: "error"
                });
              }
              
          } catch (error) {
              console.error('Error submitting form', error);
          }
      };
  
  
      
    
  
    const fetchCakes = async () => {
        const formData = new FormData();
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/getcakes.php', formData, {
                headers: {
                    'Content-Type': 'application/form-data'
                }
            });
            console.log(response.data);
            setCakes(response.data);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
  
    const fetchSizes = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/getsizes.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          console.log(response.data);
          
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };
  const fetchAllBranches = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBranches.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
       // console.log(response.data);
        setBranches(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllCakes = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getallcakes.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  
  
  
   const handleGet =(id,decoratorID,prodinvID,cakeID,sizeID,caketype,cakesize,quantityselected,deliveredq)=>{
      setDeliveryID(id);
      setDecorID(decoratorID);
      setprodinvID(prodinvID);
      setCakeselect(cakeID);
      setSizeselect(sizeID);
      setCaketype(caketype);
      setCakesize(cakesize);
      setQuantityselect(deliveredq);
      setQuantity(deliveredq);
      setCurrentquantity(deliveredq);
      setDamage('0');
      console.log(prodinvID,cakeID,sizeID,caketype,cakesize,quantityselected,deliveredq);
   };   
   
   const fetchBakerinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllinventoryfrombaker.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setBakerinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handlebakerinventory=()=>{
    fetchBakerinventory();
  };

  const fetchIcinginventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllIcinginventory.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setIcinginventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handleicinginventory=()=>{
    fetchIcinginventory();
  };
  const fetchDeliveryinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllDeliveryinventoryperbranch.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setDeliveryinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handledeliveryinventory=()=>{
    fetchDeliveryinventory();
  };

  const fetchDeliveredinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllInventorydelivered.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setDeliveredinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handledeliveredinventory=()=>{
    fetchDeliveredinventory();
  };

  
  const formatDate = (datei) => {
    const date =new Date(datei);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  };
  
return(
    <>
    <main id="main" className="main">

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Manage Items</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Items</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
      <div className="card">
            <div className="card-body">
              

             
              <ul className="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified" role="tablist">
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={handlebakerinventory}>Cakes Baked</button>
                </li>
               
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100" id="decoration-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-decoration" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={handleicinginventory}>Cakes w/ Icing</button>
                </li>
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100" id="contact-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-contact" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={handledeliveryinventory}>Cakes for Delivery</button>
                </li>
               
                
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100" id="contact-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-cakesuccess" type="button" role="tab" aria-controls="cakesuccess" aria-selected="false" onClick={handledeliveredinventory}>Cakes Delivered</button>
                </li>
               
              </ul>
          <div className="tab-content pt-2" id="borderedTabJustifiedContent">
            <div className="tab-pane fade show active" id="bordered-justified-home" role="tabpanel" aria-labelledby="home-tab">
              
                  <div className="col-lg-12">
                        <div className="row" style={{maxHeight:400, overflowY: 'scroll'}}>
                        
                        {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                            bakerinventory.map(inv=>{

                                const r=inv['quantity']-inv['icingcount'];
                                if(r>0){
        
                                
                                return (
                                   
                                    <div className="col-lg-12 rounded shadow p-1">
                                      <div className="row">
                                      <div className="col-lg-2 col-sm-12 mt-2 rounded text-center" style={{backgroundImage: "url("+link+inv['photo']+")",backgroundSize: 'cover',backgroundPosition: 'center',maxHeight:300}}></div>
                                      <div className="col-lg-6 mt-2 rounded"><strong>{inv['cake_type']}</strong>  <br/>size: {inv['size']} <br/> Quantity: {inv['quantity']}, Remaining: {inv['quantity']-inv['icingcount']}, W/ Icing: {inv['icingcount']-inv['deliverycount']}, For Delivery:{inv['deliverycount']}   <br/> Date: {formatDate(inv['date_added'])}</div>
                                        
                                      </div>
                                    </div>
                                  )}})
                        )}
                        
                        
                        
                        
                        
                         </div>
                  </div>

                  </div>
                <div className="tab-pane fade" id="bordered-justified-profile" role="tabpanel" aria-labelledby="profile-tab">
                 Snacks
                 </div>
                 <div className="tab-pane fade" id="bordered-justified-decoration" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="row" style={{maxHeight:400, overflowY: 'scroll'}}>
                    {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                            icinginventory.map(inv=>{
                                const availableQuantity = inv['quantity'] - inv['deliverycount'];
        
                                if (availableQuantity !== 0) {
                                return (
                                    <div className="col-lg-12 rounded shadow p-1">
                                      <div className="row">
                                      <div className="col-lg-2 col-sm-12 mt-2 rounded text-center" style={{backgroundImage: "url("+link+inv['photo']+")",backgroundSize: 'cover',backgroundPosition: 'center',maxHeight:300}}></div>
                                      <div className="col-lg-4 mt-2 rounded"><strong>{inv['cake_type']}</strong>  <br/>size: {inv['size']} <br/> Quantity: {inv['quantity']-inv['deliverycount']} <br/> Date: {formatDate(inv['date_added'])}</div>
                                       
                                      </div>
                                    </div>
                                  )}else{
                                    return null;
                                  }
                                  
                                  })
                        )}
                        
                         </div>

                        
                  </div>
                <div className="tab-pane fade" id="bordered-justified-contact" role="tabpanel" aria-labelledby="decoration-tab">
                <div className="row" style={{maxHeight:400, overflowY: 'scroll'}}>
                {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                            deliveryinventory.map(inv=>{
                                var r=inv['quantity']-inv['deliverycount'];
                                if (r>0) {
                                return (
                                    <div className="col-lg-12 rounded shadow p-1">
                                      <div className="row">
                                        <div className="col-lg-2 col-sm-12 mt-2 rounded text-center" style={{backgroundImage: "url("+link+inv['photo']+")",backgroundSize: 'cover',backgroundPosition: 'center',maxHeight:300}}></div>
                                        <div className="col-lg-4 mt-2 rounded"><strong>{inv['cake_type']}</strong>  <br/>size: {inv['size']} <br/> Quantity Remaining: {inv['quantity']-inv['deliverycount']} <br/> Destination: {inv['branch_name']} <br/>Date: {formatDate(inv['date_added'])}</div>
                                        <div className="col-lg-4 mt-2 rounded">
                                        <button type="button" className="btn btn-success" onClick={() => handleGet(inv['id'],inv['decoratorID'],inv['prodinvID'],inv['cakeID'],inv['sizeID'],inv['cake_type'],inv['size'],inv['quantity'],inv['quantity']-inv['deliverycount'])} data-bs-toggle="modal" data-bs-target="#verticalycentered">
                                            Receive <i className="fa fa-check"></i>
                                        </button>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  )}else{
                                    return null;
                                  }
                                  
                                  })
                        )}
                        
                        <div className="modal fade" id="verticalycentered" tabIndex="-1">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">To Receive</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                              <span>Cake Type: {caketype}</span><br/>
                              <span>Size: {cakesize}</span><br/>
                              <span>Quantity w/ Icing: {quantityselect}</span><br/>
                              
                              
                                <hr/>
                                <input type="text" 
                                value={quantity}
                                onChange={handleQuantityChange}
                                id="quantity" name="quantity" placeholder="Enter quantity of received items" className="form-control"/>
                                <hr/>
                                <input type="text" 
                                value={damage}
                                onChange={handleDamageChange}
                                id="damage" name="damage" placeholder="Enter quantity lacking or damage" className="form-control"/>
                                <hr/>
                              
                              </div>
                                <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" disabled={submitting?true:false} onClick={handleSubmit} >Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                                </div>
                              </div>
                            </div>
                        </div>  
                        
                  </div>
                </div>
                
                <div className="tab-pane fade" id="bordered-justified-cakesuccess" role="tabpanel" aria-labelledby="cakesuccess-tab">
                <div className="row" style={{maxHeight:400, overflowY: 'scroll'}}>
                {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                            deliveredinventory.map(inv=>{
                                if (inv['status'] == 'received') {
                                return (
                                    <div className="col-lg-12 rounded shadow p-1">
                                      <div className="row">
                                      <div className="col-lg-2 col-sm-12 mt-2 rounded text-center" style={{backgroundImage: "url("+link+inv['photo']+")",backgroundSize: 'cover',backgroundPosition: 'center',maxHeight:300}}></div>
                                      <div className="col-lg-4 mt-2 rounded"><strong>{inv['cake_type']}</strong>  <br/>size: {inv['size']} <br/> Quantity: {inv['branchcount']} <br/> Destination: {inv['branch_name']} <br/>Date: {formatDate(inv['date_added'])}</div>
                                        <div className="col-lg-4 mt-2 rounded"><strong>Received By: {inv['receiver']}</strong> <br/> Date Received: {formatDate(inv['date_received'])} </div>
                                      </div>
                                    </div>
                                  )}else{
                                    return null;
                                  }
                                  
                                  })
                        )}
                        
                  </div>
                
                  </div>
               
              </div>

            </div>
          </div>
        
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default ManagerContent;
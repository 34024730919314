import React from "react";
import SideNav from "./SideNav";
import AddemployeeContent from "./AddemployeeContent";
import Footer from "./Footer";
function Addemployee(){
 return(
    <>
    <SideNav />
    <AddemployeeContent />
    <Footer/>
    </>
 );
}
export default Addemployee;
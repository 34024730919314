import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import OrdertakingContent from "./OrdertakingContent";

function Ordertaking(){
 return(
    <>
    <SideNav />
    <OrdertakingContent />
    <Footer/>
    </>
 );
}
export default Ordertaking;
import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
function SnacksContent(){
  const navigate = useNavigate();
  const buttonRef = useRef();
  const buttonRef2 = useRef();
  const [cakes, setCakes] = useState([]);
  const [allcakes, setAllCakes] = useState([]);
  const [cakesSizes, setCakeSizes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [caketype, setCaketype] = useState('');
  const [cakesize, setSize] = useState('');
  const [cakeprice, setCakePrice] = useState('');
  const [snackprice, setSnackPrice] = useState('');
  const [priceidselected, setCakePriceid] = useState('');
  const [cakesizeselect, setSizeselect] = useState('');
  const [cakeidselect, setCakeselect] = useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [cakephoto, setCakephoto] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const [ctphoto, setPhotoID] = useState('');
  const [imageUrl, setImageUrl] = useState("https://megaxsolutions.com/api/middleware/uploads/" + ctphoto); // default image URL
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
       
      validate();
    }
    fetchAllCakes();
    fetchCakes();
  fetchSizes();
  }, [myToken,myTokenID]);

  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
    const handleCakeselect=(e)=>{
      setCakeselect(e.target.value);
    };
    const handleCakePrice=(e)=>{
      setCakePrice(e.target.value);
    };
    const handleSnackPrice=(e)=>{
      setSnackPrice(e.target.value);
    };
    const handleSizeselect =(e)=>{
      setSizeselect(e.target.value);
    };
    const handleCaketypeChange = (e) => {
        setCaketype(e.target.value);
    };
    const handleSizeChange = (e) => {
      setSize(e.target.value);
  };

    const handleCakephotoChange = (e) => {
        setCakephoto(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('caketype', caketype);
        formData.append('cakephoto', cakephoto);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addsnacks.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              alert(response.data['success']);
              setCaketype("");
              setCakephoto(null);
              
            }else{
              alert(response.data['error']);
            }
            setSubmitting(false);
            
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };


    const handleSubmitSize = async (e) => {
      e.preventDefault();
      setSubmitting(true);
      const formData = new FormData();
      formData.append('cakesize', cakesize);
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      if(cakesize=='' || cakesize==null){
        setSubmitting(false);
        return;
      }
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/addsnacksize.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          
          if(response.data['success']){
            alert(response.data['success']);
            setSize("");
            
          }else{
            alert(response.data['error']);
          }
          setSubmitting(false);
          
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };
  const handleSubmitPrice = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append('cakeprice', cakeprice);
    formData.append('cakeid', cakeidselect);
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    if(cakeprice=='' || cakeprice==null || cakeidselect==''){
      setSubmitting(false);
      alert("Please select cake type and price");
      return;
    }
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/addsnacksprice.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
          
        if(response.data['success']){
          Swal.fire({
            title: "Great!",
            text: "Price updated",
            icon: "success"
          });
          //triggerButtonClick2();
          setCakePrice("");
          fetchAllCakes();
        }else{
          alert(response.data['error']);
        }
        setSubmitting(false);
        
    } catch (error) {
        console.error('Error submitting form', error);
    }
};

  const fetchCakes = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/getsnacks.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          console.log(response.data);
          setCakes(response.data);
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };

  const fetchSizes = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getsnacksizes.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setCakeSizes(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
};
const handleSubmitupdatePrice = async () => {
  const formData = new FormData();
  formData.append('priceID', priceidselected);
  formData.append('snackID', cakeidselect);
  formData.append('price', snackprice);
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
  console.log(cakeidselect);
  console.log(snackprice);
  setSubmitting(true);

  if(snackprice==='' || snackprice===null){
    alert("Requires price amount");
    setSubmitting(false);
    return;
    
  }
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/updatesnackprice.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
        Swal.fire({
          title: "Great!",
          text: "Price updated",
          icon: "success"
        });
        fetchAllCakes();
        triggerButtonClick();
      }else{
        Swal.fire({
          title: "Ops!",
          text: "Something went wrong. check your connection "+response.data['error'],
          icon: "info"
        });
      }

      setSubmitting(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};

const fetchAllCakes = async () => {
  const formData = new FormData();
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
  setisLoading(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllsnacks.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      setAllCakes(response.data);
      setisLoading(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};



 const handleget =()=>{
  fetchCakes();
  fetchSizes();
 };   
 const editPrice=(snackid,priceid,price,photo)=>{
  setCakeselect(snackid);
  if(price==='' || price===null){
    setSnackPrice(snackprice);
  }else{
    setSnackPrice(price);
  }
  
  setCakePriceid(priceid);
  setPhotoID(photo);
  let previewUrl = "https://megaxsolutions.com/api/middleware/uploads/"+photo;
  setImageUrl(previewUrl);
 };
 const triggerButtonClick2 = () => {
  if (buttonRef2.current) {
    buttonRef2.current.click();
  }
};
 const triggerButtonClick = () => {
  if (buttonRef.current) {
    buttonRef.current.click();
  }
};
const handleFileChange = async(event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    setFile(selectedFile);

    // Create a URL to display the selected file before upload
    const previewUrl = URL.createObjectURL(selectedFile);
    setImageUrl(previewUrl);

    try {
      setSubmitting(true);
      const formData = new FormData();
      console.log(cakeidselect);
      formData.append('snackID', cakeidselect);
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      formData.append('cakephoto', selectedFile);
     
      
      const response = await axios.post('https://megaxsolutions.com/api/middleware/updateSnackphoto.php', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Type': 'multipart/form-data'
        }
      });
      
    console.log(response.data);
      if(response.data['success']){
        
        
          
      }else{
        Swal.fire({
            title: "Ops!",
            text: "Something went wrong. check your connection "+response.data['error'],
            icon: "info"
          });
      }
      setSubmitting(false);
      
    } catch (error) {
      console.error('Error submitting form', error);
      
    }

  }
};
const removeSnack=(priceid)=>{
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Remove this item"
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {

        const formData = new FormData();
        
        formData.append('priceID', priceid);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);

        setRemoving(true);
        
        const response = await axios.post('https://megaxsolutions.com/api/middleware/removeSnacksizeprice.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        
      console.log(response.data);
        if(response.data['success']){
          
          fetchAllCakes();
            
        }else{
          Swal.fire({
              title: "Ops!",
              text: "Something went wrong. check your connection "+response.data['error'],
              icon: "info"
            });
        }
        setRemoving(false);
        
      } catch (error) {
        console.error('Error submitting form', error);
        setSubmitting(false); // Ensure this is called even if there's an error
      }
    }
  });
 };
 const [searchQuery, setSearchQuery] = useState('');
 const filteredSnacks = allcakes.filter(item =>
   item.snack_type.toLowerCase().includes(searchQuery.toLowerCase())
 );
return(
    <>
    <main id="main" className="main">
    <div className="modal fade" id="price" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Snacks Price</h5>
                      <button type="button" ref={buttonRef2} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <select className="form-control" id="cakeID" name="cakeID" onChange={handleCakeselect} value={cakeidselect}>
                        <option>select snack type</option>
                        {cakes.map(cake => (
                            <option key={cake.id} value={cake.id}>{cake.snack_type}</option>
                        ))}
                      </select>
                      <hr/>
                      
                   
                      <input  type="text" id="price" className="form-control" value={cakeprice} onChange={handleCakePrice} name="price" placeholder="price"/>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitPrice} >Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>

    <div className="modal fade" id="updateprice" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Update Snacks</h5>
                      <button type="button" ref={buttonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="justify-content-center mb-2">
                        <div className="item d-flex">
                        <img src={imageUrl} style={{borderRadius: '10px', marginLeft:'20px',maxHeight:'140px',justifyContent:'center'}}/>
                        <input type="file"  onChange={handleFileChange} style={{marginLeft:'20px',marginTop:'50px'}}/>
                      
                        </div> {submitting?(<><i className="fa fa-spinner fa-spin"></i> <i>Updating photo..</i> </>):""}
                        
                        
                    </div>
                      <select disabled='true' className="form-control" id="cakeID" name="cakeID" onChange={handleCakeselect} value={cakeidselect}>
                        <option>select snack type</option>
                        {cakes.map(cake => (
                            <option key={cake.id} value={cake.id}>{cake.snack_type}</option>
                        ))}
                      </select>
                      <hr/>
                      
                   
                      <input  type="text" id="price" className="form-control" value={snackprice} onChange={handleSnackPrice} name="price" placeholder="price"/>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitupdatePrice} >Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Snacks</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Snacks</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
       
        <div className="col-12">
          <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-lg-8">
                        <h5 className="card-title">Snacks</h5>
                   
                    <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#verticalycentered">
                    Add Snacks <i className="fa fa-plus-circle"></i>
              
              </button> | <button type="button" onClick={handleget} className="btn btn-success" data-bs-toggle="modal" data-bs-target="#price">
                    Set Price <i className="fa fa-plus-circle"></i>
              </button>

              <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add New Snacks Type</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <input type="text" 
                       value={caketype}
                       onChange={handleCaketypeChange}
                       id="caketype" name="caketype" placeholder="Enter type" className="form-control"/>
                      <hr/>
                      <input type="file" id="cakephoto" onChange={handleCakephotoChange}  name="cakephoto"/>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" disabled={submitting? true:false} onClick={handleSubmit} >Save changes {submitting? (<i className="fa fa-spinner fa-spin"></i>):""}</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="sizes" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add New Snacks Size</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <input type="text" 
                       value={cakesize}
                       onChange={handleSizeChange}
                       id="cakesize" name="cakesize" placeholder="Enter Size" className="form-control"/>
                      <hr/>
                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitSize} >Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>

              
                    </div>
                    <div className="col-lg-4"><input type="text" id="search" 
                     value={searchQuery}
                     onChange={(e) => setSearchQuery(e.target.value)} placeholder="search by type" name="search" className="form-control"/></div>
                </div>
            
            </div>
           
            <div className="card-body" style={{overflowY:'scroll', maxHeight:450}}>
             
                
              <table className="table datatable">
                <thead>
                  <tr>
                    <th scope="col">Item ID</th>
                    <th scope="col">Snack Type</th>
                    <th scope="col">Price</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                   {isLoading?(<div>Loading..</div>):''}     
                  {filteredSnacks.map(cakes=>(
                    <tr>
                      <th scope="row" key={cakes.id}>{cakes.id}</th>
                      <td>{cakes.snack_type}</td>
                    
                       <td>{cakes.price}</td>
                       <td>
                      {cakes.id?(<>
                        <button className="btn btn-default" onClick={()=>editPrice(cakes.snackID,cakes.id,cakes.price,cakes.photo)}  data-bs-toggle="modal" data-bs-target="#updateprice"><i className="fa fa-pencil"></i></button>
                      <button className="btn btn-default"><i className="fa fa-times" onClick={()=>removeSnack(cakes.id)}></i></button>
                     
                      </>):(<>
                        <i>No Price set yet.</i>
                      </>)}
                       </td>
                    </tr>
                  ))}
                
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default SnacksContent;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import SalesHistoryContent from "./SalesHistoryContent";



function SalesHistory(){
 return(
    <>
    <SideNav />
   <SalesHistoryContent/>
    <Footer/>
    </>
 );
}
export default SalesHistory;
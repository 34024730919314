import {React,useState,useEffect} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function ScheduleContent(){
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());  
  const [empid,setEmpID]=useState('');
  const [fname,setfName]=useState('');
  const [lname,setlName]=useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isGetting, setisGetting] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [branches, setBranches] = useState([]);
  const [levels, setLevels] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [monstarttime, setmonStarttime] = useState('');
  const [monendtime, setmonEndtime] = useState('');
  const [tuesstarttime, settuesStarttime] = useState('');
  const [tuesendtime, settuesEndtime] = useState('');
  const [wedstarttime, setwedStarttime] = useState('');
  const [wedendtime, setwedEndtime] = useState('');
  const [thustarttime, setthuStarttime] = useState('');
  const [thuendtime, setthuEndtime] = useState('');
  const [fristarttime, setfriStarttime] = useState('');
  const [friendtime, setfriEndtime] = useState('');
  const [satstarttime, setsatStarttime] = useState('');
  const [satendtime, setsatEndtime] = useState('');
  const [sunstarttime, setsunStarttime] = useState('');
  const [sunendtime, setsunEndtime] = useState('');
  const [schedset, setSchedset] = useState('');
  const [monstate, setmonState] = useState('');
  const [tuestate, settueState] = useState('');
  const [wedstate, setwedState] = useState('');
  const [thustate, setthuState] = useState('');
  const [fristate, setfriState] = useState('');
  const [satstate, setsatState] = useState('');
  const [sunstate, setsunState] = useState('');
  const [schedules, setSchedule] = useState([]);  
  const [myshift, setShift] = useState('');
  

  //schedule state reg/off
  const handleschedset=(e)=>{
    setSchedset(e.target.value);
    console.log(e.target.value);
  };
  const handlemonstate=(e)=>{
    setmonState(e.target.value);
  };
  const handletuestate=(e)=>{
    settueState(e.target.value);
  };
  const handlewedstate=(e)=>{
    setwedState(e.target.value);
  };
  const handlethustate=(e)=>{
    setthuState(e.target.value);
  };
  const handlefristate=(e)=>{
    setfriState(e.target.value);
  };
  const handlesatstate=(e)=>{
    setsatState(e.target.value);
  };
  const handlesunstate=(e)=>{
    setsunState(e.target.value);
  };

//start and end time
  const handlemonstarttime=(e)=>{
    setmonStarttime(e.target.value);
  };
  const handlemonendtime=(e)=>{
    setmonEndtime(e.target.value);
  };

  const handletuesstarttime=(e)=>{
    settuesStarttime(e.target.value);
  };
  const handletuesendtime=(e)=>{
    settuesEndtime(e.target.value);
  };

  const handlewedstarttime=(e)=>{
    setwedStarttime(e.target.value);
  };
  const handlewedendtime=(e)=>{
    setwedEndtime(e.target.value);
  };
  const handlethustarttime=(e)=>{
    setthuStarttime(e.target.value);
  };
  const handlethuendtime=(e)=>{
    setthuEndtime(e.target.value);
  };
  const handlefristarttime=(e)=>{
    setfriStarttime(e.target.value);
  };
  const handlefriendtime=(e)=>{
    setfriEndtime(e.target.value);
  };
  const handlesatstarttime=(e)=>{
    setsatStarttime(e.target.value);
  };
  const handlesatendtime=(e)=>{
    setsatEndtime(e.target.value);
  };
  const handlesunstarttime=(e)=>{
    setsunStarttime(e.target.value);
  };
  const handlesunendtime=(e)=>{
    setsunEndtime(e.target.value);
  };

  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
        
      validate();
    }

    fetchAllLevels();
    fetchAllBranches();
    fetchAllEmployees();
  }, [myToken,myTokenID]);
  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllBranches = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBranches.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         //console.log(response.data);
        setBranches(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllLevels = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllLevels.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setLevels(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllEmployees = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllEmployees3records.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setEmployees(response.data);
       

        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  const handleSubmit=async()=>{
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('empID', empid);
    formData.append('monstarttime', monstarttime);
    formData.append('monendtime', monendtime);
    formData.append('tuesstarttime', tuesstarttime);
    formData.append('tuesendtime', tuesendtime);
    formData.append('wedstarttime', wedstarttime);
    formData.append('wedendtime', wedendtime);
    formData.append('thustarttime', thustarttime);
    formData.append('thuendtime', thuendtime);
    formData.append('fristarttime', fristarttime);
    formData.append('friendtime', friendtime);
    formData.append('satstarttime', satstarttime);
    formData.append('satendtime', satendtime);
    formData.append('sunstarttime', sunstarttime);
    formData.append('sunendtime', sunendtime);
    formData.append('schedset', schedset);
    formData.append('monstate', monstate);
    formData.append('tuestate', tuestate);
    formData.append('wedstate', wedstate);
    formData.append('thustate', thustate);
    formData.append('fristate', fristate);
    formData.append('satstate', satstate);
    formData.append('sunstate', sunstate);
    setisSubmitting(true);

    try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/addSchedule.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
        Swal.fire({
          title: "Added",
          text: "Schedule has been added",
          icon: "success"
        });
      }else{
        Swal.fire({
          title: "Ops!",
          text: "Please set all the scheduled hrs "+response.data['error'],
          icon: "error"
        });
      }
      setisSubmitting(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }

  };
const handleGet=(empIDs,fnames,lnames,scheduless)=>{
  setEmpID(empIDs);
  setfName(fnames);
  setlName(lnames);
  setSchedule(scheduless);
  
};
const handleDateChange = (newDate) => {
    setDate(newDate);
    console.log(newDate);
    fetchShift(newDate);
  };

  const fetchShift = async (date) => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('date', date);
    formData.append('empID', empid);
   setisGetting(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getEmployeeshift.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        setisGetting(false);
        console.log(response.data);
        setShift(response.data);
       
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
return(
    <>
    <main id="main" className="main">
    <div className="modal fade" id="calendarcentered" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Employee Calendar</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">

                          <div class="card">
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <h5 class="card-title">{fname} {lname}</h5>
                                    </div>  
                                       
                                </div>
                                <div className="calendar-container" style={{paddingLeft:40,width:'100%'}}>
                                        <Calendar
                                            onChange={handleDateChange}
                                            value={date}
                                        />
                                </div>
                                <div className="col-lg-12">
                                    { myshift? (
                                        myshift.map(item=>
                                    {
                                    
                                        
                                    return(
                                        <>
                                         <p>Date type: {item.daytype}  </p>
                                         <p>Schedule In: {item.starttime}  </p>
                                         <p>Schedule Out: {item.endtime} </p>   
                                        </>
                                    )
                                    })) : (
                                        <div>Fetching ....</div>
                                    )}
                                   
                                </div>
                                
                                 
                                
                              </div>

                            </div>
                          </div>

                          </div>
                        </div>
                                    
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      </div>
                  </div>
                </div>
              </div>
              
            <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-l">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Employee Schedule</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row">

                      
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">

                          <div class="card">
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <h5 class="card-title">{fname} {lname}</h5>
                                    </div>  
                                    <div className="col-lg-6 shadow">
                                    <input type="radio" name="schedstate" value="week" onChange={handleschedset}/> this week <br/>
                                    <input type="radio" name="schedstate" value="month" onChange={handleschedset}/> this month <br/>
                                    <input type="radio" name="schedstate" value="quarter" onChange={handleschedset}/> this quarter <br/>
                                    <input type="radio" name="schedstate" value="year" onChange={handleschedset}/> this year
                                    </div>    
                                </div>
                              
                              <div class="row g-3 mt-2">
                                <div class="col-md-6 shadow">
                                    <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Monday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="monstate" value="reg" onChange={handlemonstate}/> Regular <br/>
                                            <input type="radio" name="monstate" value="off" onChange={handlemonstate}/> Off
                                        </div>    
                                    </div>
                                  
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={monstarttime} onChange={handlemonstarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={monendtime} onChange={handlemonendtime} id="endtime" name="endtime"/> OUT
                                </div>

                                <div class="col-md-6 shadow">
                                     <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Tuesday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="tuestate"  value="reg" onChange={handletuestate} /> Regular <br/>
                                            <input type="radio" name="tuestate"  value="off" onChange={handletuestate}/> Off
                                        </div>    
                                    </div>
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={tuesstarttime} onChange={handletuesstarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={tuesendtime} onChange={handletuesendtime} id="endtime" name="endtime"/> OUT
                                </div>
                                <div class="col-md-6 shadow">
                                    <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Wednesday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="wedstate" value="reg" onChange={handlewedstate}/> Regular <br/>
                                            <input type="radio" name="wedstate" value="off" onChange={handlewedstate}/> Off
                                        </div>    
                                    </div>
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={wedstarttime} onChange={handlewedstarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={wedendtime} onChange={handlewedendtime} id="endtime" name="endtime"/> OUT
                                </div>
                                <div class="col-md-6 shadow">
                                <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Thursday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="thutate" value="reg" onChange={handlethustate}/> Regular <br/>
                                            <input type="radio" name="thustate" value="off" onChange={handlethustate}/> Off
                                        </div>    
                                    </div>
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={thustarttime} onChange={handlethustarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={thuendtime} onChange={handlethuendtime} id="endtime" name="endtime"/> OUT
                                </div>
                                <div class="col-md-6 shadow">
                                <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Friday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="fristate" value="reg" onChange={handlefristate}/> Regular <br/>
                                            <input type="radio" name="fristate" value="off" onChange={handlefristate}/> Off
                                        </div>    
                                    </div>
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={fristarttime} onChange={handlefristarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={friendtime} onChange={handlefriendtime} id="endtime" name="endtime"/> OUT
                                </div>
                                <div class="col-md-6 shadow">
                                <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Saturday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="satstate" value="reg" onChange={handlesatstate}/> Regular <br/>
                                            <input type="radio" name="satstate" value="off" onChange={handlesatstate}/> Off
                                        </div>    
                                    </div>
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={satstarttime} onChange={handlesatstarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={satendtime} onChange={handlesatendtime} id="endtime" name="endtime"/> OUT
                                </div>
                                <div class="col-md-6 shadow">
                                <div className="row">
                                        <div className="col-lg-6">
                                        <label for="fName" class="form-label">Sunday</label>
                                        </div>  
                                        <div className="col-lg-6">
                                            <input type="radio" name="sunstate" value="reg" onChange={handlesunstate}/> Regular <br/>
                                            <input type="radio" name="sunstate" value="off" onChange={handlesunstate}/> Off
                                        </div>    
                                    </div>
                                </div>
                                <div class="col-md-6 shadow">
                                  <input type="time" value={sunstarttime} onChange={handlesunstarttime}  id="starttime" name="starttime"/> IN
                                  <input type="time" value={sunendtime} onChange={handlesunendtime} id="endtime" name="endtime"/> OUT
                                </div>
                                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting?true:false} >Save changes {isSubmitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    
                      </div>
                              </div>

                            </div>
                          </div>

                          </div>
                        </div>

                      
                        
                        
                      </div>
                                    
                    </div>
                    
                  </div>
                </div>
              </div>

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Scheduling</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Employees</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
                <div className="row">
                    <div className="col-lg-8"><h5 className="card-title">Registered</h5></div>
                    <div className="col-lg-4"><input type="text" id="search" placeholder="search by name" name="search" className="form-control"/></div>
                </div>
            
            </div>
           
            <div className="card-body" style={{overflowY:'scroll', maxHeight:450}}>
             
                
              <table className="table datatable">
                <thead className="sticky-top">
                  <tr>
                    <th scope="col">ID #</th>
                    <th scope="col">Name</th>
                    <th scope="col">Scheduled days</th>
                  </tr>
                </thead>
                <tbody>

                {isLoading ? (
                            
                            <tr>
                               <th scope="row"><div className="waving col-lg-12"></div></th>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                               
                            </tr>
                        ) : (
                          employees.map(item => {
                           var scheduleEntries = item.schedule;

        
                            return (
                              <tr key={item.id}>
                                <th scope="row">{item.empID}</th>
                                <td>{item.first_name} {item.last_name}</td>
                                <td>
                                  <div className="row">
                                    <div className="col-lg-12">
                                    <button className="btn btn-default" onClick={()=>handleGet(item['empID'],item['first_name'],item['last_name'],item['schedule'])} data-bs-toggle="modal" data-bs-target="#verticalycentered"><i className="fa fa-pencil"></i></button>
                                    <button className="btn btn-default" onClick={()=>handleGet(item['empID'],item['first_name'],item['last_name'],item['schedule'])} data-bs-toggle="modal" data-bs-target="#calendarcentered"><i className="fa fa-calendar"></i></button>
                                  
                                    </div>
                                   
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
               

                 
                  
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default ScheduleContent;
import React, { useState,useEffect,useContext} from 'react';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ClockContext } from './ClockContext';
function SideNav(){
  const navigate = useNavigate();
  const [username, setUsername]=useState('');
  const [date, setDate] = useState(new Date());
  const [level, setUserlevel]=useState('');

  
  const handeLogout=()=>{
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'tokenID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        localStorage.removeItem('clockInTime');
        localStorage.removeItem('clockOutTime');
        localStorage.removeItem('username');
        localStorage.removeItem('token');
        localStorage.removeItem('tokenID');
        localStorage.removeItem('userlevel');
        
       
            // Redirect to login page
            navigate('/login');
      }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
       
    };

    useEffect(() => {
      // Get cookie by name
      const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      };

        setUsername(getCookie('username'));
        const ulevel= localStorage.getItem('userlevel');
        setUserlevel(ulevel);
        console.log(level);
    },[]);

    

    useEffect(() => {
      const timerID = setInterval(() => setDate(new Date()), 1000);
      return () => clearInterval(timerID);
      
    }, []);
  
    const formatDate = (date) => {
      const options = {hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, weekday: 'long',year: 'numeric', month: 'long', day: 'numeric'};
      return date.toLocaleDateString('en-US', options);
    };
   

 return(
    <>
    <header id="header" className="header fixed-top d-flex align-items-center">

<div className="d-flex align-items-center justify-content-between">
  <a href="#" className="logo d-flex align-items-center">
    <img src="assets/img/ikea.jpg" alt=""/>
    <span className="d-none d-lg-block">IKEA</span>
  </a>
  <i 
  className={isSidebarOpen?("bi bi-x-square toggle-sidebar-btn"):("bi bi-list toggle-sidebar-btn")} 
  onClick={toggleSidebar}>
    
  </i>
  {formatDate(date)}
</div>



<nav className="header-nav ms-auto">
  <ul className="d-flex align-items-center">

    

    <li className="nav-item dropdown">

      {/* <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
        <i className="bi bi-bell"></i>
        <span className="badge bg-primary badge-number">4</span>
      </a> */}

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
        <li className="dropdown-header">
          You have 4 new notifications
          <a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="notification-item">
          <i className="bi bi-exclamation-circle text-warning"></i>
          <div>
            <h4>Lorem Ipsum</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>30 min. ago</p>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="notification-item">
          <i className="bi bi-x-circle text-danger"></i>
          <div>
            <h4>Atque rerum nesciunt</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>1 hr. ago</p>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="notification-item">
          <i className="bi bi-check-circle text-success"></i>
          <div>
            <h4>Sit rerum fuga</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>2 hrs. ago</p>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>

        <li className="notification-item">
          <i className="bi bi-info-circle text-primary"></i>
          <div>
            <h4>Dicta reprehenderit</h4>
            <p>Quae dolorem earum veritatis oditseno</p>
            <p>4 hrs. ago</p>
          </div>
        </li>

        <li>
          <hr className="dropdown-divider"/>
        </li>
        <li className="dropdown-footer">
          <a href="#">Show all notifications</a>
        </li>

      </ul>

    </li>

    

    <li className="nav-item dropdown pe-3">

      <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
        <img src="assets/img/profile-img.jpg" alt="Profile" className="rounded-circle"/>
        <span className="d-none d-md-block dropdown-toggle ps-2">{username}</span>
      </a>

      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
        <li className="dropdown-header">
          <h6>{username}</h6>
          
        </li>
        <li>
          <hr className="dropdown-divider"/>
        </li>

        
        

       
        

       

        <li>
          <a className="dropdown-item d-flex align-items-center" href="#" onClick={handeLogout}>
            <i className="bi bi-box-arrow-right"></i>
            <span>Sign Out</span>
          </a>
        </li>

      </ul>
    </li>

  </ul>
</nav>

</header>
    <aside id="sidebar" className={`sidebar ${isSidebarOpen ? 'open' : ''}`} style={{zIndex:1000}}>

<ul className="sidebar-nav" id="sidebar-nav">
{level == 1 && (
  <>
  <li className="nav-item">
          <Link to="/dashboard" className="nav-link">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>
  <li className="nav-item active">
    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-menu-button-wide"></i><span>Employees</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    
      <li>
      <Link to="/addemployee">
      
          <i className="bi bi-circle"></i><span>Add Employee</span>
        
        </Link>
      </li>
      <li>
      <Link to="/employees">
       
          <i className="bi bi-circle"></i><span>All Employees</span>
       
        </Link>
      </li>
      <li>
      <Link to="/schedule">
       
          <i className="bi bi-calendar"></i><span>Schedule</span>
       
        </Link>
      </li>
      <li>
      <Link to="/payroll">
       
          <i className="bi bi-circle"></i><span>Payroll</span>
       
        </Link>
      </li>
      
    </ul>
  </li>
  
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#products-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-bar-chart"></i><span>Products</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="products-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    <li>
      <Link to="/cakes">
      
          <i className="bi bi-cake"></i><span>Cakes</span>
        
        </Link>
      </li>
      <li>
      <Link to="/snacks">
       
          <i className="bi bi-cookie"></i><span>Snacks</span>
       
        </Link>
      </li>
    </ul>
  </li>
  <li className="nav-link">
     
      <Link to="/checklistconfig">
       
       <i className="bi bi-check-square"></i><span> Checklist</span>
    
     </Link>
      
    
    </li>
    <li className="nav-item">
     <Link to="/ordertaking">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-check"></i>
          <span>Order Taking</span>
        </a>
      </li>
      </Link>
  </li>

  <li className="nav-item">
  <Link to="/server">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-check"></i>
          <span>Server</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/baker">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-rocket"></i>
          <span>Baker</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/decorator">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cake2"></i>
          <span>Decorator</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/ordersviewer">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cake"></i>
          <span>Orders</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#manager-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-pie-chart"></i><span>Manager</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="manager-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    <li>
      <Link to="/manager">
      
          <i className="bi bi-cake"></i><span>Cakes</span>
        
        </Link>
      </li>
      
      <li>
      <Link to="/managersnacksdelivery">
       
          <i className="bi bi-cookie"></i><span> Snacks Delivery</span>
       
        </Link>
      </li>
      <li>
        <Link to="/startofday">
       
          <i className="bi bi-circle"></i><span>Start of Day</span>
       
        </Link>
      </li>
      {/* <li>
        <Link to="/endofday">
       
          <i className="bi bi-circle"></i><span>End of Day</span>
       
        </Link>
      </li> */}
      <li>
        <Link to="/inventoryreport">
       
          <i className="bi bi-circle"></i><span>Inventory Report</span>
       
        </Link>
      </li>
      {/* <li>
        <Link to="/inventoryreceipt">
       
          <i className="bi bi-circle"></i><span>Inventory Receipt</span>
       
        </Link>
      </li> */}
      {/* <li>
        <Link to="/incidentreport">
       
          <i className="bi bi-circle"></i><span>Incident Report</span>
       
        </Link>
      </li> */}
    </ul>
  </li>

  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#sales-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-bar-chart"></i><span>Sales Performance</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="sales-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
    
      <li>
      <Link to="/saleshistory">
       
          <i className="bi bi-cookie"></i><span>Sales History</span>
       
        </Link>
      </li>
      {/* <li>
        <Link to="/expensetracking">
       
          <i className="bi bi-circle"></i><span>Expense Tracking</span>
       
        </Link>
      </li> */}
      {/* <li>
        <Link to="/inhousecheckout">
       
          <i className="bi bi-circle"></i><span>In House Checkout</span>
       
        </Link>
      </li>
      
      <li>
        <Link to="/trending">
       
          <i className="bi bi-circle"></i><span>Trending Now</span>
       
        </Link>
      </li>
      <li>
        <Link to="/forecasting">
       
          <i className="bi bi-circle"></i><span>Projection and Forecasting</span>
       
        </Link>
      </li> */}
      
    </ul>

    
  </li>
  <li className="nav-item">
  <Link to="/employeeattendance">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-clock"></i>
          <span>Employee Attendance</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/delivery">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-car-front"></i>
          <span>Cakes Delivery</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/snacksdelivery">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-car-front"></i>
          <span>Snacks Delivery</span>
        </a>
      </li>
      </Link>
  </li>
<li className="nav-item">
  <Link to="/hotkitchen">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cup-hot"></i>
          <span>Hot Kitchen</span>
        </a>
      </li>
      </Link>
  </li>
<li className="nav-item">
  <Link to="/cashier">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-currency-peso">&#8369;</i>
          <span>Cashier</span>
        </a>
      </li>
      </Link>
  </li>
  </>
        

      )}

{level == 2 && (<>
  <li className="nav-item">
     <Link to="/ordertaking">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-check"></i>
          <span>Order Taking</span>
        </a>
      </li>
      </Link>
  </li>

  <li className="nav-item">
  <Link to="/server">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-check"></i>
          <span>Server</span>
        </a>
      </li>
      </Link>
  </li>
</>)}
{level == 3 && (<>
  <li className="nav-item">
  <Link to="/baker">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-rocket"></i>
          <span>Baker</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/ordersviewer">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cake"></i>
          <span>Orders</span>
        </a>
      </li>
      </Link>
  </li>
</>)}  
{level == 4 && (<>
  <li className="nav-item">
  <Link to="/decorator">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cake2"></i>
          <span>Decorator</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
    <Link to="/ordersviewer">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cake"></i>
          <span>Orders</span>
        </a>
      </li>
      </Link>
  </li>
</>)}
{level == 5 && (<>
  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#manager-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-pie-chart"></i><span>Manager</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="manager-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
    <li>
      <Link to="/manager">
      
          <i className="bi bi-cake"></i><span>Cakes</span>
        
        </Link>
      </li>
      
      <li>
      <Link to="/managersnacksdelivery">
       
          <i className="bi bi-cookie"></i><span> Snacks Delivery</span>
       
        </Link>
      </li>
      <li>
        <Link to="/startofday">
       
          <i className="bi bi-circle"></i><span>Start of Day</span>
       
        </Link>
      </li>
      {/* <li>
        <Link to="/endofday">
       
          <i className="bi bi-circle"></i><span>End of Day</span>
       
        </Link>
      </li> */}
      <li>
        <Link to="/inventoryreport">
       
          <i className="bi bi-circle"></i><span>Inventory Report</span>
       
        </Link>
      </li>
      {/* <li>
        <Link to="/inventoryreceipt">
       
          <i className="bi bi-circle"></i><span>Inventory Receipt</span>
       
        </Link>
      </li> */}
      {/* <li>
        <Link to="/incidentreport">
       
          <i className="bi bi-circle"></i><span>Incident Report</span>
       
        </Link>
      </li> */}
    </ul>
  </li>

  <li className="nav-item">
    <a className="nav-link collapsed" data-bs-target="#sales-nav" data-bs-toggle="collapse" href="#">
      <i className="bi bi-bar-chart"></i><span>Sales Performance</span><i className="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul id="sales-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
    
      <li>
      <Link to="/saleshistory">
       
          <i className="bi bi-cookie"></i><span>Sales History</span>
       
        </Link>
      </li>
      {/* <li>
        <Link to="/expensetracking">
       
          <i className="bi bi-circle"></i><span>Expense Tracking</span>
       
        </Link>
      </li> */}
      {/* <li>
        <Link to="/inhousecheckout">
       
          <i className="bi bi-circle"></i><span>In House Checkout</span>
       
        </Link>
      </li>
      
      <li>
        <Link to="/trending">
       
          <i className="bi bi-circle"></i><span>Trending Now</span>
       
        </Link>
      </li>
      <li>
        <Link to="/forecasting">
       
          <i className="bi bi-circle"></i><span>Projection and Forecasting</span>
       
        </Link>
      </li> */}
      
    </ul>

    
  </li>
  <li className="nav-item">
  <Link to="/employeeattendance">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-clock"></i>
          <span>Employee Attendance</span>
        </a>
      </li>
      </Link>
  </li>
  
  
</>)}
{level == 6 && (<>
  <li className="nav-item">
  <Link to="/delivery">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-car-front"></i>
          <span>Cakes Delivery</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/snacksdelivery">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-car-front"></i>
          <span>Snacks Delivery</span>
        </a>
      </li>
      </Link>
  </li>
</>)}
{level == 7 && (<>
  <li className="nav-item">
  <Link to="/hotkitchen">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cup-hot"></i>
          <span>Hot Kitchen</span>
        </a>
      </li>
      </Link>
  </li>
  <li className="nav-item">
  <Link to="/ordersviewer">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-cake"></i>
          <span>Orders</span>
        </a>
      </li>
      </Link>
  </li>
</>)}
{level == 8 && (<>
  <li className="nav-item">
  <Link to="/cashier">
      
      <li className="nav-item">
        <a className="nav-link">
        <i className="bi bi-currency-peso">&#8369;</i>
          <span>Cashier</span>
        </a>
      </li>
      </Link>
  </li>
</>)}
  
</ul>

</aside>
    </>
 );
}
export default SideNav;
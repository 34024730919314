import {React,useState,useEffect} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';

function AddemployeeContent(){
  const navigate = useNavigate();
  const [fname,setfName]=useState('');
  const [mname,setmName]=useState('');
  const [lname,setlName]=useState('');
  const [bdate,setbDate]=useState('');
  const [add1,setAdd1]=useState('');
  const [add2,setAdd2]=useState('');
  const [city,setCity]=useState('');
  const [zip,setZip]=useState('');
  const [datehired,setdateHired]=useState('');
  const [dailyrate,setdailyRate]=useState('');
  const [phealth,setpHealth]=useState('');
  const [pagibig,setpagIbig]=useState('');
  const [sss,setsss]=useState('');
  const [tin,settin]=useState('');
  const [mobile,setMobile]=useState('');
  const [emergency,setEmergency]=useState('');
  const [brachassigned,setBranchassigned]=useState('');
  const [userlevel,setUserlevel]=useState('');
  const [email,setEmail]=useState('');
  const [password,setPassword]=useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [branches, setBranches] = useState([]);
  const [levels, setLevels] = useState([]);
  const [emplevel, setEmplevel] = useState('');
  const handlefname=(e)=>{
    setfName(e.target.value);
  };
  const handleemplevel=(e)=>{
    setEmplevel(e.target.value);
  };
  const handlemname=(e)=>{
    setmName(e.target.value);
  };
  const handlelname=(e)=>{
    setlName(e.target.value);
  };
  const handledbdate=(e)=>{
    setbDate(e.target.value);
  };
  const handleadd1=(e)=>{
    setAdd1(e.target.value);
  };
  const handleadd2=(e)=>{
    setAdd2(e.target.value);
  };
  const handlecity=(e)=>{
    setCity(e.target.value);
  };
  const handlezip=(e)=>{
    setZip(e.target.value);
  };
  const handledatehired=(e)=>{
    setdateHired(e.target.value);
  };
  const handledailyrate=(e)=>{
    setdailyRate(e.target.value);
  };
  const handlephealth=(e)=>{
    setpHealth(e.target.value);
  };
  const handlepagibig=(e)=>{
    setpagIbig(e.target.value);
  };
  const handlesss=(e)=>{
    setsss(e.target.value);
  };
  const handletin=(e)=>{
    settin(e.target.value);
  };
  const handlemobile=(e)=>{
    setMobile(e.target.value);
  };
  const handleemergency=(e)=>{
    setEmergency(e.target.value);
  };
  const handlebranchassigned=(e)=>{
    setBranchassigned(e.target.value);
  };
  const handleuserlevel=(e)=>{
    setUserlevel(e.target.value);
  };
  const handleemail=(e)=>{
    setEmail(e.target.value);
  };
  const handlepassword=(e)=>{
    setPassword(e.target.value);
  };

  
  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
        validate();
    }
    fetchAllLevels();
    fetchAllBranches();
   
  }, [myToken,myTokenID]);

  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllBranches = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBranches.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        setBranches(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllLevels = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllLevels.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setLevels(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  const handleSubmit=async()=>{
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('fname', fname);
    formData.append('mname', mname);
    formData.append('lname', lname);
    formData.append('bdate', bdate);
    formData.append('add1', add1);
    formData.append('add2', add2);
    formData.append('city', city);
    formData.append('zip', zip);
    formData.append('datehired', datehired);
    formData.append('dailyrate', dailyrate);
    formData.append('phealth', phealth);
    formData.append('pagibig', pagibig);
    formData.append('sss', sss);
    formData.append('tin', tin);
    formData.append('mobile', mobile);
    formData.append('emergency', emergency);
    formData.append('branchassigned', brachassigned);
    formData.append('userlevel', userlevel);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('emplevel', emplevel);
    setisSubmitting(true);
    if(emplevel==null || emplevel==''){
      alert('Employee Level is Required');
      setisSubmitting(false);
      return;
    }
    if(fname==null || fname==''){
      alert('Firstname is required');
      setisSubmitting(false);
      return;
    }
    if(lname==null || lname==''){
      alert('Lastname is required');
      setisSubmitting(false);
      return;
    }
    if(bdate==null || bdate==''){
      alert('Birthdate is required');
      setisSubmitting(false);
      return;
    }
    if(fname==null || fname==''){
      alert('Firstname is required');
      setisSubmitting(false);
      return;
    }
    if(add1==null || add1==''){
      alert('Address 1 is required');
      setisSubmitting(false);
      return;
    }
    if(city==null || city==''){
      alert('City is required');
      setisSubmitting(false);
      return;
    }
    if(setZip==null || zip==''){
      alert('Zip code is required');
      setisSubmitting(false);
      return;
    }
    if(dailyrate==null || dailyrate==''){
      alert('Daily rate is required');
      setisSubmitting(false);
      return;
    }
    if(datehired==null || datehired==''){
      alert('Date hired is required');
      setisSubmitting(false);
      return;
    }
    if(brachassigned==null || brachassigned==''){
      alert('Brach is required');
      setisSubmitting(false);
      return;
    }
    if(userlevel==null || userlevel==''){
      alert('Level is required');
      setisSubmitting(false);
      return;
    }
    if(email==null || email==''){
      alert('Email is required');
      setisSubmitting(false);
      return;
    }
    if(password==null || password==''){
      alert('Password is required');
      setisSubmitting(false);
      return;
    }
    
    try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/addEmployee.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
        Swal.fire({
          title: "Great!",
          text: response.data['success'],
          icon: "success"
        });
      }else{
        Swal.fire({
          title: "Ops!",
          text: response.data['error'],
          icon: "info"
        });
      }
      setisSubmitting(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }

  };
return(
    <>
    <main id="main" class="main">

<div class="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Add Employee</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">Add Employee</li>
          </ol>
        </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
</div>
</div>
<section class="section dashboard">
  <div class="row">

    
    <div class="col-lg-12">
      <div class="row">

       
        <div class="col-xxl-6 col-md-6">
          <div class="card info-card sales-card">

            

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Personal Information</h5>

            
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="fName" class="form-label">First Name</label>
                  <input type="text" onChange={handlefname} class="form-control" id="fName" name="fName"/>
                </div>
                <div class="col-md-6">
                  <label for="mName" class="form-label">Middle Name</label>
                  <input type="text" onChange={handlemname} class="form-control" id="mName" name="mName"/>
                </div>
                <div class="col-md-6">
                  <label for="lName" class="form-label">Last Name</label>
                  <input type="text" onChange={handlelname} class="form-control" id="lName" name="lName"/>
                </div>
                <div class="col-md-6">
                  <label for="bdate" class="form-label">Birthdate</label>
                  <input type="date" onChange={handledbdate} class="form-control" id="bdate" name="bdate"/>
                </div>
                
                
                <div class="col-12">
                  <label for="inputAddress5" class="form-label">Address</label>
                  <input type="text" onChange={handleadd1} class="form-control" id="inputAddres5s" placeholder="1234 Main St"/>
                </div>
                <div class="col-12">
                  <label for="inputAddress2" class="form-label">Address 2</label>
                  <input type="text" onChange={handleadd2} class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"/>
                </div>
                <div class="col-md-6">
                  <label for="inputCity" class="form-label">City</label>
                  <input type="text" onChange={handlecity} class="form-control" id="inputCity"/>
                </div>
                
                <div class="col-md-2">
                  <label for="inputZip" class="form-label">Zip</label>
                  <input type="text" onChange={handlezip} class="form-control" id="inputZip"/>
                </div>
                <div class="col-md-4">
                  <label for="dhired" class="form-label">Date Hired</label>
                  <input type="date" onChange={handledatehired} class="form-control" id="dhired" name="dhired"/>
                </div>
                <div class="col-md-6">
                  <label for="rate" class="form-label">Daily Rate</label>
                  <input type="text" onChange={handledailyrate} class="form-control" id="rate" name="rate"/>
                </div>
                <div class="col-md-6">
                  <label for="rate" class="form-label">Employee Level</label>
                  <select className="form-control" onChange={handleemplevel}> 
                      <option key={0}  value={0} selected disabled>Select employee level</option>
                      <option key={1}  value={1}>Regular</option>
                      <option key={2}  value={2}>Probationary</option>
                      <option key={3}  value={3}>Intern</option>
                      
                  </select>
                </div>
                
                
              </div>

            </div>
          </div>

          </div>
        </div>

      
        <div class="col-xxl-6 col-md-6">
          <div class="card info-card sales-card">

            

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Government Mandatories</h5>

            
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="phealth" class="form-label">PhilHealth</label>
                  <input type="text" onChange={handlephealth} class="form-control" id="phealth" name="phealth"/>
                </div>
                <div class="col-md-6">
                  <label for="pagibig" class="form-label">Pag-ibig</label>
                  <input type="text" onChange={handlepagibig} class="form-control" id="pagibig" name="pagibig"/>
                </div>
                <div class="col-md-6">
                  <label for="sss" class="form-label">SSS</label>
                  <input type="text"onChange={handlesss} class="form-control" id="sss" name="sss"/>
                </div>
                <div class="col-md-6">
                  <label for="tin" class="form-label">TIN</label>
                  <input type="text" onChange={handletin} class="form-control" id="tin" name="tin"/>
                </div>
                
              </div>

            </div>

            
          </div>
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Contact</h5>

            
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="mobile" class="form-label">Mobile Number</label>
                  <input type="text" onChange={handlemobile} class="form-control" id="mobile" name="mobile"/>
                </div>
                <div class="col-md-6">
                  <label for="incase" class="form-label">In Case of Emergency</label>
                  <input type="text" onChange={handleemergency} class="form-control" id="incase" name="incase"/>
                </div>
                
                
              </div>

            </div>

            
          </div>
          <div class="card-body">
              <h5 class="card-title">System Role</h5>

            
              <div class="row g-3">
                <div class="col-md-6">
                  <label for="branch" class="form-label">Branch</label>
                  <select onChange={handlebranchassigned} class="form-control" id="branch" name="branch">
                  <option key={0} value={0} disabled selected>select branch</option>
                    {branches.map(item=>(
                      <option key={item.id} value={item.id}>{item.branch_name}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="ulevel" class="form-label">User Level</label>
                  <select onChange={handleuserlevel} class="form-control" id="ulevel" name="ulevel">
                    <option key={0} value={0} disabled selected>select user level</option>
                    {levels.map(item=>(
                      <option key={item.id} value={item.id}>{item.user_level}</option>
                    ))}
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="inputEmail5" class="form-label">Email</label>
                  <input type="email" onChange={handleemail} class="form-control" id="inputEmail5"/>
                </div>
                <div class="col-md-6">
                  <label for="inputPassword5" class="form-label">Password</label>
                  <input type="password" onChange={handlepassword} class="form-control" id="inputPassword5"/>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary m-2" onClick={handleSubmit} disabled={isSubmitting?true:false}>Submit {isSubmitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                  <button type="reset" class="btn btn-secondary m-2">Reset</button>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

   

  </div>
</section>

</main>

    </>
);
}
export default AddemployeeContent;
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
export const ClockContext = createContext();

const ClockProvider = ({ children }) => {
    
  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [isClockedin,setisClockedin]=useState(false);
  const [auth, setAuth]=useState(false);
  const [isSending, setSending]=useState(false);
  const [clockstate,setClockstate]=useState(false);

  

const sendClock=async(type,myToken,myTokenID)=>{
        setSending(true);
          const formData = new FormData();
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
          formData.append('type', type);

          // console.log(myToken);
          // console.log(myTokenID);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/clock.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response.data);
        if(response.data['success']){
            if(type=='in'){
                const now = new Date();
                setClockInTime(now);
                setClockOutTime(null);
                setDuration(null);
                setisClockedin(true);
                localStorage.setItem('clockInTime', now);
                localStorage.removeItem('clockOutTime');
                setClockstate(true);
            }
            if(type=='out'){
              setClockstate(false);
                const now = new Date();
                setClockOutTime(now);
                setisClockedin(false);
                localStorage.setItem('clockOutTime', now);
                if (clockInTime) {
                const diff = now - clockInTime;
                const diffInSeconds = Math.floor(diff / 1000);
                const hours = Math.floor(diffInSeconds / 3600);
                const minutes = Math.floor((diffInSeconds % 3600) / 60);
                const seconds = diffInSeconds % 60;
                setDuration({ hours, minutes, seconds });
                }
            }   

            
        }else{
          Swal.fire({
            title: "Ops!",
            text: response.data['error'],
            icon: "info"
          });
        }
        
        setSending(false);
        
    } catch (error) {
        console.error('Error submitting form', error);
    }
};

  useEffect(() => {
    const savedClockInTime = localStorage.getItem('clockInTime');
    const savedClockOutTime = localStorage.getItem('clockOutTime');

    if (savedClockInTime) {
      setClockInTime(new Date(savedClockInTime));
    }
    if (savedClockOutTime) {
      setClockOutTime(new Date(savedClockOutTime));
    }
  }, []);

  useEffect(() => {
    if (clockInTime) {
      localStorage.setItem('clockInTime', clockInTime);
    }
    if (clockOutTime) {
      localStorage.setItem('clockOutTime', clockOutTime);
    }
  }, [clockInTime, clockOutTime]);

  useEffect(() => {
    let interval;
    if (clockInTime && !clockOutTime) {
      interval = setInterval(() => {
        const now = new Date();
        const diff = now - clockInTime;
        const diffInSeconds = Math.floor(diff / 1000);
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;
        setDuration({ hours, minutes, seconds });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [clockInTime, clockOutTime]);

  const handleClockIn = (type,mytoken,mytokenID) => {
    sendClock(type,mytoken,mytokenID);
    
  };

  const handleClockOut = (type,mytoken,mytokenID) => {
    
    sendClock(type,mytoken,mytokenID);
   
  };
  const saveTimein=(timein)=>{
    localStorage.setItem('clockInTime', timein);
    setisClockedin(true);
  };
  const saveTimeout=(timeout)=>{
    localStorage.setItem('clockOutTime', timeout);
    setisClockedin(false);
  };
  const sending=(bol)=>{
    setSending(bol);
  };
  const handleduration=(val)=>{
    setDuration(val);
  };
  const clockouttime=()=>{
    setClockOutTime(null);
  };
  const clockintime=()=>{
    setClockInTime(null);
  };
  const handleclockstate=(state)=>{
    setClockstate(state);
  };

  

  return (
    <ClockContext.Provider
      value={{
        clockInTime,
        clockOutTime,
        duration,
        isClockedin,
        isSending,
        auth,
        handleClockIn,
        handleClockOut,
        saveTimein,
        saveTimeout,
        sending,
        handleduration,
        clockintime,
        clockouttime,
        clockstate,
        handleclockstate
      }}
    >
      {children}
    </ClockContext.Provider>
  );
};

export default ClockProvider;

import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import DeliveryContent from "./DeliveryContent";

function Delivery(){
 return(
    <>
    <SideNav />
    <DeliveryContent />
    <Footer/>
    </>
 );
}
export default Delivery;
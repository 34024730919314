import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

function ChecklistConfigContent(){
  const printRef = useRef();
  const navigate = useNavigate();
  const [checklistID, setchecklistID] = useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isRemoving, setisRemoving] = useState(false);
  const [isGetting, setisGetting] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [checklist, setchecklist] = useState([]);
  const [details, setDetails] = useState('');
  const [type, setType] = useState('');

 

  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
       
      validate();
      getChecklists();
    }
  }, [myToken,myTokenID]);

  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         //console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const getChecklists = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getChecklists.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setchecklist(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const addChecklists = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('details', details);
    formData.append('type', type);
    
    setisSubmitting(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/addChecklist.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        if(response.data['success']){
            Swal.fire({
                title: "Great",
                text: "Checklist Added",
                icon: "success"
              });
              getChecklists();
        }else{
            Swal.fire({
                title: "Ops!",
                text: "Something went wrong "+response.data,
                icon: "error"
              });
        }
        setisSubmitting(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const updateChecklists = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('details', details);
    formData.append('type', type);
    formData.append('checklistID', checklistID);
    
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/updateChecklist.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        if(response.data['success']){
            Swal.fire({
                title: "Great",
                text: "Checklist Updated",
                icon: "success"
              });
              getChecklists();
        }else{
            Swal.fire({
                title: "Ops!",
                text: "Something went wrong "+response.data,
                icon: "error"
              });
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
 const handleDetails=(e)=>{
    setDetails(e.target.value);
 } 
 const handleType=(e)=>{
    setType(e.target.value);
 } 
 const handleGet=(ids,detailss,types)=>{
    setchecklistID(ids);
    setDetails(detailss);
    setType(types);
    
 }
 const removeChecklist=(id)=>{
    Swal.fire({
        title: "Are you sure?",
        text: "You are about to remove this checklist",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove this"
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setisRemoving(true);
           
    
            const formData = new FormData();
            formData.append('checklistID', id);
            formData.append('token', myToken);
            formData.append('tokenID', myTokenID);
            
            
    
            const response = await axios.post('https://megaxsolutions.com/api/middleware/removeChecklist.php', formData, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            });
            
         // console.log(response.data);
            if(response.data['success']){
               
                getChecklists();
            }else{
              Swal.fire({
                  title: "Ops!",
                  text: "Something went wrong. check your connection "+response.data['error'],
                  icon: "info"
                });
            }
            setisRemoving(false);
            
            
            
          } catch (error) {
            console.error('Error submitting form', error);
            
          }
        }
      });
 };
 const [searchQuery, setSearchQuery] = useState('');
 const filterchecklist = checklist.filter(item => 
        item.type.toLowerCase().includes(searchQuery.toLowerCase())

  );
return(
    <>
    <main id="main" className="main">
   
              
            <div className="modal fade" id="updatechecklist" tabindex="-1">
            <div className="modal-dialog">
                  <div className="modal-content" >
                    <div className="modal-header">
                    <h5 className="modal-title">Update Checklist</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" ref={printRef}>
                    <div class="row">
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">
                            <label>Details</label>
                            <textarea onChange={handleDetails} className="form-control" cols="8" rows="3" value={details}></textarea>
                            <select className="mt-2 form-control" onChange={handleType} value={type}>
                                <option>select checklist type</option>
                                <option value="start">Add to Start of Day Checklist</option>
                                <option value="end">Add to End of Day Checklist</option>
                            </select>
                          </div>

                          </div>
                        </div>
                                    
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={updateChecklists}>Save</button>
                      </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="addchecklist" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content" >
                    <div className="modal-header">
                    <h5 className="modal-title">Add Checklist</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" ref={printRef}>
                    <div class="row">
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">
                            <label>Details</label>
                            <textarea onChange={handleDetails} className="form-control" cols="8" rows="3"></textarea>
                            <select className="mt-2 form-control" onChange={handleType}>
                                <option>select checklist type</option>
                                <option value="start">Add to Start of Day Checklist</option>
                                <option value="end">Add to End of Day Checklist</option>
                            </select>
                          </div>

                          </div>
                        </div>
                                    
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" disabled={isSubmitting?true:false} onClick={addChecklists}>Save {isSubmitting?(<><i className="fa fa-spinner fa-spin"></i></>):('')}</button>
                      </div>
                  </div>
                </div>
              </div>
              
          

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Checklist Configuration</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Checklist Configuration</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addchecklist">Add Checklist</button>
            <div className="filter">
              <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-filter"></i></a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li className="dropdown-header text-start">
                  <h6>Filter</h6>
                </li>
                <li><a className="dropdown-item" href="#" onClick={(e) => setSearchQuery('')}>All</a></li>   
                <li><a className="dropdown-item" href="#" onClick={(e) => setSearchQuery('start')}>Start of Day</a></li>
                <li><a className="dropdown-item" href="#" onClick={(e) => setSearchQuery('end')}>End of Day</a></li>
                
              </ul>
            </div>                    
            </div>
           
            <div className="card-body" style={{overflowY:'scroll', maxHeight:450}}>
            
              <table className="table datatable">
                <thead>
                  <tr>
                    <th scope="col">Checklist Details</th>
                    <th scope="col">Checklist Type</th>
                    <th scope="col">Action</th>
                   
                  </tr>
                </thead>
                <tbody>

                {isLoading ? (
                            
                            <tr>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                            </tr>
                        ) : (
                            filterchecklist.map(item => {
                            return (
                              <tr key={item.id}>
                                <td>{item.details}</td>
                                <td>{item.type} of day</td>
                                <td>
                                <div className="row">
                                                <div className="col-lg-12">
                                                <div class="btn-group" role="group" aria-label="Basic outlined example">
                                            <button className="btn btn-outline-primary"  onClick={()=>handleGet(item.id,item.details,item.type)} title="Edit" data-bs-toggle="modal" data-bs-target="#updatechecklist"><i className="fa fa-pencil"></i></button>
                                            <button className="btn btn-outline-danger" disabled={isRemoving?true:false} onClick={()=>removeChecklist(item.id)}   title="remove" ><i className="fa fa-times"></i></button>
                                            
                                                </div>
                                                </div>
                                            </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                
                

                 
                  
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default ChecklistConfigContent;
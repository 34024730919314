import React, { useContext,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClockContext } from './ClockContext';
import axios from 'axios';
const Clocks = () => {
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [timein,setTimein]=useState('');
  const [timeout,setTimeout]=useState('');
  
  const {
    clockInTime,
    clockOutTime,
    duration,
    isClockedin,
    isSending,
    auth,
    handleClockIn,
    handleClockOut,
    saveTimein,
    saveTimeout,
    sending,
    handleduration,
    clockintime,
    clockouttime,
    clockstate,
    handleclockstate
  } = useContext(ClockContext);

 
  const formatDuration = (duration) => {
    const hours = String(duration.hours).padStart(2, '0');
    const minutes = String(duration.minutes).padStart(2, '0');
    const seconds = String(duration.seconds).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };
    
    // Get token from cookie
    
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
     
      validate();
      
    }
  }, [myToken,myTokenID]);

  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
  
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/auth.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        let data=response.data;
         console.log(response.data);
         if(data!=0){ 
          
             if(data[2]==='in'){
                 let timeins = new Date(data[1]);
                 saveTimein(timeins);
                 setTimein(timeins);
                 localStorage.setItem('clockInTime', timeins);
                 localStorage.removeItem('clockOutTime');
                 clockouttime('');
             }
             if(data[2]==='out'){
              
                 let timeouts = new Date(data[1]);
                 saveTimeout(timeouts);
                 setTimeout(timeouts);
                 localStorage.removeItem('clockInTime');
                 localStorage.setItem('clockOutTime', timeouts);
                 
             }
             if(data[3]==='in'){
               handleclockstate(true);
               
             }else{
               handleclockstate(false);
             }
               console.log(data[3]); 
             
         }else{
           localStorage.removeItem('clockOutTime');
           localStorage.removeItem('clockInTime');
           clockintime(null);
           clockouttime(null);
           handleduration(null);
         }
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  return (
    
    <div className='row'>
      
      {isSending? (<>
        <div className="modal fade show" id="loginmodal" tabindex="-1" aria-modal="true" style={{display:'block',backgroundColor:'rgba(0, 0, 0, 0.5)'}}>
                <div className="modal-dialog border-none modal-dialog-centered display-block text-center">
                  <div className="modal-content justify-center bg-transparent">
                    <i className='fa fa-spinner fa-spin' style={{fontSize:'100px'}}></i>
                  </div>
                </div>
              </div>
      </>):""}
            
        <div className='col-lg-12'>
        {/* {clockstate?( <button className='btn btn-warning float-end' onClick={()=>handleClockOut('out',myToken,myTokenID)}>Clock Out</button>):(<button className='btn btn-success float-end' onClick={()=>handleClockIn('in',myToken,myTokenID)}>Clock In</button>)}
         */}
        {isClockedin && (
        
        <p className='badge bg-success float-end m-1 mt-2'>Clock In Time: {timein.toLocaleString()}</p>
      
        )}
        {!isClockedin && (
          <>
           <p className='badge bg-warning float-end m-1 mt-2'>Clock Out Time: {timeout.toLocaleString()}</p>
           </>  
        )}

        {duration && (

            <span className='badge bg-success float-end m-1 mt-2'>{formatDuration(duration)}</span>
            
        )}
        </div>
        <div className='col-lg-8'>
        
        </div>
        
       
     

      

      
    </div>
  );
};

export default Clocks;

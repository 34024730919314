import SideNav from "./SideNav";
import Footer from "./Footer";
import OrdersviewerContent from "./OrdersviewerContent";


function Ordersviewer() {
    

    return (
        <>
            <SideNav />
            <OrdersviewerContent />
            <Footer />
        </>
    );
}

export default Ordersviewer;

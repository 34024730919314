import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import BakerContent from "./BakerContent";


function Baker(){
 return(
    <>
    <SideNav />
    <BakerContent />
    <Footer/>
    </>
 );
}
export default Baker;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import CashierContent from "./CashierContent";



function Cashier(){
 return(
    <>
    <SideNav />
    <CashierContent />
    <Footer/>
    </>
 );
}
export default Cashier;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import HotkitchenContent from "./HotkitchenContent";


function Hotkitchen(){
 return(
    <>
    <SideNav />
    <HotkitchenContent />
    <Footer/>
    </>
 );
}
export default Hotkitchen;
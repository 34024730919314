import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2';
import './style.css';
import { useNavigate } from 'react-router-dom';
function OrdertakingContent(){
  const printRef = useRef();
  const printRef2 = useRef();
  const buttonRef = useRef(null);
  const buttonRef2 = useRef(null);
  const navigate = useNavigate();
  const link='https://megaxsolutions.com/api/middleware/uploads/';
  const [cakes, setCakes] = useState([]);
  const [allcakes, setAllCakes] = useState([]);
  const [allsnacks, setAllSnacks] = useState([]);
  const [cakesSizes, setCakeSizes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const [caketype, setCaketype] = useState('');
  const [cakesize, setSize] = useState('');
  const [cakeprice, setCakePrice] = useState('');
  const [cakesizeselect, setSizeselect] = useState('');
  const [cakeidselect, setCakeselect] = useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [cakephoto, setCakephoto] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [addtoorderbtn, setAddtoorderbtn] = useState(false);
  const [isOpen, setisOpen] = useState('');
  const [tickets, setTickets] = useState([]);
  const [branchdaily, setBranchdaily] = useState([]);
  const [shiftamount, setShiftamount] = useState('');
  const [shiftamountclose, setShiftamountclose] = useState('');
  const [clicked, setClicked] = useState(false);
  const [rippleStyle, setRippleStyle] = useState({});
  const divRef = useRef(null);
  const [availablesizes, setAvailablesizes] = useState([]);
  let [quantityselected, setQuantity] = useState(1);
  let [quantitystock, setQuantitystock] = useState(0);
  const [cakename, setCakename] = useState('');
  const [snackname, setSnackname] = useState('');
  const [pricepercakeidsizeid, setPricecakeidsizeid] = useState('');
  const [quantitypersizeid, setQuantitypersizeid] = useState('');
  const [cakeidsizeidconcat, setConcatcakeidsizeid] = useState('');
  const [priceselected, setPriceselected] = useState('');
  const [totalprice, setTotalprice] = useState('');
  const [totalpriceformat, setFormatTotalprice] = useState('');
  const [isRequired, setisRequired] = useState(false);
  const [showdelete, setShowdelete] = useState(false);
  const [ticketscount, setTicketcount] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);
  let [totalAmount, setTotalAmount] = useState(0);
  const [concatenatedQuantityValues, setConcatenatedQuantityValues] = useState([]); 
  const [concatenatedSnackIDprice, setConcatenatedSnackIDprice] = useState([]);
  const [snackid, setSnackID] = useState('');
  const [snackquantity, setSnackqunatity] = useState('');
  const [customername, setCustomername] = useState('');
  const [customeraddress, setCustomeraddress] = useState('');
  const [customerphone, setCustomerphone] = useState('');
  const [orderdesc, setOrderdesc] = useState('');
  const [duedate, setDuedate] = useState('');
  const [celebrantname, setCelebrantname] = useState('');
  const [celebrantage, setCelebrantage] = useState('');
  const [celebrantgender, setCelebrantgender] = useState('');
  const [totaldeposit, setTotaldeposit] = useState('');
  const [totalbalance, setTotalbalance] = useState('');
  const [dineintakeout, setDineintakeout] = useState('');
  const [ticketorderstoprint, setTicketorderstoprint] = useState([]);
  const [ticketnametoprint, setTicketnametoprint] = useState('');
  const [amountreceiveddisplay, setAmountReceiveddisplay] = useState('');
  const [amountreceived, setAmountReceived] = useState('');
  const [discount, setDiscount] = useState('');
  const [tablenumber, setTablenumber] = useState('');
  const [outofstock, setOutofstock] = useState(false);
  const [outofstock2, setOutofstock2] = useState(false);
  const [paymethod, setPaymethod] = useState('');
  const [totalAmountDiscounted, setTotalAmountDiscounted] = useState('');
  const [ctphoto, setPhotoID] = useState('');
  const [imageUrl, setImageUrl] = useState("https://megaxsolutions.com/api/middleware/uploads/" + ctphoto); // default image URL
  const [file, setFile] = useState(null);
  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
       
      validate();
    }
    fetchAllCakes();
    checkcashierDay();
    fetchSizes();
    getTickets();
    fetchAllSnacks();
  }, [myToken,myTokenID]);

  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  const handlequantityminussnack=(e)=>{
    
    quantityselected=quantityselected-1;
    if(quantityselected<1){
        quantityselected=1;
    }
   
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    
    setQuantity(quantityselected);
  };
  const handlequantityminus=(e)=>{
    
    quantityselected=quantityselected-1;
    if(quantityselected<1){
        quantityselected=1;
    }
    if(priceselected==''){
        setisRequired(true);
        return;
       }else{
        setisRequired(false);
       }
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    
    setQuantity(quantityselected);
  };
  const handlequantityplussnack=(e)=>{
    quantityselected=quantityselected+1;
    if(quantityselected<1){
        quantityselected=1;
    }
   
    if(quantityselected>quantitystock){
        alert('Ops! quantity selected is greater than in stock');
        return;
    }
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    setQuantity(quantityselected);
  };
  const handlequantityplus=(e)=>{
    quantityselected=quantityselected+1;
    if(quantityselected<1){
        quantityselected=1;
    }
   if(priceselected==''){
    setisRequired(true);
    return;
   }else{
    setisRequired(false);
   }
    if(quantityselected>quantitystock){
        alert('Ops! quantity selected is greater than in stock');
        return;
    }
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    setQuantity(quantityselected);
  };
  const formatNumberWithCommas = (number) => {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const handleAmountReceived=(event)=>{
    
    let inputValue = event.target.value;
    let plainNumber = inputValue.replace(/[^\d]/g, '');
    let formattedCurrency = formatNumberWithCommas(plainNumber);
    setAmountReceived(inputValue);
    setAmountReceiveddisplay(formattedCurrency);
  };
  const handleAmountDiscount=(event)=>{
    setDiscount(event.target.value);
    
    if(event.target.value!=''){
      let discount=parseFloat(event.target.value);
      let subtotal=totalAmount*discount;
      let res=totalAmount-subtotal;
      setTotalAmountDiscounted(res); 
    }else{
      setTotalAmountDiscounted(''); 
    }
    
  };

  const handlepaymentMethod=(event)=>{
    setPaymethod(event.target.value);
    console.log(event.target.value);
  };


  const handlecustomername=(e)=>{
    setCustomername(e.target.value);
  };
  const handlecustomeraddress=(e)=>{
    setCustomeraddress(e.target.value);
  };
  const handlecustomerphone=(e)=>{
    setCustomerphone(e.target.value);
  };

  const handleorderdesc=(e)=>{
    setOrderdesc(e.target.value);
  };
  const handleduedate=(e)=>{
    setDuedate(e.target.value);
  };
  const handlecelebrantname=(e)=>{
    setCelebrantname(e.target.value);
  };
  const handlecelebrantage=(e)=>{
    setCelebrantage(e.target.value);
  };
  const handlecelebrantgender=(e)=>{
    setCelebrantgender(e.target.value);
  };
  const handledineintakeout=(e)=>{
    setDineintakeout(e.target.value);
  };
  
  const handletotaldeposit=(e)=>{
    setTotaldeposit(e.target.value);
    let balance=totalAmount-Number(e.target.value);
    if(e.target.value===null || e.target.value===''){
      setTotalbalance(0);
    }else{
      setTotalbalance(balance);
      
    }
    
    

  };
  const handlereservation=(e)=>{
    setTotaldeposit(0);
    setTotalbalance(0);
    setDineintakeout(0);
    if(checkedValues.length<=0){
      alert('Please select order items first!');
      return;
    }
  };
  const triggerButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };
  const triggerButtonClick2 = () => {
    if (buttonRef2.current) {
      buttonRef2.current.click();
    }
  };
  const handleshiftamount=(e)=>{
    setShiftamount(e.target.value);
  };
  const handleshiftamountclose=(e)=>{
    setShiftamountclose(e.target.value);
  };
    const handleCakeselect=(e)=>{
      setCakeselect(e.target.value);
    };
    const handleCakePrice=(e)=>{
      setCakePrice(e.target.value);
    };
    const handleSizeselect =(e)=>{

        let sID=  e.target.value;
        let concat= `${cakeidselect}-${sID}`;
        let pricefound = pricepercakeidsizeid[concat]; // Find the corresponding value
        console.log(concat);
        let qfound = quantitypersizeid[sID];
        console.log(qfound);
        console.log(pricefound);

        
        setQuantity(1); // Default quantity
        setTotalprice(pricefound * 1); // Initial calculation
        setPriceselected(pricefound);
        setSizeselect(sID);
        setQuantitystock(qfound);

        let totals = pricefound * 1; // 1 is the initial quantity
        setFormatTotalprice(totals.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }));
        
        setTotalprice(totals);
        setisRequired(false);
        if(qfound<=0){
          setOutofstock(true);
        }else{
          setOutofstock(false);
        }
    };
    

    

    const handleSubmitTicket = async() => {
        const formData = new FormData();
        formData.append('cakeID', cakeidselect);
        formData.append('sizeID', cakesizeselect);
        formData.append('quantity', quantityselected);
        formData.append('totalamount', totalprice);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);

        if(cakesizeselect==0 || cakesizeselect==''){
            setSubmitting(false);
            setisRequired(true);
            return;
            
        }
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addTicket.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              
              const closeModalButton = document.querySelector('#sizes .btn-close');
              if (closeModalButton) {
                  closeModalButton.click();
              }
              getTickets();
              }else{
              alert(response.data['error']+ 'Check your connection!');
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
    const handleSubmitTicketsnacks = async() => {
        const formData = new FormData();
        formData.append('snackID', snackid);
        formData.append('quantity', quantityselected);
        formData.append('totalamount', totalprice);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);

        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addTicket.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              
              const closeModalButton = document.querySelector('#snacksmodal .btn-close');
              if (closeModalButton) {
                  closeModalButton.click();
              }
              getTickets();
              }else{
              alert(response.data['error']+ 'Check your connection!');
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

const getTickets = async() => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
   
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getTickets.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response.data);
        setTickets(response.data);
        setTicketcount(response.data.length);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

    const handleCakephotoChange = (e) => {
        setCakephoto(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('caketype', caketype);
        formData.append('cakephoto', cakephoto);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);

        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addcake.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              alert(response.data['success']);
              setCaketype("");
              setCakephoto(null);
            }else{
              alert(response.data['error']);
            }
            
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleSubmitshift = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('amount', shiftamount);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setisOpen(false);
        
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/openshift.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            
            if(response.data['success']){
              
              setisOpen(true);
            }else{
              alert(response.data['error']);
            }
            
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
    const handleSubmitshiftclose = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('amount', shiftamountclose);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/closeshift.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
             
                document.getElementById('verticalycentered').classList.remove('show');
                document.getElementById('verticalycentered').style.display = 'none';
                document.body.classList.remove('modal-open');
                document.body.style.paddingRight = '0px';
                const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (modalBackdrop) modalBackdrop.parentNode.removeChild(modalBackdrop);
              setisOpen(false);
            }else{
              alert(response.data['error']);
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleSubmitSize = async (e) => {
      e.preventDefault();
      setSubmitting(true);
      const formData = new FormData();
      formData.append('cakesize', cakesize);
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      if(cakesize=='' || cakesize==null){
        setSubmitting(false);
        return;
      }
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/addcakesize.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          
          if(response.data['success']){
            alert(response.data['success']);
            setSize("");
            
          }else{
            alert(response.data['error']);
          }
          setSubmitting(false);
          
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };

  const handleSubmitServer = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append('ticketids', JSON.stringify(checkedValues));
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('dineintakeout', dineintakeout);
    formData.append('amountreceived', amountreceived);
    formData.append('paymethod', paymethod);
    formData.append('discountedamount', totalAmountDiscounted);
    formData.append('discount', discount);
    formData.append('tablenumber', tablenumber);
    if(tablenumber===null || tablenumber==='' || tablenumber===0){
      alert('Select table number');
      setSubmitting(false);
      return;
    }
    if(checkedValues.length <= 0 || dineintakeout==0){
      alert('Select items and dine option');
      setSubmitting(false);
      return;
    }
    if(amountreceived<=0 || amountreceived===''){
      alert('Please add received amount before sending to server');
      setSubmitting(false);
      return;
    }
    if(discount!==''){
      if(amountreceived<totalAmountDiscounted){
        alert('Amount received is less than total discounted amount of purchase, Check the amount received');
        setSubmitting(false);
        return;
      }
    }else{
      if(amountreceived<totalAmount){
        alert('Amount received is less than total amount of purchase, Check the amount received');
        setSubmitting(false);
        return;
      }
    }
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/addSales.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        
        console.log(response.data['toprint']);
        if(response.data['success']){
            getTickets();
            setCheckedValues([]);
            document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                checkbox.checked = false;
            });
            
            setTicketorderstoprint(response.data['toprint']);
            triggerButtonClick();
        }else{
          alert(response.data['error']);
        }
        setSubmitting(false);
        
    } catch (error) {
        console.error('Error submitting form', error);
    }
};

const handleSubmitreservation = async (e) => {
  e.preventDefault();
  setSubmitting(true);
  const formData = new FormData();
  formData.append('ticketids', JSON.stringify(checkedValues));
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
  formData.append('customername', customername);
  formData.append('customeraddress', customeraddress);
  formData.append('customerphone', customerphone);
  formData.append('orderdesc', orderdesc);
  formData.append('duedate', duedate);
  formData.append('celebrantname', celebrantname);
  formData.append('celebrantage', celebrantage);
  formData.append('celebrantgender', celebrantgender);
  formData.append('totalamount', totalAmount);
  formData.append('totaldeposit', totaldeposit);
  formData.append('totalbalance', totalbalance);
  formData.append('file', file);
  
  if(checkedValues.length <= 0){
    setSubmitting(false);
    alert('Select Items first!');
    return;
  }
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/addSalesreservation.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
          getTickets();
          setCheckedValues([]);
          document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
              checkbox.checked = false;
          });
          Swal.fire({
            title: "Added",
            text: "Reservation added",
            icon: "success"
          });
          setTicketorderstoprint(response.data['toprint']);
          triggerButtonClick2();

      }else{
        Swal.fire({
          title: "Ops!",
          text: "Something went wrong "+response.data['error'],
          icon: "info"
        });
      }
      setSubmitting(false);
      
  } catch (error) {
      console.error('Error submitting form', error);
  }
};

  const fetchCakes = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/getcakes.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          console.log(response.data);
          setCakes(response.data);
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };

  const fetchSizes = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getsizes.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setCakeSizes(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
};

const fetchAllCakes = async () => {
  const formData = new FormData();
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
  setisLoading(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/getallcakesbranch.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data.length>0){
        setAllCakes(response.data);
        response.data.map(item=>{
            const parsedValues = item.concatenated_cakeID_sizeID_quantity.split(',');
            setConcatenatedQuantityValues(prevValues => [...prevValues, ...parsedValues]);
          
        });
      }
      
      
      
      setisLoading(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};
const fetchAllSnacks = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllsnacksbranch.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        if(response.data.length>0){
          setAllSnacks(response.data); 
          let arr=[];
          response.data.map(item=>{
              arr.push(item.concatenated_snackID_price);
          });
          
          const keyValuePairs = arr.reduce((acc, item) => {
              const [key, value] = item.split(':');
              acc[key] = value;
              return acc;
          }, {});
          setConcatenatedSnackIDprice(keyValuePairs);
        }
        
        setisLoading(false);

    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  
const checkcashierDay = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    setisOpen(false);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/checkcashier.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
       if(response.data!=0){
        setBranchdaily(response.data);
        let status="";
        response.data.map(item=>{
            status=item.branch_status;
        });
        setisLoading(false);
        console.log(status);
        if(status=='open'){
            setisOpen(true);
        }else{
            setisOpen(false);
        }
        
       }else{
        setisOpen(false);
       }
       
    } catch (error) {
        console.error('Error submitting form', error);
    }
    setisLoading(false);
  };

  


 const handleget =()=>{
  fetchCakes();
  fetchSizes();
 };   

 const converter=(value)=>{
    const keyValueArray = value.split(',');
    const resultpricepercakeidsizeid = keyValueArray.reduce((acc, pair) => {
        const [key, value] = pair.split(':');
        acc[key] = parseInt(value, 10); // Convert value to integer
        return acc;
    }, {});
    return resultpricepercakeidsizeid;
 };

const checkAndSplit = (str) => {
  // Check if the string contains a comma
  if (str.includes(',')) {
    // If it contains a comma, use the split() function
    return str.split(',');
  } else {
    // If no comma is found, return the original string as an array
    return [str];
  }
};
 const handleClick = (sizes,cakeID,stock,cakename,pricepercakeidsizeids,quantitypersizeids) => {
  
  if(pricepercakeidsizeids===null){
    Swal.fire({
      title: "Ops!",
      text: "Please set price for this item.",
      icon: "info"
    });
    return;
  }
  const allSizesArray = [];
  let asizes = checkAndSplit(sizes);
  //let asizes = sizes.split(','); 
  asizes.map(size => {
     
      allSizesArray.push(size.trim());
      });
console.log(allSizesArray);
setAvailablesizes(allSizesArray);
    


      
if(stock<=0){
setOutofstock(true);
}else{
  setOutofstock(false);
}
    let res1=converter(pricepercakeidsizeids);
    let res2=converter(quantitypersizeids);
    setPricecakeidsizeid(res1);
    setQuantitypersizeid(res2); 

    setCakeselect(cakeID);
    setQuantitystock(stock);
    setCakename(cakename);

    setQuantity(0); // Default quantity
    setTotalprice(0.00); // Initial calculation
    setPriceselected('');
    setSizeselect(0);
    setFormatTotalprice(0.00);
    
  };
  const getIdBySize = (size) => {
    const foundSize = cakesSizes.find(item => item.size === size);
    return foundSize ? foundSize.id : null; // Return null if size not found
  };
const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let filteredItem = tickets.find(item => item.id ==  value);

        if (filteredItem) {
            
            let { cakeID, sizeID } = filteredItem;
            let concatenatedValue = `${cakeID}-${sizeID}`;
            //console.log('Concatenated Value:', concatenatedValue);
        } else {
            console.log('No item found with the specified ID.');
        }

        const quantitySum = {};

     
        tickets.forEach(item => {
            let key; 
            if(item.cakeID==null){
                key= item.snackID;
           }else{
                key = `${item.cakeID}-${item.sizeID}`;
           }
            
            
            if (quantitySum[key]) {
                quantitySum[key] += item.quantity;
            } else {
                quantitySum[key] = item.quantity;
            }
        });
        
        // Convert the quantitySum object to an array of {cakeID-sizeID: totalQuantity} pairs if needed
        const result = Object.entries(quantitySum).map(([key, totalQuantity]) => ({
            key,
            totalQuantity
        }));
        
        //console.log('Sum of quantities per cakeID and sizeID:', result);


        setCheckedValues((prevCheckedValues) => {
            if (checked) {
                // Add the value if it's checked and it's not already in the array
                return [...prevCheckedValues, value];
                
            } else {
                // Remove the value if it's unchecked
                return prevCheckedValues.filter((item) => item !== value);
            }
        });
        
};
useEffect(()=>{
    if(checkedValues.length>0){
       
        setShowdelete(true);
    }else{
        setShowdelete(false);
    }

});
useEffect(() => {
    const total = checkedValues.reduce((sum, id) => {
        // Convert id to number if necessary (assuming ticket.id is a number)
        const numericId = Number(id);
        const ticket = tickets.find(ticket => ticket.id === numericId);
        return sum + (ticket ? ticket.total_amount : 0);
    }, 0);
    if(tickets.length<=0){
        setAddtoorderbtn(false);
    }else{
        setAddtoorderbtn(true);
    }
    setTotalAmount(total);
}, [checkedValues, tickets]);


const handleremoveChecked = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
 
          const formData = new FormData();
          formData.append('ticketids',  JSON.stringify(checkedValues));
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
  
          setRemoving(true);
          
          const response = await axios.post('https://megaxsolutions.com/api/middleware/removeTickets.php', formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          
        
          if(response.data['success']){
            getTickets();
            setCheckedValues([]);
            document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                checkbox.checked = false;
            });
          
              
          }else{
            Swal.fire({
                title: "Ops!",
                text: "Something went wrong. check your connection "+response.data['error'],
                icon: "info"
              });
          }
          setRemoving(false);
          
          
          
        } catch (error) {
          console.error('Error submitting form', error);
          setSubmitting(false); // Ensure this is called even if there's an error
        }
      }
    });
  };

  const handlespoiled = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, set this as spoiled"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
 
          const formData = new FormData();
          formData.append('ticketids',  JSON.stringify(checkedValues));
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
  
          setRemoving(true);
          
          const response = await axios.post('https://megaxsolutions.com/api/middleware/spoiledTickets.php', formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          
        console.log(response.data);
          if(response.data['success']){
            getTickets();
            setCheckedValues([]);
            document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                checkbox.checked = false;
            });
          
            fetchAllCakes();
            fetchAllSnacks(); 
          }else{
            Swal.fire({
                title: "Ops!",
                text: "Something went wrong. check your connection "+response.data['error'],
                icon: "info"
              });
          }
          setRemoving(false);
          
          
          
        } catch (error) {
          console.error('Error submitting form', error);
          setSubmitting(false); // Ensure this is called even if there's an error
        }
      }
    });
  };
 
  const handleinhouse = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, set this as inhouse consumed"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
 
          const formData = new FormData();
          formData.append('ticketids',  JSON.stringify(checkedValues));
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
  
          setRemoving(true);
          
          const response = await axios.post('https://megaxsolutions.com/api/middleware/inhouseTickets.php', formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          console.log(response.data);
        
          if(response.data['success']){
            getTickets();
            setCheckedValues([]);
            document.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
                checkbox.checked = false;
            });
          
            fetchAllCakes();
            fetchAllSnacks(); 
          }else{
            Swal.fire({
                title: "Ops!",
                text: "Something went wrong. check your connection "+response.data['error'],
                icon: "info"
              });
          }
          setRemoving(false);
          
          
          
        } catch (error) {
          console.error('Error submitting form', error);
          setSubmitting(false); // Ensure this is called even if there's an error
        }
      }
    });
  };
const handleClicksnacks=(id,qnty,name,price)=>{
    setSnackID(id);
    setQuantitystock(qnty);
    setSnackname(name);
    setPriceselected(price);
    setQuantity(0);
    setTotalAmount(0);
    setTotalprice(0);
    setFormatTotalprice(0);
    if(qnty<=0){
      setOutofstock2(true);
    }else{
      setOutofstock2(false);
    }
}; 


const handlePrint = () => {
  const printContent = printRef.current;
  const WindowPrint = window.open('', '', 'width=700,height=800');
 // WindowPrint.document.write(printContent.innerHTML);
  WindowPrint.document.write(`
      <html>
        <head>
          <title>Order Slip</title>
          <style>
            /* Include your styles here */
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 20px;
            }
           table{
           width: 100%;
        
           }
           #printtotal{
           border-top: 1px solid black;
           }
           tr,td,th{
           text-align:center;
           }
          </style>
          <!-- Include Bootstrap CSS if necessary -->
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    //WindowPrint.close();
    WindowPrint.focus();
    WindowPrint.print();
   
};
const handlePrint2 = () => {
  const printContent = printRef2.current;
  const WindowPrint = window.open('', '', 'width=700,height=800');
 // WindowPrint.document.write(printContent.innerHTML);
  WindowPrint.document.write(`
      <html>
        <head>
          <title>Order Slip</title>
          <style>
            /* Include your styles here */
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 20px;
            }
           table{
           width: 100%;
        
           }
           #printtotal{
           border-top: 1px solid black;
           }
           tr,td,th{
           text-align:center;
           }
          </style>
          <!-- Include Bootstrap CSS if necessary -->
          <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
        </head>
        <body>
          ${printContent.innerHTML}
        </body>
      </html>
    `);
    WindowPrint.document.close();
    //WindowPrint.close();
    WindowPrint.focus();
    WindowPrint.print();
    
};
const totalAmounttoprint = ticketorderstoprint.reduce((acc, item) => acc + item.total_amount, 0);
const totalAmountreceived = ticketorderstoprint.reduce((acc, item) => item.amount_received, 0);
const totalChangetoprint= totalAmountreceived-totalAmounttoprint;
useEffect(() => {
  if (ticketorderstoprint.length > 0) {
    
    setTicketnametoprint(ticketorderstoprint[0].ticket_name);
  }
}, [ticketorderstoprint]);

let filteredAllcakes=[];
const [searchQuery, setSearchQuery] = useState('');
if(allcakes.length>0){
  
  filteredAllcakes = allcakes.filter(order =>
    order.cake_type?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
}
 
let filteredAllsnack=[];
const [searchQuerysnack, setSearchQuerysnack] = useState('');
if (allsnacks.length>0) {
  
  filteredAllsnack = allsnacks.filter(order =>
    order.snack_name?.toLowerCase().includes(searchQuerysnack?.toLowerCase())
  ); 
}
const handleFileChange = async(event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    setFile(selectedFile);

    // Create a URL to display the selected file before upload
    const previewUrl = URL.createObjectURL(selectedFile);
    setImageUrl(previewUrl);

  //   try {
  //     setSubmitting(true);
  //     const formData = new FormData();
  //     console.log(cakeidselect);
  //     formData.append('snackID', cakeidselect);
  //     formData.append('token', myToken);
  //     formData.append('tokenID', myTokenID);
  //     formData.append('cakephoto', selectedFile);
     
      
  //     const response = await axios.post('https://megaxsolutions.com/api/middleware/updateSnackphoto.php', formData, {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     });
      
  //   console.log(response.data);
  //     if(response.data['success']){
        
        
          
  //     }else{
  //       Swal.fire({
  //           title: "Ops!",
  //           text: "Something went wrong. check your connection "+response.data['error'],
  //           icon: "info"
  //         });
  //     }
  //     setSubmitting(false);
      
  //   } catch (error) {
  //     console.error('Error submitting form', error);
      
  //   }

  }
};
const handleTable=(e)=>{
  setTablenumber(e.target.value);
}
return(
    <>
    <main id="main" className="main">
    <div className="modal fade" id="receiptmodal2" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content" >
                    <div className="modal-header">
                      <h5 className="modal-title">Order Slip</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" ref={printRef2}>
                      <h3>{ticketnametoprint}</h3>
                      <label>Customer Name: {customername}</label><br/>
                      <label>Customer Address: {customeraddress}</label><br/>
                      <label>Phone: {customerphone}</label><br/>
                      <label>Due Date: {duedate}</label><br/>
                      <label>Celebrant's Name: {celebrantname}</label><br/>
                      <label>Age: {celebrantage}</label><br/>
                      <label>Gender: {celebrantgender}</label><br/>
                      <label>Order Description: {orderdesc}</label><br/>
                      
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ITEM</th>
                            <th>QNTTY</th>
                            <th>AMOUNT</th>
                            
                          </tr>
                        </thead>
                     
                      {
                       

                      ticketorderstoprint.map(item=>{
                        
                      return(
                        <>
                        
                        <tr>
                          <td>{item.item_name.toUpperCase()}</td>
                          <td>{item.quantity}</td>
                          <td>&#8369;{item.total_amount}</td>
                        </tr>
                        
                        </>
                      )
                      })}
                     
                      <tr id='printtotal' style={{borderTop:'1px solid black'}}>
                          <td ><h4>TOTAL AMOUNT:</h4></td>
                          <td></td>
                          <td ><h4>&#8369;{totalAmounttoprint}</h4></td>
                      </tr>
                      <tr id='printtotal' style={{borderTop:'1px solid black'}}>
                          <td ><h4>DEPOSIT:</h4></td>
                          <td></td>
                          <td ><h3>&#8369;{totaldeposit}</h3></td>
                      </tr>
                      <tr id='printtotal' style={{borderTop:'1px solid black'}}>
                          <td ><h4>BALANCE:</h4></td>
                          <td></td>
                          <td ><h4>&#8369;{totalbalance}</h4></td>
                      </tr>
                      </table>
                    </div>
                    <div className="modal-footer">
                     
                      <button type="button" className="btn btn-primary" onClick={handlePrint2}>Print </button>
                    </div>
                  </div>
                </div>
              </div>


          <div className="modal fade" id="receiptmodal" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content" >
                    <div className="modal-header">
                      <h5 className="modal-title">Order Slip</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" ref={printRef}>
                      <label>{dineintakeout}</label><br/>
                      <h3>{ticketnametoprint}</h3>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ITEM</th>
                            <th>QNTTY</th>
                            <th>AMOUNT</th>
                            
                          </tr>
                        </thead>
                     
                      {
                       

                      ticketorderstoprint.map(item=>{
                        
                      return(
                        <>
                        
                        <tr>
                          <td>{item.item_name.toUpperCase()}</td>
                          <td>{item.quantity}</td>
                          <td>&#8369;{item.total_amount}</td>
                        </tr>
                        
                        </>
                      )
                      })}
                      
                      
                      <tr id='printtotal' style={{borderTop:'1px solid black'}}>
                          <td ><h4>TOTAL AMOUNT:</h4></td>
                          <td></td>
                          <td ><h4>&#8369;{totalAmounttoprint}</h4></td>
                      </tr>
                      <tr id='printtotal' style={{borderTop:'1px solid black'}}>
                          <td ><h4>AMOUNT RECEIVED:</h4></td>
                          <td></td>
                          <td ><h4>&#8369;{totalAmountreceived}</h4></td>
                      </tr>
                      <tr id='printtotal' style={{borderTop:'1px solid black'}}>
                          <td ><h4>TOTAL CHANGE:</h4></td>
                          <td></td>
                          <td ><h4>{totalChangetoprint.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</h4></td>
                      </tr>

                      </table>
                    </div>
                    <div className="modal-footer">
                     
                      <button type="button" className="btn btn-primary" onClick={handlePrint}>Print </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="reservation" tabindex="-1">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Reservation</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div className="row"> 
                       
                       
                        <div className="col-lg-6">
                        <label>Customer Name</label>
                              <input type="text" 
                              value={customername}
                              onChange={handlecustomername}
                              className="form-control"/>
                              
                        <label>Address</label>
                            <input type="text" 
                            value={customeraddress}
                            onChange={handlecustomeraddress}
                            className="form-control"/>
                          <label>Phone Number</label>
                          <input type="text" 
                          placeholder="eg. 0961..."
                          value={customerphone}
                          onChange={handlecustomerphone}
                          maxLength={11}
                          className="form-control"/>

                        <label>Due Date</label>
                        <input type="datetime-local" 
                        value={duedate}
                        onChange={handleduedate}
                        className="form-control"/>

                      <label>Celebrants Name</label>
                     <input type="text" 
                       value={celebrantname}
                       onChange={handlecelebrantname}
                       className="form-control"/>
                       <label>Age</label>
                     <input type="text" 
                       value={celebrantage}
                       onChange={handlecelebrantage}
                       className="form-control"/>
                       <label>Gender</label>
                      <select className="form-control" value={celebrantgender} onChange={handlecelebrantgender}>
                      <option key={0} selected >select gender</option>
                        <option key={1} value='Male'>Male</option>
                        <option key={2} value='Female'>Female</option>
                      </select>
                      <label>Order Description</label>
                                <textarea className="form-control" rows='8' placeholder="" onChange={handleorderdesc} value={orderdesc}></textarea>
                                
                        </div>
                        <div className="col-lg-6">
                        <div class="justify-content-center mb-2 mt-3">
                        <label>Photo</label><br/>
                          <div className="item mb-3">
                           
                              <img src={imageUrl} style={{borderRadius: '10px', marginLeft:'20px',maxHeight:'230px',justifyContent:'center'}}/>
                              <input type="file"  onChange={handleFileChange} style={{marginLeft:'20px',marginTop:'20px'}}/>
                      
                              </div> {submitting?(<><i className="fa fa-spinner fa-spin"></i> <i>Updating photo..</i> </>):""}
                        
                        
                            </div>
                            <hr/>
                               <label>Total Amount</label>
                            <input type="text" readOnly 
                              value={totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                             
                              className="form-control"/>
                              <label>Deposit</label>
                            <input type="text" 
                              value={totaldeposit}
                              onChange={handletotaldeposit}
                              className="form-control"/>
                              <label>Balance</label>
                              <input type="text" readOnly
                              value={totalbalance.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                              className="form-control"/>
                        </div>
                        
                      </div>
                    </div>
                    

                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handleSubmitreservation} disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''}>Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>

             <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Specify drawer amount before closing</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <input type="text" 
                       value={shiftamountclose}
                       onChange={handleshiftamountclose}
                       placeholder="Enter amount" className="form-control"/>
                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handleSubmitshiftclose} disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''}>Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="snacksmodal" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Quantity</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                   
                        
                        <div className="text-center">
                        <h1>{snackname}</h1>
                        <h3>In Stock: {quantitystock}</h3>
                        { 
                          outofstock2? (
                            <>
                            <img style={{maxWidth:'100%',height:'200px'}} src={link+"outofstock.jpeg"} />
                            </>
                          ):(
                            <>
                            <label>Quantity</label><br/>
                            <button className="btn btn-primary" onClick={handlequantityminussnack}><i className="fa fa-minus"></i></button><span className="m-5 mt-5 fw-bold fs-1 fs-md-2 fs-lg-3">{quantityselected}</span><button className="btn btn-primary" onClick={handlequantityplussnack}><i className="fa fa-plus"></i></button>
                      
                            </>
                          )

                        }

                         
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div className="text-center">
                        { 
                          outofstock2? (
                            <>
                           
                            </>
                          ):(
                            <>
                             <h3 className="fw-bold fs-1 fs-md-2 fs-lg-3">{totalpriceformat}</h3>
                            </>
                          )

                        }
                       
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                    { 
                          outofstock2? (
                            <>
                            
                            </>
                          ):(
                            <>
                             <button type="button" style={{width:'100%'}} className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitTicketsnacks} >Add to Orders <i className="fa fa-ticket"></i>{submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                   
                            </>
                          )

                        }
                       </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="sizes" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Size and Quantity</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <h5 className="card-title">{cakename}</h5>
                        <label>Size</label>
                        <select className={`form-control ${isRequired ? 'border border-danger border-2' : ''}`} id="sizeID" name="sizeID" onChange={handleSizeselect} value={cakesizeselect}>
                            <option key={0} value={0} disabled> select size </option>
                            {availablesizes.map(cakesize => (
                                <option key={getIdBySize(cakesize)} value={getIdBySize(cakesize)}>{cakesize}</option>
                            ))}
                        </select><br/>
                        
                       
                        <div className="text-center">
                        <h3>In Stock: {quantitystock}</h3>
                        { 
                          outofstock? (
                            <>
                            <img style={{maxWidth:'100%',height:'200px'}} src={link+"outofstock.jpeg"} />
                            </>
                          ):(
                            <>
                            <label>Quantity</label><br/>
                            <button className="btn btn-primary" onClick={handlequantityminus}><i className="fa fa-minus"></i></button><span className="m-5 mt-5 fw-bold fs-1 fs-md-2 fs-lg-3">{quantityselected}</span><button className="btn btn-primary" onClick={handlequantityplus}><i className="fa fa-plus"></i></button>
                        
                            </>
                          )

                        }
                        
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div className="text-center">
                        { 
                          outofstock? (
                            <>
                            
                            </>
                          ):(
                            <>
                            <h3 className="fw-bold fs-1 fs-md-2 fs-lg-3">{totalpriceformat}</h3>
                            </>
                          )

                        }
                        
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                        { 
                          outofstock? (
                            <>
                            
                            </>
                          ):(
                            <>
                             <button type="button" style={{width:'100%'}} className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitTicket} >Add to Orders <i className="fa fa-ticket"></i>{submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    
                            </>
                          )

                        }
                     </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="tickets" tabindex="-1">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Orders</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                            {showdelete?(
                                <>
                                <button title='set as spoiled' className="btn btn-default float-end" onClick={handlespoiled} disabled={isRemoving?(<i className="fa fa-spinner fa-spin"></i>):''}><i className="fa fa-bacon"></i> {isRemoving?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                                <button title='set as inhouse consume' className="btn btn-default float-end" onClick={handleinhouse} disabled={isRemoving?(<i className="fa fa-spinner fa-spin"></i>):''}><i className="fa fa-home"></i> {isRemoving?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                                
                                 <button title='Remove' className="btn btn-default float-end" onClick={handleremoveChecked} disabled={isRemoving?(<i className="fa fa-spinner fa-spin"></i>):''}><i className="fa fa-trash"></i> {isRemoving?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                                </>
                            ):(
                                <></>
                            )}
                       

                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Items</th>
                                    
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                            {tickets.map((item, index) => {
                                let itemname;
                               
                                
                                        if(item.cake_type==null){
                                            itemname=item.snack_type;
                                            
                                        }else{
                                            itemname=item.cake_type +" ("+ item.size+")";
                                        }
                                        return (
                                            <tr key={index} id={item.id}>
                                                <td><input type="checkbox" style={{width:'30px', height:'30px'}} 
                                                value={item.id} 
                                                
                                                onChange={handleCheckboxChange}/></td>
                                                <td>{itemname}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.total_amount}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td colSpan="3"><strong>Total:</strong></td>
                                        <td>{showdelete?(
                                            <><strong>{totalAmount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</strong></>
                                        ):(
                                            <>
                                            <strong>&#8369;0.00</strong>
                                            </>
                                        )}
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3"><strong>Discounted:</strong></td>
                                        <td>{showdelete?(
                                            <><strong>{totalAmountDiscounted.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</strong></>
                                        ):(
                                            <>
                                            <strong>&#8369;0.00</strong>
                                            </>
                                        )}
                                            
                                        </td>
                                    </tr>
                            </tbody>
                        </table>
                        <div className="row">
                          <div className="col-lg-12 mt-2">
                             <div className="row">
                                      <div className="col-lg-6">
                                        <div className="row">
                                          <div className="col-6">
                                          <input onClick={handlepaymentMethod} style={{width:'30px',height:'30px'}} type="radio" name="paymethod" value={'CASH'} /> CASH
                                          </div>
                                          <div className="col-6">
                                          <input onClick={handlepaymentMethod} style={{width:'30px',height:'30px'}} type="radio" name="paymethod" value={'GCASH'} /> GCASH 
                                            </div>
                                        </div>
                                      
                                     
                                        </div>
                                        <div className="col-lg-6">
                                        <div className="row">
                                          <div className="col-6">
                                          <input onChange={handledineintakeout} style={{width:'30px',height:'30px'}} type="radio" name="dineoption" value={'DINE-IN'} /> DINE-IN
                                         
                                          </div>
                                          <div className="col-6">
                                          <input onChange={handledineintakeout} style={{width:'30px',height:'30px'}} type="radio" name="dineoption" value={'TAKE-OUT'} /> TAKE-OUT
                                         
                                          </div>
                                          <div className="col-6 mt-2">
                                            <label>Table Number</label>
                                          <select className="form-control" onChange={handleTable}>
                                           <option key={0} value={0}>select table</option>
                                            <option key={1} value={1}>1</option>
                                            <option key={2} value={2}>2</option>
                                            <option key={3} value={3}>3</option>
                                            <option key={4} value={4}>4</option>
                                            <option key={5} value={5}>5</option>
                                            <option key={6} value={6}>6</option>
                                            <option key={7} value={7}>7</option>
                                            <option key={8} value={8}>8</option>
                                            <option key={9} value={9}>9</option>
                                            <option key={10} value={10}>10</option>
                                            <option key={11} value={11}>11</option>
                                            <option key={12} value={12}>12</option>
                                            <option key={13} value={13}>13</option>
                                            <option key={14} value={14}>14</option>
                                            <option key={15} value={15}>15</option>
                                            <option key={16} value={16}>16</option>
                                            <option key={17} value={17}>17</option>
                                            <option key={18} value={18}>18</option>
                                            <option key={19} value={19}>19</option>
                                            <option key={20} value={20}>20</option>
                                          </select>
                                         
                                          </div>
                                        </div>
                                           
                                        </div>
                                        
                              </div> 
                              
                            
                          </div>
                          <div className="col-lg-12 mt-2">
                          <div className="row">
                          <div className="col-lg-6">
                                    <input type="text" placeholder="Discount in Decimal" value={discount} onChange={handleAmountDiscount} className="form-control col-lg-4 float-end"/>
                                        
                                        </div>
                                        <div className="col-lg-6">
                                        <input type="text" placeholder="Amount Received" value={amountreceiveddisplay} onChange={handleAmountReceived} className="form-control col-lg-4 float-end"/>
                                        </div>
                                        
                              </div>
                           
                                  
                          </div >           
                        </div>
                        
                    </div>

                    <div className="row p-2">
                      {
                        addtoorderbtn?(
                        <>
                      
                        <div className="col-lg-6 mt-2">
                        <button type="button" style={{width:'100%'}} className="btn btn-warning btn-xl" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} data-bs-toggle="modal" data-bs-target="#reservation" onClick={handlereservation} >RESERVATION <i className="fa fa-paper-plane"></i> {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    
                        </div>
                        <div className="col-lg-6 mt-2">
                        <button type="button" style={{width:'100%'}} className="btn btn-primary btn-xl float-end" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitServer} >SEND TO SERVER <i className="fa fa-paper-plane"></i> {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    
                        </div>
                        
                        
                       
                       
                        </>):''
                      }
                         
                         
                      </div>
                  </div>
                </div>
              </div>


<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Order Taking</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Order Taking</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
       
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <button className="btn btn-info" data-bs-toggle="modal" data-bs-target="#tickets">ORDERS <i className="bi bi-ticket"></i> <i className="badge bg-danger">{ticketscount}</i></button>  
                        <button className="btn btn-danger float-end d-none" data-bs-toggle="modal" data-bs-target="#verticalycentered">END OF SHIFT <i className="bi bi-shop-window"></i></button>   
                        <button id="receiptbtn" ref={buttonRef} className="btn btn-info float-end" data-bs-toggle="modal" data-bs-target="#receiptmodal">LAST RECEIPT PRINT <i className="bi bi-ticket"></i> <i className="badge bg-danger"></i></button> 
                      <button id="receiptbtn2" ref={buttonRef2} className="btn btn-info float-end" data-bs-toggle="modal" data-bs-target="#receiptmodal2">LAST RESERVATION PRINT <i className="bi bi-ticket"></i> <i className="badge bg-danger"></i></button> 
                    </div>
                   
                </div>
             
                        
                
            
            </div>
           
            <div className="card-body">
              

            
                <div className="col-lg-12">
                <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                        <li className="nav-item flex-fill" role="presentation">
                            <button className="nav-link w-100" onClick={fetchAllCakes} id="home-tab" data-bs-toggle="tab" data-bs-target="#home-justified" type="button" role="tab" aria-controls="home" aria-selected="true">
                            CAKES <i className="bi bi-cake"></i> 
                            </button>
                        </li>
                        <li className="nav-item flex-fill" role="presentation">
                            <button className="nav-link w-100 active" onClick={fetchAllSnacks} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-justified" type="button" role="tab" aria-controls="profile" aria-selected="false">
                            SNACKS <i className="bi bi-cookie"></i>
                            </button>
                        </li>
                      
                        </ul>
                        <div className="tab-content pt-2" id="myTabjustifiedContent">
                        <div className="tab-pane fade" id="home-justified" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row" style={{maxHeight:500, overflowY: 'scroll'}}>
                        <input type="text" 
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="form-control col-lg-4" placeholder="search cake"/>
                        {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                            filteredAllcakes.map(inv=>{
                                let avail="";
                                let sstat;
                                if(inv.total_quantity>0){
                                    avail="Available";
                                    sstat=1;
                                }else{
                                    avail="Not Available";
                                    sstat=0
                                }

                
                                return (
                                   
                                    <div className="col-lg-12 rounded shadow p-1">
                                      <div 
                                       ref={divRef}
                                       className={`row clickable ${clicked ? 'clicked' : ''}`}
                                       onClick={()=>handleClick(inv.concatenated_sizes,inv.cakeID,inv.total_quantity,inv.cake_type,inv.price_per_cakeID_sizeID,inv.quantity_per_sizeID)}
                                       style={{ '--ripple-left': rippleStyle.left, '--ripple-top': rippleStyle.top }}
                                       data-bs-toggle="modal" data-bs-target="#sizes" 
                                        >
                                       <div className="card-body">
                                            <h5 className="card-title">{inv.cake_type} | <span className={(sstat==1)?"text-success":"text-danger"}> {avail}</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <img src={link+inv['photo']} style={{maxHeight:'80px', maxWidth:'100px', borderRadius:'50%'}}/>
                                        
                                                
                                                </div>
                                                <div className="ps-3">
                                                <h6>Total Stock :{inv.total_quantity}</h6>
                                                <span class="text-success small pt-1 fw-bold">Sizes: {inv.concatenated_sizes}</span> <span class="text-muted small pt-2 ps-1"> </span>

                                                </div>
                                               
                                            </div>
                                            </div>     
                                      
                                      </div>
                                    </div>
                                  )})
                        )}
                        
                         </div>
                         </div>
                        <div className="tab-pane fade show active" id="profile-justified" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row" style={{maxHeight:500, overflowY: 'scroll'}}>
                        <input type="text" 
                          value={searchQuerysnack}
                          onChange={(e) => setSearchQuerysnack(e.target.value)}
                          className="form-control col-lg-4" placeholder="search snack"/>
                        {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                            filteredAllsnack.map(inv=>{
                                let avail="";
                                let astat;
                                if(inv.total_quantity>0){
                                    avail="Available";
                                    astat=1;
                                }else{
                                    avail="Not Available";
                                    astat=0;
                                }

                
                                return (
                                   
                                    <div className="col-lg-12 rounded shadow p-1">
                                      <div 
                                       ref={divRef}
                                       className={`row clickable ${clicked ? 'clicked' : ''}`}
                                       onClick={()=>handleClicksnacks(inv.snackID,inv.total_quantity,inv.snack_name,inv.price)}
                                       style={{ '--ripple-left': rippleStyle.left, '--ripple-top': rippleStyle.top }}
                                       data-bs-toggle="modal" data-bs-target="#snacksmodal" 
                                        >
                                       <div className="card-body">
                                            <h5 className="card-title">{inv.snack_name}| <span className={(astat==1)?"text-success":"text-danger"}> {avail}</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                <img src={link+inv['photo']} style={{maxHeight:'80px', maxWidth:'100px', borderRadius:'50%'}}/>
                                        
                                                
                                                </div>
                                                <div className="ps-3">
                                                <h6>Total Stock :{inv.total_quantity}</h6>
                                                <h6>Price :{inv.price}</h6>
                                                </div>
                                               
                                            </div>
                                            </div>     
                                      
                                      </div>
                                    </div>
                                  )})
                        )}
                        
                         </div>
                        </div>
                        
                        </div>
                </div>
                
           

             
                        
              

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default OrdertakingContent;
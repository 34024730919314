import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SalesChart = ({data}) => {
  // Data array
//   const data = [
//     { snackID: 1, branchID: 2, total_amount: 466, total_sales: 2, snack_name: 'siopao' },
//     { snackID: 2, branchID: 2, total_amount: 500, total_sales: 5, snack_name: 'coke' },
//     { snackID: 3, branchID: 2, total_amount: 466, total_sales: 2, snack_name: 'sisaw' },
//     { snackID: 4, branchID: 2, total_amount: 1000, total_sales: 2, snack_name: 'sisig' }
//   ];

  // Extract snack names and total amounts
  const labels = data.map(item => item.cake_type);
  const totalAmounts = data.map(item => item.total_sold);
  const totalAmountss = data.map(item => item.total_amount);
  const totalAmountSum = totalAmountss.reduce((sum, value) => sum + value, 0);
  const formattedTotalAmountSum = totalAmountSum.toLocaleString('en-PH', {
    style: 'currency',
    currency: 'PHP',
  });
  // Chart data
  const chartData = {
    labels: labels, // X-axis labels (snack names)
    datasets: [
      {
        label: 'Total Sales : '+`${formattedTotalAmountSum}`,
        data: totalAmounts, // Y-axis data (total amount)
        backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cake Sales',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Total Amount',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Cake Type',
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default SalesChart;

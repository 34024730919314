import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

function DasboardContent(){
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const navigate = useNavigate();
  const [salestoday, setSalestoday] = useState(null);
  const [salesweek, setSalesweek] = useState(null);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
        
          validate();
    }
    fetchAllEmployees();
    getSalestoday();
    getSalesweek();
    getLogs();
  }, [myToken,myTokenID]);
  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         //console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
    } catch (error) {
        //console.error('Error submitting form', error);
    }
  };
  const fetchAllEmployees = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getEmployeelatesundertime.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setEmployees(response.data);
        setisLoading(false);
    } catch (error) {
       // console.error('Error submitting form', error);
    }
  };

  const getSalestoday = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getSalestoday.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data[0]);
        setSalestoday(response.data[0]);
        setisLoading(false);
    } catch (error) {
        //console.error('Error submitting form', error);
    }
  };
  const getSalesweek = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getSalesweek.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data[0]);
        setSalesweek(response.data[0]);
        setisLoading(false);
    } catch (error) {
        //console.error('Error submitting form', error);
    }
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  // Filtered ticket orders based on the search query
  const filteredEmployees = employees.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

const handleyesterday= async()=>{
  const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getEmployeelatesundertimeyesterday.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setEmployees(response.data);
        setisLoading(false);
    } catch (error) {
        //console.error('Error submitting form', error);
    }
};
const getLogs= async()=>{
  const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getLogs.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setLogs(response.data);
        
    } catch (error) {
       // console.error('Error submitting form', error);
    }
};
const formatCurrency = (number) => {
  if (!number) return 0;

  // Parse the input to a float, then format it to two decimal places
  let floatNumber = number.toFixed(2);

  // Format with commas
  return floatNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const [difference, setDifference] = useState('');
const calculateDifference = (startTime,endTime) => {
  if (startTime && endTime) {
    const start = new Date(startTime);
    const end = new Date(endTime);

    // Calculate the difference in milliseconds
    const diffInMs = end - start;

    if (diffInMs < 0) {
      return 0;
      return;
    }

    // Convert difference to hours and minutes
    const diffInHrs = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInMins = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

   
    if (diffInHrs >= 1 && diffInHrs <= 23){
      return `${diffInHrs} hour(s) and ${diffInMins} minute(s) ago`;
    }else if (diffInHrs>=24){
      return `${(diffInHrs/24).toFixed(1)} day(s) ago`;
    }else{
      return `${diffInMins} minute(s) ago`;
    }
    
  } else {
    return 0;
  }
};
return(
    <>
    <main id="main" className="main">

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Dashboard</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-8">
      <div className="row">

       
        <div className="col-xxl-6 col-md-6">
          <div className="card info-card sales-card">

            

            <div className="card-body">
              <h5 className="card-title">Sales <span>| This Week</span></h5>

              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                <i className="bi bi-currency-peso">&#8369;</i>
                </div>
                <div className="ps-3">
                  <h6>
                  {salesweek ? (
                  <h1>{formatCurrency(salesweek.salestoday)}</h1>
                ) : (
                  <p>Loading...</p>
                )}
                  </h6>
                 
                </div>
              </div>
            </div>

          </div>
        </div>

      
        <div className="col-xxl-6 col-md-6">
          <div className="card info-card revenue-card">


            <div className="card-body">
              <h5 className="card-title">Sales <span>|  Today</span></h5>

              <div className="d-flex align-items-center">
                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                  <i className="bi bi-currency-peso">&#8369;</i>
                </div>
                <div className="ps-3">
                  <h6>
                  {salestoday ? (
                  <h1>{formatCurrency(salestoday.salestoday)}</h1>
                ) : (
                  <p>Loading...</p>
                )}
                  </h6>
                 

                </div>
              </div>
            </div>

          </div>
        </div>

      
        
      

       
        <div className="col-12">
          <div className="card recent-sales overflow-auto">

            <div className="filter">
              <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <li className="dropdown-header text-start">
                  <h6>Filter</h6>
                </li>

                <li><a className="dropdown-item" href="#" onClick={fetchAllEmployees}>Today</a></li>
                <li><a className="dropdown-item" href="#" onClick={handleyesterday}>Yesterday</a></li>
                
              </ul>
            </div>

            <div className="card-body">
              <h5 className="card-title">Employees Logs <span>| Today</span></h5>
              <input type="text" value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} placeholder="search"  />
               <div className="card-body" style={{overflowY:'scroll', maxHeight:450}}>
             
                
             <table className="table table-borderless datatable">
               <thead>
                 <tr>
                   <th scope="col">ID #</th>
                   <th scope="col">Name</th>
                   <th scope="col">Undertime</th>
                   <th scope="col">Lates</th>
                 
                 
                 </tr>
               </thead>
               <tbody>

               {isLoading ? (
                           
                           <tr>
                              <th scope="row"><div className="waving col-lg-12"></div></th>
                               <td><div className="waving col-lg-12"></div></td>
                               <td><div className="waving col-lg-12"></div></td>
                               <td><div className="waving col-lg-12"></div></td>
                               <td><div className="waving col-lg-12"></div></td>
                               <td>
                               <div className="waving col-lg-4"></div>
                               </td>
                           </tr>
                       ) : (
                        filteredEmployees.map(item => {
                           let status = "";
                           let badgeClass = "";
       
                          
       
                           return (
                             <tr key={item.id}>
                               <th scope="row">{item.id}</th>
                               <td>{item.name}</td>
                               <td>{item.undertime}</td>
                               <td>{item.late}</td>
                               
                              
                             </tr>
                           );
                         })
                       )}
              

                
                 
               </tbody>
             </table>

           </div>
             

            </div>

          </div>
        </div>

        
        

      </div>
    </div>

   
    <div className="col-lg-4">

      
      <div className="card">
        

        <div className="card-body">
          <h5 className="card-title">Recent Activity <span>| Today</span></h5>

          <div className="activity" style={{maxHeight:'620px', overflowX:'scroll'}}>
            {logs.map(item=>
            {
              const now = new Date();
              let times=calculateDifference(item.date_added,now);
              
              return(
              <>
              <div className="activity-item d-flex">
              <div className="activite-label">{times}</div>
              <i className='bi bi-circle-fill activity-badge text-info align-self-start'></i>
              <div className="activity-content">
               {item.details}
              </div>
              </div>
              </>
            )})}           
            

           

          </div>

        </div>
      </div>

     
     

      
      

      
      

    </div>

  </div>
</section>

</main>

    </>
);
}
export default DasboardContent;
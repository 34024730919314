import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route,Routes, Navigate} from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Registration from './components/Registration';
import Login from './components/Login';
import Logout from './components/Logout';
import Addemployee from './components/Addemployee';
import Employees from './components/Employees';
import Cakes from './components/Cakes';
import Snacks from './components/Snacks';
import Baker from './components/Baker';
import Decorator from './components/Decorator';
import Delivery from './components/Delivery';
import Manager from './components/Manager';
import ClockProvider from './components/ClockContext';
import Schedule from './components/Schedule';
import Payroll from './components/Payroll';
import Ordertaking from './components/Ordertaking';
import Managersnacks from './components/Managersnacks';
import Server from './components/Server';
import Hotkitchen from './components/Hotkitchen';
import SnacksDelivery from './components/SnacksDelivery';
import SnacksManager from './components/SnacksManager';
import Cashier from './components/Cashier';
import Ordersviewer from './components/Ordersviewer';
import StartofDay from './components/StartofDay';
import ChecklistConfig from './components/ChecklistConfig';
import InventoryReport from './components/InventoryReport';
import SalesHistory from './components/SalesHistory';
import EmpAttendance from './components/EmpAttendance';
function App() {
  return (
    <ClockProvider>
    <Router>
      
    <Routes>
    <Route path="/employeeattendance" element={<EmpAttendance />} />
     <Route path="/saleshistory" element={<SalesHistory />} /> 
    <Route path="/inventoryreport" element={<InventoryReport />} /> 
     <Route path="/checklistconfig" element={<ChecklistConfig />} /> 
     <Route path="/startofday" element={<StartofDay />} /> 
     <Route path="/ordersviewer" element={<Ordersviewer/>} /> 
     <Route path="/cashier" element={<Cashier />} /> 
     <Route path="/managersnacksdelivery" element={<SnacksManager/>} /> 
     <Route path="/snacksdelivery" element={<SnacksDelivery />} /> 
     <Route path="/hotkitchen" element={<Hotkitchen/>} /> 
      <Route path="/server" element={<Server/>} /> 
     <Route path="/managersnacks" element={<Managersnacks />} /> 
      <Route path="/ordertaking" element={<Ordertaking />} /> 
      <Route path="/payroll" element={<Payroll />} /> 
     <Route path="/schedule" element={<Schedule />} /> 
      <Route path="/manager" element={<Manager />} /> 
      <Route path="/delivery" element={<Delivery />} /> 
      <Route path="/decorator" element={<Decorator />} />  
      <Route path="/baker" element={<Baker />} /> 
      <Route path="/snacks" element={<Snacks />} />
      <Route path="/cakes" element={<Cakes/>} />
      <Route path="/register" element={<Registration />} />
      <Route path="/addemployee" element={<Addemployee />} />
      <Route path="/employees" element={<Employees />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  </Router>
  </ClockProvider>
  );
}

export default App;

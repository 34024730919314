import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import ScheduleContent from "./ScheduleContent";
function Schedule(){
 return(
    <>
    <SideNav />
    <ScheduleContent/>
    <Footer/>
    </>
 );
}
export default Schedule;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import SnacksContent from "./SnacksContent";


function Snacks(){
 return(
    <>
    <SideNav />
    <SnacksContent />
    <Footer/>
    </>
 );
}
export default Snacks;
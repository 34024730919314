import {React,useState,useEffect} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';

function StartofDayContent(){
  const navigate = useNavigate();
 
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [branches, setBranches] = useState([]);
  const [levels, setLevels] = useState([]);
  const [checklist, setchecklist] = useState([]);
  const [selections, setSelections] = useState({});
  const [kitchenPhoto, setKitchenPhoto] = useState(null);
  const [diningPhoto, setDiningPhoto] = useState(null);
  const [expiredProductsPhoto, setExpiredProductsPhoto] = useState(null);
  const [general, setGeneral] = useState('');
  const [topush, setTopush] = useState('');
  const handleRadioChange = (itemId, value) => {
    setSelections(prevState => ({
      ...prevState,
      [itemId]: value
    }));
  };
  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };
  const handleGeneral = (e) => {
    setGeneral(e.target.value);
  };
  const handleItemtopush = (e) => {
    setTopush(e.target.value);
  };
  
  
  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
        validate();
    }
    getChecklists(); 
   
  }, [myToken,myTokenID]);

  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const getChecklists = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getstartChecklists.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setchecklist(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    
    // Append radio button selections
    for (const [itemId, value] of Object.entries(selections)) {
      formData.append(`selection_${itemId}`, value);
    }
    
    // Append files
    formData.append('topush', topush);
    formData.append('generalnote', general);
    formData.append('kitchenPhoto', kitchenPhoto);
    formData.append('diningPhoto', diningPhoto);
    formData.append('expiredProductsPhoto', expiredProductsPhoto);
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisSubmitting(true);
    try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/saveChecklists.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if(response.data['status']==='success'){
        Swal.fire({
          title: "Great!",
          text: "Checklist has been submitted",
          icon: "success"
        });
      }else{
        Swal.fire({
          title: "Ops!",
          text: "Something went wrong "+response.data['message'],
          icon: "error"
        });
      }
      setisSubmitting(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
return(
    <>
    <main id="main" class="main">

<div class="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Start of Day Checklist</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
            <li class="breadcrumb-item active">Start of Day Checklist</li>
          </ol>
        </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
</div>
</div>
<section class="section dashboard">
  <div class="row">

    
    <div class="col-lg-12">
      <div class="row">

       
        <div class="col-xxl-8 col-md-8">
          <div class="card info-card sales-card">

            

          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Pre-opening Checklist</h5>

            
              <div class="row g-3">
              <table className="table datatable">
                <thead>
                  <tr>
                    <th scope="col">Checklist Details</th>
                    <th scope="col">Done</th>
                    <th scope="col">Not Done</th>
                    <th scope="col">Needs Correction</th>
                   
                  </tr>
                </thead>
                <tbody>

                {isLoading ? (
  <tr>
    <td><div className="waving col-lg-12"></div></td>
    <td><div className="waving col-lg-12"></div></td>
    <td><div className="waving col-lg-12"></div></td>
  </tr>
) : (
  checklist.map(item => (
    <tr key={item.id}>
      <td>{item.details}</td>
      <td>
        <input
          type="radio"
          style={{width: '20px', height: '20px'}}
          name={`cb-${item.id}`}
          value="done"
          onChange={() => handleRadioChange(item.id, 'done')}
        />
      </td>
      <td>
        <input
          type="radio"
          style={{width: '20px', height: '20px'}}
          name={`cb-${item.id}`}
          value="notdone"
          onChange={() => handleRadioChange(item.id, 'notdone')}
        />
      </td>
      <td>
        <input
          type="radio"
          style={{width: '20px', height: '20px'}}
          name={`cb-${item.id}`}
          value="needconfirm"
          onChange={() => handleRadioChange(item.id, 'needconfirm')}
        />
      </td>
    </tr>
  ))
)}

                
                </tbody>
              </table>
             
                
              </div>

            </div>
          </div>

          </div>
        </div>

      
        <div class="col-xxl-4 col-md-4">
          <div class="card info-card sales-card">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">OPENING CHECKLIST SHOULD BE SUBMITTED BEFORE 10:00 AM</h5>

                 <div class="col-md-12 mt-2 bg-info p-2 rounded">
                  <label for="phealth" class="form-label">Picture of Dining Area</label><br/>
                  <input type="file" onChange={(e) => handleFileChange(e, setDiningPhoto)} />
                  </div>
                  <div class="col-md-12 mt-2 bg-info p-2 rounded">
                  <label for="phealth" class="form-label">Picture of Kitchen Area</label><br/>
                  <input type="file" onChange={(e) => handleFileChange(e, setKitchenPhoto)} />

                  </div>
                  <div class="col-md-12 mt-2 bg-info p-2 rounded">
                  <label for="phealth" class="form-label">Picture of Expired Products</label><br/>
                  <input type="file" onChange={(e) => handleFileChange(e, setExpiredProductsPhoto)} />
                  </div>
              <div class="row">
                
                
              </div>

            </div>

            
          </div>
          </div>
        </div>

        <div class="col-xxl-12 col-md-12">
        <label for="general" class="form-label">What snack Item are you pushing today?</label><br/>
        <input  type="text" name="topush" onChange={handleItemtopush} value={topush} className="form-control"/>
        <label for="general" class="form-label">Manager's General Note:</label><br/>
        <textarea className="form-control" onChange={handleGeneral} value={general}></textarea>
        <button className="btn btn-primary mt-2 form-control" onClick={handleSubmit} disabled={isSubmitting?true:false}>Submit {isSubmitting?(<i className="fa fa-spinner fa-spin"></i>):""}</button>
        </div>

      </div>
    </div>

   

  </div>
</section>

</main>

    </>
);
}
export default  StartofDayContent;
import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';
function HotkitchenContent(){
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const link='https://megaxsolutions.com/api/middleware/uploads/';
    const [cakes, setCakes] = useState([]);
    const [deliveredinventory, setDeliveredinventory] = useState([]);
    const [deliveryinventory, setDeliveryinventory] = useState([]);
    const [bakerinventory, setBakerinventory] = useState([]);
    const [icinginventory, setIcinginventory] = useState([]);
    const [allcakes, setAllCakes] = useState([]);
    const [cakesSizes, setCakeSizes] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [caketype, setCaketype] = useState('');
    const [cakesize, setSize] = useState('');
    const [cakeprice, setCakePrice] = useState('');
    const [cakesizeselect, setSizeselect] = useState('');
    const [cakeidselect, setCakeselect] = useState('');
    const [myToken, setToken] = useState('');
    const [myTokenID, setTokenID] = useState('');
    const [cakephoto, setCakephoto] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [quantity, setQuantity] = useState('');
  
  
    useEffect(() => {
      // Get cookie by name
      const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      };
  
      // Get token from cookie
    
     const token = getCookie('token');
     const tokenID = getCookie('tokenID');
     setToken(token);
     setTokenID(tokenID);
      if (token) {
          
        validate();
      }
      fetchAllCakes();
      fetchBakerinventory();
    }, [myToken,myTokenID]);
    const validate = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      setisLoading(true);
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
           console.log(response.data);
          
          if(response.data[0]['success']==1){
           
          }else{
            navigate('/login');
          }
      } catch (error) {
          console.error('Error submitting form', error);
      }
    };
    const triggerButtonClick = () => {
      if (buttonRef.current) {
        buttonRef.current.click();
      }
    };
      const handleCakeselect=(e)=>{
        setCakeselect(e.target.value);
      };
      const handleQuantityChange=(e)=>{
        setQuantity(e.target.value);
      };
      const handleCakePrice=(e)=>{
        setCakePrice(e.target.value);
      };
      const handleSizeselect =(e)=>{
        setSizeselect(e.target.value);
      };
      const handleCaketypeChange = (e) => {
          setCaketype(e.target.value);
      };
      const handleSizeChange = (e) => {
        setSize(e.target.value);
    };
  
      const handleCakephotoChange = (e) => {
          setCakephoto(e.target.files[0]);
      };
  
      const handleSubmit = async (e) => {
          e.preventDefault();
          setSubmitting(true);
          const formData = new FormData();
          formData.append('quantity', quantity);
          formData.append('snackID', cakeidselect);
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
        
        if(cakeidselect==0 || cakeidselect==null){
            alert("Please select cake type");
            setSubmitting(false);
            return;
        }
        
        if(quantity==0 || quantity==null){
            alert("Please input quantity");
            setSubmitting(false);
            return;
        }
          try {
              const response = await axios.post('https://megaxsolutions.com/api/middleware/addSnackinventory.php', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              });
              console.log(response.data);
              if(response.data['success']){
                Swal.fire({
                  title: "Great!",
                  text: response.data['success'],
                  icon: "success"
                });
                setQuantity("");
                setSubmitting(false);
                triggerButtonClick();
                fetchAllCakes();
              }else{
                Swal.fire({
                  title: "Ops!",
                  text: response.data['error'],
                  icon: "info"
                });
              }
              
          } catch (error) {
              console.error('Error submitting form', error);
          }
      };
  
  
      
    
  
      const fetchCakes = async () => {
        const formData = new FormData();
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/getsnacks.php', formData, {
                headers: {
                    'Content-Type': 'application/form-data'
                }
            });
            console.log(response.data);
            setCakes(response.data);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
  
    const fetchSizes = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/getsizes.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          console.log(response.data);
          setCakeSizes(response.data);
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };
  
  const fetchAllCakes = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getallsnackskitchen.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setAllCakes(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  
  
  
   const handleget =()=>{
    fetchCakes();
    fetchSizes();
   };   
   
   const fetchBakerinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBakerinventory.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setBakerinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handlebakerinventory=()=>{
    fetchBakerinventory();
  };
  const fetchIcinginventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllIcinginventory.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setIcinginventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handleicinginventory=()=>{
    fetchIcinginventory();
  };
  const fetchDeliveryinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllDeliveryinventory.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setDeliveryinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handledeliveryinventory=()=>{
    fetchDeliveryinventory();
  };
  const fetchDeliveredinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllInventorydelivered.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setDeliveredinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handledeliveredinventory=()=>{
    fetchDeliveredinventory();
  };
  const formatDate = (datei) => {
    const date =new Date(datei);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  };
  
  
return(
    <>
    <main id="main" className="main">

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Manage Items</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Items</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
      <div className="card">
            <div className="card-body">
              

             
              <ul className="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified" role="tablist">
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={handlebakerinventory}>Snacks Finished</button>
                </li>
                
               
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100" id="contact-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-contact" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={handledeliveryinventory}>Snacks for Delivery</button>
                </li>
               
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100" id="contact-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-cakesuccess" type="button" role="tab" aria-controls="cakesuccess" aria-selected="false" onClick={handledeliveredinventory}>Snacks Delivered</button>
                </li>
               
              </ul>
              <div className="tab-content pt-2" id="borderedTabJustifiedContent">
                <div className="tab-pane fade show active" id="bordered-justified-home" role="tabpanel" aria-labelledby="home-tab">
                <button type="button" className="btn btn-success" onClick={handleget} data-bs-toggle="modal" data-bs-target="#verticalycentered">
                    Add <i className="fa fa-plus-circle"></i>
              </button>

                  <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add New finished</h5>
                      <button type="button" ref={buttonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <select className="form-control" id="cakeID" name="cakeID" onChange={handleCakeselect} value={cakeidselect}>
                        <option value={0} key={0}>select snacks</option>
                        {cakes.map(cake => (
                            <option key={cake.id} value={cake.id}>{cake.snack_type}</option>
                        ))}
                      </select>
                     
                      <hr/>
                      <input type="text" 
                       value={quantity}
                       onChange={handleQuantityChange}
                       id="quantity" name="quantity" placeholder="Enter quantity" className="form-control"/>
                      <hr/>
                    
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" disabled={submitting?true:false} onClick={handleSubmit} >Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>
                  <div className="col-lg-12">
                        <div className="row" style={{maxHeight:500, overflowY: 'scroll'}}>
                        {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                          allcakes.map(inv=>{
                          let ttl=inv.quantity - inv.fordelivery - inv.delivered;
                          if(ttl>0){
                          return(
                              <div className="col-lg-12 rounded shadow p-1">
                                <div className="row">
                                  <div className="col-lg-2 mt-2 rounded"><img src={link+inv['photo']} style={{borderRadius: '50%', maxHeight:90, maxWidth:90}}/></div>
                                  <div className="col-lg-6 mt-2 rounded"><strong>{inv['snack_name']}</strong>  <br/> Remaining: {inv.quantity - inv.fordelivery - inv.delivered} <br/>
                                   Date: {formatDate(inv['date_added'])}</div>
                                  </div>
                              </div>
                            ) }else{
  
                            }})
			                  )}	   
                        
                        
                        
                         </div>
                  </div>

                  </div>
               
                <div className="tab-pane fade" id="bordered-justified-contact" role="tabpanel" aria-labelledby="contact-tab">
                <div className="row" style={{maxHeight:500, overflowY: 'scroll'}}>
                        {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                          allcakes.map(inv=>{
                          
                          if(inv.fordelivery>0){
                          return(
                              <div className="col-lg-12 rounded shadow p-1">
                                <div className="row">
                                  <div className="col-lg-2 mt-2 rounded"><img src={link+inv['photo']} style={{borderRadius: '50%', maxHeight:90, maxWidth:90}}/></div>
                                  <div className="col-lg-6 mt-2 rounded"><strong>{inv['snack_name']}</strong>  <br/> For delivery: {inv.fordelivery} <br/>
                                   Date: {formatDate(inv['date_added'])}</div>
                                  </div>
                              </div>
                            ) }else{
  
                            }})
			                  )}	   
                        
                         </div>
                  </div>
               
                <div className="tab-pane fade" id="bordered-justified-cakesuccess" role="tabpanel" aria-labelledby="cakesuccess-tab">
                <div className="row" style={{maxHeight:500, overflowY: 'scroll'}}>
                        {isLoading ? (
                            
                            <>
                            <div className="waving col-lg-2"></div>
                            <div className="waving col-lg-4"></div>
                            <div className="waving col-lg-4"></div>
                            </>
                        ) : (
                          allcakes.map(inv=>{
                          
                          if(inv.delivered>0){
                          return(
                              <div className="col-lg-12 rounded shadow p-1">
                                <div className="row">
                                  <div className="col-lg-2 mt-2 rounded"><img src={link+inv['photo']} style={{borderRadius: '50%', maxHeight:90, maxWidth:90}}/></div>
                                  <div className="col-lg-6 mt-2 rounded"><strong>{inv['snack_name']}</strong>  <br/> Delivered: {inv.delivered} <br/>
                                   Date: {formatDate(inv['date_added'])}</div>
                                  </div>
                              </div>
                            ) }else{
  
                            }})
			                  )}	   
                        
                         </div>
                  </div>
                <div className="tab-pane fade" id="bordered-justified-snackssucess" role="tabpanel" aria-labelledby="snackssucess-tab">
                    Snacks Delivered
                </div>
              </div>

            </div>
          </div>
        
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default HotkitchenContent;
import React from "react";
function Footer(){
return(
    <>
    <footer id="footer" class="footer">
        <div className="copyright">
        &copy; Copyright <strong><span>Megaxsolutions</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
        </div>
</footer>
</>
);
}
export default Footer;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import CakesContent from "./CakesContent";

function Cakes(){
 return(
    <>
    <SideNav />
    <CakesContent/>
    <Footer/>
    </>
 );
}
export default Cakes;
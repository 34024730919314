import React from "react";
import SideNav from "./SideNav";

import Footer from "./Footer";
import StartofDayContent from "./StartofDayContent";
function StartofDay(){
 return(
    <>
    <SideNav />
    <StartofDayContent />
    <Footer/>
    </>
 );
}
export default StartofDay;
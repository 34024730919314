import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import PayrollContent from "./PayrollContent";

function Payroll(){
 return(
    <>
    <SideNav />
    <PayrollContent />
    <Footer/>
    </>
 );
}
export default Payroll;
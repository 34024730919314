import React from "react";
import SideNav from "./SideNav";

import Footer from "./Footer";
import ChecklistConfigContent from "./ChecklistConfigContent";
function ChecklistConfig(){
 return(
    <>
    <SideNav />
    <ChecklistConfigContent />
    <Footer/>
    </>
 );
}
export default ChecklistConfig;
import {React,useState,useEffect} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';
import Select from 'react-select';
function ManagersnacksContent(){
    const navigate = useNavigate();
    const link='https://megaxsolutions.com/api/middleware/uploads/';
    const [branches, setBranches] = useState([]);
    const [branchselect, setBranchselect] = useState([]);
    const [selectedSnack, setSelectedSnack] = useState(null);
    const [cakes, setCakes] = useState([]);
    const [snacks, setSnacks] = useState([]);
    const [deliveredinventory, setDeliveredinventory] = useState([]);
    const [deliveryinventory, setDeliveryinventory] = useState([]);
    const [bakerinventory, setBakerinventory] = useState([]);
    const [icinginventory, setIcinginventory] = useState([]);
    const [snackinventory, setSnackinventory] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [prodinvID, setprodinvID] = useState('');
    const [cakesizeselect, setSizeselect] = useState('');
    const [cakeidselect, setCakeselect] = useState('');
    const [quantityselect, setQuantityselect] = useState('');
    const [myToken, setToken] = useState('');
    const [myTokenID, setTokenID] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [quantity, setQuantity] = useState('');
    const [caketype, setCaketype] = useState('');
    const [cakesize, setCakesize] = useState('');
    const [currentquantity, setCurrentquantity] = useState('');
    const [decorID, setDecorID] = useState('');
    const [deliveryID, setDeliveryID] = useState('');
    const [damage, setDamage] = useState('');
    useEffect(() => {
      // Get cookie by name
      const getCookie = (name) => {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
      };
  
      // Get token from cookie
    
     const token = getCookie('token');
     const tokenID = getCookie('tokenID');
     setToken(token);
     setTokenID(tokenID);
      if (token) {
         
        validate();
      }
    
      fetchSnacks();
    }, [myToken,myTokenID]);
    const validate = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      setisLoading(true);
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
           console.log(response.data);
          
          if(response.data[0]['success']==1){
           
          }else{
            navigate('/login');
          }
          setisLoading(false);
      } catch (error) {
          console.error('Error submitting form', error);
      }
    };
      

      const handleQuantityChange=(e)=>{
        var quantitytoput=e.target.value;

        setQuantity(e.target.value);
        setCurrentquantity(quantityselect-quantitytoput);
        console.log(currentquantity);
      };
      const handleDamageChange=(e)=>{
        setDamage(e.target.value);
      };
      
     
    
      const handleSubmit = async (e) => {
          e.preventDefault();
          setSubmitting(true);
          const formData = new FormData();
          formData.append('damage', damage);
          formData.append('deliveryID', deliveryID);
          formData.append('decorID', decorID);
          formData.append('prodinvID', prodinvID);
          formData.append('quantity', quantity);
          formData.append('cakeid', cakeidselect);
          formData.append('cakesize', cakesizeselect);
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
        
        
        if(currentquantity<=0){
          alert("All Cakes has already been set to received");
          setSubmitting(false);
          return;
        }
        if(damage==null || damage==''){
            alert("set 0 if no damage or lacking");
            setSubmitting(false);
            return;
          }
        if(quantity>quantityselect){
            alert("Quantity should not be greater than number of cakes to receive");
            setSubmitting(false);
            return;
        }
        if(quantity==0 || quantity==null){
            alert("Please input quantity");
            setSubmitting(false);
            return;
        }
        
          try {
              const response = await axios.post('https://megaxsolutions.com/api/middleware/addbranchinventory.php', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              });
              console.log(response.data);
              if(response.data['success']){
                alert(response.data['success']);
                setQuantity("");
                setSubmitting(false);
                
                setCurrentquantity(currentquantity-quantity);
                console.log(currentquantity);
              }else{
                alert(response.data['error']);
              }
              
          } catch (error) {
              console.error('Error submitting form', error);
          }
      };
  
  
      
    
  
    const fetchCakes = async () => {
        const formData = new FormData();
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/getcakes.php', formData, {
                headers: {
                    'Content-Type': 'application/form-data'
                }
            });
            console.log(response.data);
            setCakes(response.data);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
  
    const fetchSizes = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/getsizes.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          console.log(response.data);
          
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };
  const fetchAllBranches = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBranches.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
       // console.log(response.data);
        setBranches(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllCakes = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getallcakes.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  
  
  
   const handleGet =(id,decoratorID,prodinvID,cakeID,sizeID,caketype,cakesize,quantityselected,deliveredq)=>{
      setDeliveryID(id);
      setDecorID(decoratorID);
      setprodinvID(prodinvID);
      setCakeselect(cakeID);
      setSizeselect(sizeID);
      setCaketype(caketype);
      setCakesize(cakesize);
      setQuantityselect(deliveredq);
      setQuantity(deliveredq);
      setCurrentquantity(deliveredq);
      setDamage('0');
      console.log(prodinvID,cakeID,sizeID,caketype,cakesize,quantityselected,deliveredq);
   };   
   
   const fetchBakerinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllinventoryfrombaker.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setBakerinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handlebakerinventory=()=>{
    fetchBakerinventory();
  };

  const fetchIcinginventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllIcinginventory.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setIcinginventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handleicinginventory=()=>{
    fetchIcinginventory();
  };

  

  const fetchAllsnackinventory = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllsnacksinventorybranch.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setSnackinventory(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  

  
  const formatDate = (datei) => {
    const date =new Date(datei);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  
    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${ampm}`;
  };
  const fetchSnacks = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getsnacktype.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setSnacks(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
};
const handleQuantitychange=(e)=>{
  setQuantityselect(e.target.value);
};
const handleselectChange = (selectedOption) => {
  setSelectedSnack(selectedOption['value']);
  console.log(selectedOption['value']);
};
const handleaddInventory = async () => {
  const formData = new FormData();
  formData.append('snackID', selectedSnack);
  formData.append('quantity', quantityselect);
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
  setSubmitting(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/addSnackinventory.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
        
      }else{
        alert(response.data['success']);
      }
      setSubmitting(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};
return(
    <>
    <main id="main" className="main">

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Manage Items</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Items</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
      <div className="card">
            <div className="card-body">
              

             
              <ul className="nav nav-tabs nav-tabs-bordered d-flex" id="borderedTabJustified" role="tablist">
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100 active" id="home-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={fetchSnacks}>Add Snacks</button>
                </li>
               
                <li className="nav-item flex-fill" role="presentation">
                  <button className="nav-link w-100" id="decoration-tab" data-bs-toggle="tab" data-bs-target="#bordered-justified-decoration" type="button" role="tab" aria-controls="contact" aria-selected="false" onClick={fetchAllsnackinventory}>Snacks Inventory</button>
                </li>
               
               
              </ul>
          <div className="tab-content pt-2" id="borderedTabJustifiedContent">
            <div className="tab-pane fade show active" id="bordered-justified-home" role="tabpanel" aria-labelledby="home-tab">
              
                  <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header"> 
                              Add Snacks Inventory
                            </div> 
                            <div className="card-body"> 
                              <label>Snack Type</label>
                              <Select
                                  onChange={handleselectChange}
                                  options={snacks}
                                  isSearchable
                                  placeholder="Select Item"
                                 
                                  /> 
                             
                              <hr/>
                              <label>Quantity</label>
                              <input type='text' id='quantity' className="form-control" onChange={handleQuantitychange} />
                            </div> 
                            <div className="card-footer"> 
                            <button className="btn btn-primary" disabled={submitting?true:false} onClick={handleaddInventory}>Add to Inventory {submitting?(<><i className="fa fa-spinner fa-spin"></i></>):(<></>)}</button>
                            </div> 

                        </div>
                  </div>

                  </div>
               
                 <div className="tab-pane fade" id="bordered-justified-decoration" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="card">
                        <div className="card-header">
                          <input type='date' />
                        </div>
                        <div className="card-body">
                        <table className="table">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {snackinventory.map(item=>(
                          <>
                          <tr>
                            <td>{item.snack_name}</td>
                            <td>{item.total_quantity}</td>
                            <td>&#8369;{item.price}</td>
                          </tr>

                          </>
                        ))}
                        
                      </tbody>
                    </table>
                        </div>
                        <div className="card-footer">

                          </div>
                    </div>
                   

                        
                  </div>
                <div className="tab-pane fade" id="bordered-justified-contact" role="tabpanel" aria-labelledby="decoration-tab">
                
                        
                 
                </div>
                
                <div className="tab-pane fade" id="bordered-justified-cakesuccess" role="tabpanel" aria-labelledby="cakesuccess-tab">
                
                
                  </div>
               
              </div>

            </div>
          </div>
        
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default ManagersnacksContent;
import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import SnacksDeliveryContent from "./SnacksDeliveryContent";



function SnacksDelivery(){
 return(
    <>
    <SideNav />
    <SnacksDeliveryContent />
    <Footer/>
    </>
 );
}
export default SnacksDelivery;
import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2';
import './style.css';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
function OrdersviewerContent(){
  const navigate = useNavigate();
  const buttonRef = useRef(null);
  const link='https://megaxsolutions.com/api/middleware/uploads/';
  const [cakes, setCakes] = useState([]);
  const [allcakes, setAllCakes] = useState([]);
  const [allsnacks, setAllSnacks] = useState([]);
  const [cakesSizes, setCakeSizes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isRemoving, setRemoving] = useState(false);
  const [caketype, setCaketype] = useState('');
  const [cakesize, setSize] = useState('');
  const [cakeprice, setCakePrice] = useState('');
  const [cakesizeselect, setSizeselect] = useState('');
  const [cakeidselect, setCakeselect] = useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [cakephoto, setCakephoto] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isOpen, setisOpen] = useState('');
  const [tickets, setTickets] = useState([]);
  const [branchdaily, setBranchdaily] = useState([]);
  const [shiftamount, setShiftamount] = useState('');
  const [shiftamountclose, setShiftamountclose] = useState('');
  const [clicked, setClicked] = useState(false);
  const [rippleStyle, setRippleStyle] = useState({});
  const divRef = useRef(null);
  const [availablesizes, setAvailablesizes] = useState([]);
  let [quantityselected, setQuantity] = useState(1);
  let [quantitystock, setQuantitystock] = useState(0);
  const [cakename, setCakename] = useState('');
  const [snackname, setSnackname] = useState('');
  const [pricepercakeidsizeid, setPricecakeidsizeid] = useState('');
  const [quantitypersizeid, setQuantitypersizeid] = useState('');
  const [cakeidsizeidconcat, setConcatcakeidsizeid] = useState('');
  const [priceselected, setPriceselected] = useState('');
  const [totalprice, setTotalprice] = useState('');
  const [totalpriceformat, setFormatTotalprice] = useState('');
  const [isRequired, setisRequired] = useState(false);
  const [isRequired2, setisRequired2] = useState(false);
  const [showdelete, setShowdelete] = useState(false);
  const [ticketscount, setTicketcount] = useState('');
  const [checkedValues, setCheckedValues] = useState([]);
  let [totalAmount, setTotalAmount] = useState(0);
  const [concatenatedQuantityValues, setConcatenatedQuantityValues] = useState([]); 
  const [concatenatedSnackIDprice, setConcatenatedSnackIDprice] = useState([]);
  const [snackid, setSnackID] = useState('');
  const [snackquantity, setSnackqunatity] = useState('');
  const [ticketorders, setTicketorders] = useState([]);
  const [amountreceivedres, setAmountreceivedres] = useState('');
  const [amountreserved, setAmountreserved] = useState('');
  const [amountchangeres, setAmountchangeres] = useState(0);
  const [updatedbalance, setUpdatedbalance] = useState('');
  const [balancetopay, setBalancetopay] = useState('');
  const [ticketname, setTicketname] = useState('');
  const [depositres, setDepositres] = useState('');
  const [paidamount, setPaidamount] = useState('');

  const [customername, setCustomername] = useState('');
  const [customeraddress, setCustomeraddress] = useState('');
  const [customerphone, setCustomerphone] = useState('');
  const [orderdesc, setOrderdesc] = useState('');
  const [duedate, setDuedate] = useState('');
  const [celebrantname, setCelebrantname] = useState('');
  const [celebrantage, setCelebrantage] = useState('');
  const [celebrantgender, setCelebrantgender] = useState('');
  const [totaldeposit, setTotaldeposit] = useState('');
  const [totalbalance, setTotalbalance] = useState('');
  const [reservationdetails, setReservationdetails] = useState([]);
  const [ctphoto, setPhotoID] = useState('');
  const [imageUrl, setImageUrl] = useState("https://megaxsolutions.com/api/middleware/uploads/" + ctphoto); // default image URL
 
  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
       
      validate();
    }
    const date = new Date();
    const formattedDate = date.getFullYear() + '-' + 
                          String(date.getMonth() + 1).padStart(2, '0') + '-' + 
                          String(date.getDate()).padStart(2, '0');
    
    getTicketordersfilterinitial(formattedDate);
  }, [myToken,myTokenID]);
  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const [searchQuery, setSearchQuery] = useState('');

  // Filtered ticket orders based on the search query
  let filteredOrders = ticketorders.filter(order =>
    order.ticket_name.toLowerCase().includes(searchQuery.toLowerCase())||
    order.customer_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  const triggerButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const handlequantityminussnack=(e)=>{
    
    quantityselected=quantityselected-1;
    if(quantityselected<1){
        quantityselected=1;
    }
   
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    
    setQuantity(quantityselected);
  };
  const handlequantityminus=(e)=>{
    
    quantityselected=quantityselected-1;
    if(quantityselected<1){
        quantityselected=1;
    }
    if(priceselected==''){
        setisRequired(true);
        return;
       }else{
        setisRequired(false);
       }
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    
    setQuantity(quantityselected);
  };
  const handlequantityplussnack=(e)=>{
    quantityselected=quantityselected+1;
    if(quantityselected<1){
        quantityselected=1;
    }
   
    if(quantityselected>quantitystock){
        alert('Ops! quantity selected is greater than in stock');
        return;
    }
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    setQuantity(quantityselected);
  };
  const handlequantityplus=(e)=>{
    quantityselected=quantityselected+1;
    if(quantityselected<1){
        quantityselected=1;
    }
   if(priceselected==''){
    setisRequired(true);
    return;
   }else{
    setisRequired(false);
   }
    if(quantityselected>quantitystock){
        alert('Ops! quantity selected is greater than in stock');
        return;
    }
    let total=priceselected*quantityselected;
    let format=total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' });
    setFormatTotalprice(format);
    setTotalprice(total);
    setQuantity(quantityselected);
  };
  const handleshiftamount=(e)=>{
    setShiftamount(e.target.value);
  };
  const handleshiftamountclose=(e)=>{
    setShiftamountclose(e.target.value);
  };
    const handleCakeselect=(e)=>{
      setCakeselect(e.target.value);
    };
    const handleCakePrice=(e)=>{
      setCakePrice(e.target.value);
    };
    const handleSizeselect =(e)=>{

        let sID=  e.target.value;
        let concat= `${cakeidselect}-${sID}`;
        let pricefound = pricepercakeidsizeid[concat]; // Find the corresponding value
        console.log(concat);
        let qfound = quantitypersizeid[sID];
        console.log(qfound);
        
        setQuantity(1); // Default quantity
        setTotalprice(pricefound * 1); // Initial calculation
        setPriceselected(pricefound);
        setSizeselect(sID);
        setQuantitystock(qfound);

        let totals = pricefound * 1; // 1 is the initial quantity
        setFormatTotalprice(totals.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }));
        
        setTotalprice(totals);
        setisRequired(false);
    };
    

    

    const handleSubmitTicket = async() => {
        const formData = new FormData();
        formData.append('cakeID', cakeidselect);
        formData.append('sizeID', cakesizeselect);
        formData.append('quantity', quantityselected);
        formData.append('totalamount', totalprice);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);

        if(cakesizeselect==0 || cakesizeselect==''){
            setSubmitting(false);
            setisRequired(true);
            return;
            
        }
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addTicket.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              
              const closeModalButton = document.querySelector('#sizes .btn-close');
              if (closeModalButton) {
                  closeModalButton.click();
              }
              getTickets();
              }else{
              alert(response.data['error']+ 'Check your connection!');
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
    const handleSubmitTicketsnacks = async() => {
        const formData = new FormData();
        formData.append('snackID', snackid);
        formData.append('quantity', quantityselected);
        formData.append('totalamount', totalprice);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);

        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addTicket.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              
              const closeModalButton = document.querySelector('#snacksmodal .btn-close');
              if (closeModalButton) {
                  closeModalButton.click();
              }
              getTickets();
              }else{
              alert(response.data['error']+ 'Check your connection!');
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

const getTickets = async() => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
   
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getTickets.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response.data);
        setTickets(response.data);
        setTicketcount(response.data.length);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

    const handleCakephotoChange = (e) => {
        setCakephoto(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('caketype', caketype);
        formData.append('cakephoto', cakephoto);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);

        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/addcake.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
              alert(response.data['success']);
              setCaketype("");
              setCakephoto(null);
            }else{
              alert(response.data['error']);
            }
            
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleSubmitshift = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('amount', shiftamount);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setisOpen(false);
        
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/openshift.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            
            if(response.data['success']){
              
              setisOpen(true);
            }else{
              alert(response.data['error']);
            }
            
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };
    const handleSubmitshiftclose = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        formData.append('amount', shiftamountclose);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        setSubmitting(true);
        try {
            const response = await axios.post('https://megaxsolutions.com/api/middleware/closeshift.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            if(response.data['success']){
             
                document.getElementById('verticalycentered').classList.remove('show');
                document.getElementById('verticalycentered').style.display = 'none';
                document.body.classList.remove('modal-open');
                document.body.style.paddingRight = '0px';
                const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
                if (modalBackdrop) modalBackdrop.parentNode.removeChild(modalBackdrop);
              setisOpen(false);
            }else{
              alert(response.data['error']);
            }
            setSubmitting(false);
        } catch (error) {
            console.error('Error submitting form', error);
        }
    };

    const handleSubmitSize = async (e) => {
      e.preventDefault();
      setSubmitting(true);
      const formData = new FormData();
      formData.append('cakesize', cakesize);
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      if(cakesize=='' || cakesize==null){
        setSubmitting(false);
        return;
      }
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/addcakesize.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          
          if(response.data['success']){
            alert(response.data['success']);
            setSize("");
            
          }else{
            alert(response.data['error']);
          }
          setSubmitting(false);
          
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };
  const handleCancel= async (ticketID) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel this item"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
 
          const formData = new FormData();
          formData.append('ticketID', ticketID);
          formData.append('token', myToken);
          formData.append('tokenID', myTokenID);
  
          setRemoving(true);
          
          const response = await axios.post('https://megaxsolutions.com/api/middleware/cancelTickets.php', formData, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
          
        
          if(response.data['success']){
            getTicketorders();
            
              
          }else{
            Swal.fire({
                title: "Ops!",
                text: "Something went wrong. check your connection "+response.data['error'],
                icon: "info"
              });
          }
          setRemoving(false);
          
        } catch (error) {
          console.error('Error submitting form', error);
          setSubmitting(false); // Ensure this is called even if there's an error
        }
      }
    });
};

  const fetchCakes = async () => {
      const formData = new FormData();
      formData.append('token', myToken);
      formData.append('tokenID', myTokenID);
      
      try {
          const response = await axios.post('https://megaxsolutions.com/api/middleware/getcakes.php', formData, {
              headers: {
                  'Content-Type': 'application/form-data'
              }
          });
          console.log(response.data);
          setCakes(response.data);
      } catch (error) {
          console.error('Error submitting form', error);
      }
  };

  const fetchSizes = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getsizes.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setCakeSizes(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
};

const fetchAllCakes = async () => {
  const formData = new FormData();
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
  setisLoading(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/getallcakesbranch.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      setAllCakes(response.data);
     response.data.map(item=>{
        const parsedValues = item.concatenated_cakeID_sizeID_quantity.split(',');
        setConcatenatedQuantityValues(prevValues => [...prevValues, ...parsedValues]);
       
     });
      
      
      setisLoading(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};
const fetchAllSnacks = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllsnacksbranch.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setAllSnacks(response.data); 
        let arr=[];
        response.data.map(item=>{
            arr.push(item.concatenated_snackID_price);
         });
        
         const keyValuePairs = arr.reduce((acc, item) => {
            const [key, value] = item.split(':');
            acc[key] = value;
            return acc;
        }, {});
        setConcatenatedSnackIDprice(keyValuePairs);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  
const checkcashierDay = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    setisOpen(false);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/checkcashier.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
       if(response.data!=0){
        setBranchdaily(response.data);
        let status="";
        response.data.map(item=>{
            status=item.branch_status;
        });
        setisLoading(false);
        console.log(status);
        if(status=='open'){
            setisOpen(true);
        }else{
            setisOpen(false);
        }
        
       }else{
        setisOpen(false);
       }
       
    } catch (error) {
        console.error('Error submitting form', error);
    }
    setisLoading(false);
  };

  


 const handleget =()=>{
  fetchCakes();
  fetchSizes();
 };   

 const converter=(value)=>{
    const keyValueArray = value.split(',');
    const resultpricepercakeidsizeid = keyValueArray.reduce((acc, pair) => {
        const [key, value] = pair.split(':');
        acc[key] = parseInt(value, 10); // Convert value to integer
        return acc;
    }, {});
    return resultpricepercakeidsizeid;
 };
const handleClickdetails=async(ticketnumber,photo)=>{
  const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('ticketnumber', ticketnumber);
    setisLoading(true);
    let urls="https://megaxsolutions.com/api/middleware/uploads/"+photo;
    setImageUrl(urls);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getReservation.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
       if(response.data!=0){
        setReservationdetails(response.data);
       }
       
    } catch (error) {
        console.error('Error submitting form', error);
    }
    setisLoading(false);
};

 const handleClick = (sizes,cakeID,stock,cakename,pricepercakeidsizeids,quantitypersizeids) => {
    const allSizesArray = [];
      let asizes = sizes.split(','); 
      asizes.map(size => {
        allSizesArray.push(size.trim());
      });
    console.log(allSizesArray);
    setAvailablesizes(allSizesArray);

    let res1=converter(pricepercakeidsizeids);
    let res2=converter(quantitypersizeids);
    setPricecakeidsizeid(res1);
    setQuantitypersizeid(res2); 

    setCakeselect(cakeID);
    setQuantitystock(stock);
    setCakename(cakename);

    setQuantity(0); // Default quantity
    setTotalprice(0.00); // Initial calculation
    setPriceselected('');
    setSizeselect(0);
    setFormatTotalprice(0.00);
    
  };
  const getIdBySize = (size) => {
    const foundSize = cakesSizes.find(item => item.size === size);
    return foundSize ? foundSize.id : null; // Return null if size not found
  };
const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let filteredItem = tickets.find(item => item.id ==  value);

        if (filteredItem) {
            
            let { cakeID, sizeID } = filteredItem;
            let concatenatedValue = `${cakeID}-${sizeID}`;
            //console.log('Concatenated Value:', concatenatedValue);
        } else {
            console.log('No item found with the specified ID.');
        }

        const quantitySum = {};

     
        tickets.forEach(item => {
            let key; 
            if(item.cakeID==null){
                key= item.snackID;
           }else{
                key = `${item.cakeID}-${item.sizeID}`;
           }
            
            
            if (quantitySum[key]) {
                quantitySum[key] += item.quantity;
            } else {
                quantitySum[key] = item.quantity;
            }
        });
        
        // Convert the quantitySum object to an array of {cakeID-sizeID: totalQuantity} pairs if needed
        const result = Object.entries(quantitySum).map(([key, totalQuantity]) => ({
            key,
            totalQuantity
        }));
        
        //console.log('Sum of quantities per cakeID and sizeID:', result);


        setCheckedValues((prevCheckedValues) => {
            if (checked) {
                // Add the value if it's checked and it's not already in the array
                return [...prevCheckedValues, value];
                
            } else {
                // Remove the value if it's unchecked
                return prevCheckedValues.filter((item) => item !== value);
            }
        });
        
};
useEffect(()=>{
    if(checkedValues.length>0){
       
        setShowdelete(true);
    }else{
        setShowdelete(false);
    }
 
});
useEffect(() => {
    const total = checkedValues.reduce((sum, id) => {
        // Convert id to number if necessary (assuming ticket.id is a number)
        const numericId = Number(id);
        const ticket = tickets.find(ticket => ticket.id === numericId);
        return sum + (ticket ? ticket.total_amount : 0);
    }, 0);

    setTotalAmount(total);
}, [checkedValues, tickets]);

const handleClickdone = async (ticket_name,ttlamount,amountreceived,amountchange) => {
  Swal.fire({
    title: ticket_name,
    text: "You are about to add this ticket to Sales,You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Set this as Sales"
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        setRemoving(true);
        let amntchange=(amountchange)? amountchange:0.00;
        let amntreceived=(amountreceived)? amountreceived:0.00;

        const formData = new FormData();
        formData.append('ticketID',  ticket_name);
        formData.append('totalamount',  ttlamount);
        formData.append('amountreceived',  amntreceived);
        formData.append('amountchange',  amntchange);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        
        const response = await axios.post('https://megaxsolutions.com/api/middleware/addsalesTickets.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        
      console.log(response.data);
        if(response.data['success']){
          getTicketorders();
            
        }else{
          Swal.fire({
              title: "Ops!",
              text: "Something went wrong. check your connection "+response.data['error'],
              icon: "info"
            });
        }
        setRemoving(false);
        
        
        
      } catch (error) {
        console.error('Error submitting form', error);
        setSubmitting(false); // Ensure this is called even if there's an error
      }
    }
  });
};
const handleClickdone2 = async () => {
  let amntchangeres=(amountchangeres)? amountchangeres:0.00;
  let amntreceivedres=(amountreceivedres)? amountreceivedres:0.00;
  if(amntreceivedres<=0){
    setisRequired(true);
    return;
  }
  if(paidamount<=0){
    setisRequired2(true);
    return;
  }

  Swal.fire({
    title: ticketname,
    text: "You are about to add this ticket to Sales,You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Set this as Sales"
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        setRemoving(true);
       

        const formData = new FormData();
        formData.append('ticketID',  ticketname);
        formData.append('totalamount',  amountreserved);
        formData.append('amountreceived',  amntreceivedres);
        formData.append('amountchange',  amntchangeres);
        formData.append('updatedbalance',  updatedbalance);
        formData.append('token', myToken);
        formData.append('tokenID', myTokenID);
        
        

        const response = await axios.post('https://megaxsolutions.com/api/middleware/addsalesTickets.php', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        
      console.log(response.data);
        if(response.data['success']){
          triggerButtonClick();
          getTicketorders();
            
        }else{
          Swal.fire({
              title: "Ops!",
              text: "Something went wrong. check your connection "+response.data['error'],
              icon: "info"
            });
        }
        setRemoving(false);
        
        
        
      } catch (error) {
        console.error('Error submitting form', error);
        setSubmitting(false); // Ensure this is called even if there's an error
      }
    }
  });
};

 
const getTicketorders = async () => {
   
  const formData = new FormData();
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
 setisLoading(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/getTicketorderscashier.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data[0]){
        //setTicketorders(response.data[0]);
        
      }else{
        alert(response.data);
      }
    
      setisLoading(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};
const getTicketordersfilterinitial = async (date) => {
   
  const formData = new FormData();
  formData.append('datefilter', date);
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
 setisLoading(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/getTicketordersviewer.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data[0]){
        setTicketorders(response.data[0]);
        
      }else{
        alert(response.data);
      }
    
      setisLoading(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};
const getTicketordersfilter = async (e) => {
   
  const formData = new FormData();
  formData.append('datefilter', e.target.value);
  formData.append('token', myToken);
  formData.append('tokenID', myTokenID);
 setisLoading(true);
  try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/getTicketordersviewer.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data[0]){
        setTicketorders(response.data[0]);
        
      }else{
        alert(response.data);
      }
    
      setisLoading(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }
};

const handleClickupdate= (ticket_name,amountres,deposit,balance)=>{
  setAmountreserved(amountres);
  setBalancetopay(balance);
  setUpdatedbalance(balance);
  setTicketname(ticket_name);
  setDepositres(deposit);
};
  const handleamountreceivedres=(e)=>{
    setAmountreceivedres(e.target.value);
    setisRequired(false);
  };
  const handlebalancetopayres=(e)=>{
    let payamount=e.target.value;
    setisRequired2(false);
    let updatedbalanceres=balancetopay-Number(payamount);
    if(payamount>balancetopay){
      alert("Amount should not be greater than payable balance amount!");
      e.target.value='';
      setAmountchangeres(0);
      return;
    }else{
      setPaidamount(payamount);
      setUpdatedbalance(updatedbalanceres);
    }

    let changeamount=amountreceivedres-Number(payamount);
    setAmountchangeres(changeamount);

  };
  const handlecustomername=(e)=>{
    setCustomername(e.target.value);
  };
  const handlecustomeraddress=(e)=>{
    setCustomeraddress(e.target.value);
  };
  const handlecustomerphone=(e)=>{
    setCustomerphone(e.target.value);
  };

  const handleorderdesc=(e)=>{
    setOrderdesc(e.target.value);
  };
  const handleduedate=(e)=>{
    setDuedate(e.target.value);
  };
  const handlecelebrantname=(e)=>{
    setCelebrantname(e.target.value);
  };
  const handlecelebrantage=(e)=>{
    setCelebrantage(e.target.value);
  };
  const handlecelebrantgender=(e)=>{
    setCelebrantgender(e.target.value);
  };
  
  let name;
  let address;
  let phone;
  let description;
  let due_date_time;
  let celebrant_name;
  let celebrant_age;
  let celebrant_gender; 
  
  
return(
    <>
    <main id="main" className="main">
    
    <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Specify drawer amount before closing</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <input type="text" 
                       value={shiftamountclose}
                       onChange={handleshiftamountclose}
                       placeholder="Enter amount" className="form-control"/>
                      
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handleSubmitshiftclose} disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''}>Save changes {submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="snacksmodal" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Quantity</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                   
                        
                        <div className="text-center">
                        <h1>{snackname.toUpperCase()}</h1>
                        <h3>In Stock: {quantitystock}</h3>
                        <label>Quantity</label><br/>
                        <button className="btn btn-primary" onClick={handlequantityminussnack}><i className="fa fa-minus"></i></button><span className="m-5 mt-5 fw-bold fs-1 fs-md-2 fs-lg-3">{quantityselected}</span><button className="btn btn-primary" onClick={handlequantityplussnack}><i className="fa fa-plus"></i></button>
                        
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div className="text-center">
                        <h3 className="fw-bold fs-1 fs-md-2 fs-lg-3">{totalpriceformat}</h3>
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                      
                      <button type="button" style={{width:'100%'}} className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleSubmitTicketsnacks} >Add to Orders <i className="fa fa-ticket"></i>{submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="reservation" tabindex="-1">
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Reservation</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div className="row"> 
                       
                       {
                        
                        reservationdetails.map(item=>{
                          name=(item.name?item.name:"");
                          address=(item.address?item.address:"");
                          phone=(item.phone?item.phone:"");
                         description=(item.order_description?item.order_description:"");
                         due_date_time=(item.due_date_time?item.due_date_time:"");
                         celebrant_name=(item.celebrant_name?item.celebrant_name:"");
                         celebrant_age=(item.celebrant_age?item.celebrant_age:"");
                         celebrant_gender=(item.celebrant_gender?item.celebrant_gender:"");
                          
 
                        })
                       }
                        <div className="col-lg-6">
                        <label>Name: {name}</label><br/>
                             
                              
                        <label>Address: {address}</label><br/>
                            
                        <label>Phone Number: {phone}</label><br/>
                          

                        <label>Due Date : {due_date_time}</label><br/>
                        

                      <label>Celebrants Name: {celebrant_name} </label><br/>
                     
                       <label>Age : {celebrant_age}</label><br/>
                     
                       <label>Gender: {celebrant_gender}</label><br/>
                     
                       <label>Order Description : {description}</label><br/>
                        </div>
                        <div className="col-lg-6">
                                
                             <center>
                            <img src={imageUrl} style={{borderRadius: '10px',marginTop:'20px',maxHeight:'400px',justifyContent:'center'}}/>
                            </center> 
                        </div>
                        
                      </div>
                    </div>
                    

                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      </div>
                  </div>
                </div>
              </div>


              <div className="modal fade" id="updatemodal" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{ticketname}</h5>
                      <button type="button" ref={buttonRef} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    
                        
                        <div className="text-left">
                         <label>Amount Received </label>{isRequired?(<><span className="float-end badge bg-danger"><i className="fa fa-exclamation-circle"></i></span></>):(<></>)}
                         <input type="number" className={isRequired?"form-control border-danger":"form-control"} onChange={handleamountreceivedres} placeholder="enter amount received"/>
                          <br/><label>Pay Balance Amount  </label>{isRequired2?(<><span className="float-end badge bg-danger"><i className="fa fa-exclamation-circle"></i></span></>):(<></>)}
                          <input type="number" className={isRequired2?"form-control border-danger":"form-control"} onChange={handlebalancetopayres} placeholder="enter amount"/>
                          <br/><label><h5>Total Balance Amount :  {updatedbalance.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</h5></label>
                         
                        </div>
                        
                        <div className="text-left">
                        <h5>Change: {amountchangeres.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</h5>
                        </div>
                        
                    </div>
                    <div className="modal-footer">
                      
                      <button type="button" style={{width:'100%'}} className="btn btn-primary" disabled={submitting?(<i className="fa fa-spinner fa-spin"></i>):''} onClick={handleClickdone2}>Add to Sales <i className="fa fa-ticket"></i>{submitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>



<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Orders</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Orders</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
       
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <input type="date" onChange={getTicketordersfilter} /><br/><br/>
            <input type="text" 
             value={searchQuery}
             onChange={(e) => setSearchQuery(e.target.value)}
             className="form-control col-lg-4" placeholder="search ticket"/>
            
            </div>
           
            <div className="card-body">
              

            
                <div className="col-lg-12">
                    
                <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                        <li className="nav-item flex-fill" role="presentation">
                            <button className="nav-link w-100" onClick={getTicketorders} id="home-tab" data-bs-toggle="tab" data-bs-target="#home-justified" type="button" role="tab" aria-controls="home" aria-selected="true">
                           REFRESH TICKETS <i className="fa fa-refresh"></i> 
                            </button>
                        </li>
                       
                        </ul>
                        <div className="tab-content pt-2" id="myTabjustifiedContent">
                        <div className="tab-pane fade show active" id="home-justified" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row" style={{maxHeight:500, overflowY: 'scroll'}}>
                        
                        {filteredOrders ? (
                            
                            filteredOrders.map(inv=>{
                              let preparing;
                              if(inv.server_status==='preparing'){
                                preparing=true;
                              }else{
                                preparing=false;
                              }
                              let ttlamount=0; 
                              let amountreserved=0;
                              let deposit=0;
                              let balance=0;
                              let amountreceived=0;
                              let amountchange=0;
                              amountreserved=inv.amount;
                              deposit=inv.deposit;
                              balance=inv.balance; 
                              amountreceived=inv.amount_received;
                              let details = [];
                              if (typeof inv.item_details === 'string' && inv.item_details.length > 0) {
                                let arr = inv.item_details.split(",");  // Split by comma
                                
                                if(arr.length>0){
                                  for (let i = 0; i < arr.length; i++) {
                                    let farr = arr[i].split("-");  // Split each item by '-'
                                    
                                    let itms = {
                                      'status': farr[0],
                                      'id': farr[1],
                                      'amount': farr[2],
                                      'quantity': farr[3],
                                      'item_name': farr[4]
                                    };
                                    
                                    details.push(itms);
                                  }
                                }else{
                                  let farr = arr[0].split("-");  // Split each item by '-'
                                    
                                    let itms = {
                                      'status': farr[0],
                                      'id': farr[1],
                                      'amount': farr[2],
                                      'quantity': farr[3],
                                      'item_name': farr[4]
                                    };
                                    
                                    details.push(itms);
                                }
                              }
                              
                              
                              
                              
                              
                              // Check if all items are canceled
                              let allCanceled = inv.item_details.every(order => order.status === 'canceled');
                              
                              if (allCanceled) {
                                // Handle the case where all items are canceled
                              } else {
                                // Handle other cases
                                let imageUrls="https://megaxsolutions.com/api/middleware/uploads/"+inv.photo;
  
                             
                                  return (
                                     
                                      <div className="col-lg-12 rounded shadow p-1">
                                        <div 
                                         ref={divRef}
                                         className={`row clickable ${clicked ? 'clicked' : ''}`}
                                         onClick={()=>handleClickdetails(inv.ticket_name,inv.photo)}

                                        data-bs-toggle="modal" data-bs-target="#reservation"
                                         style={{ '--ripple-left': rippleStyle.left, '--ripple-top': rippleStyle.top }}
                                        
                                          >
                                         <div className="card-body">
                                              <h5 className="card-title">{inv.ticket_name} <br/><span> {inv.is_reservation?(<>Reserved by: {inv.customer_name} <br/><i>Due Date:  {inv.due_date_time}</i></>):(<></>)}</span> </h5>
  
                                              <div className="d-flex align-items-center row">
                                                  
                                                  <div className="col-lg-6">
                                                   <table className="table">
                                                    <thead>
                                                      <tr>
                                                        <th>ITEM</th>
                                                        <th>QNTTY</th>
                                                        <th>AMOUNT</th>
                                                      
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                    {inv.item_details.map(it=> 
                                                       
                                                     {
                                                      
                                                      if(it.status!='canceled'){
                                                        ttlamount=ttlamount+Number(it.amount);
                                                        if(amountreceived){
                                                          amountchange=Number(amountreceived)-ttlamount;
                                                        }
                                                      }
                                                      
                                                     
                                                     
                                                      
  
                                                     
                                                    return(
                                                      <>
                                                      <tr>
                                                        <td><span class="text-muted small pt-2 ps-1"> {it.item_name} </span></td>
                                                        <td> <span class="text-muted small pt-2 ps-1"> {it.quantity} </span></td>
                                                        <td> <span class="text-success small pt-1 fw-bold">&#8369;{it.amount}</span> </td>
                                                    
                                                        
                                                      </tr>
                                                      
                                                   
                                                      </>
                                                    )
                                                   
                                                    }
                                                    )}
  
                                                    {
                                                      (inv.is_reservation==1)? (
                                                      <>
                                                       <tr>
                                                          <td></td>
                                                          <td>TOTAL</td>  
                                                          <td><span class="text-success large pt-1 fw-bold">{amountreserved? amountreserved.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }):0}</span></td>
                                                          
                                                      </tr>
                                                      <tr>
                                                          <td></td>  
                                                          <td>DEPOSIT</td>
                                                          <td><span class="text-success large pt-1 fw-bold">{deposit?deposit.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }):0}</span></td>
                                                        
                                                      </tr>
                                                      <tr>
                                                          <td></td>  
                                                          <td>BALANCE</td>
                                                          <td><span class="text-success large pt-1 fw-bold">{balance?balance.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }):0}</span></td>
                                                         
                                                      </tr>
                                                      </>
                                                      ):(
                                                        <>
                                                         <tr>
                                                          <td></td>
                                                          <td>TOTAL</td>  
                                                          <td><span class="text-success large pt-1 fw-bold">{ttlamount?ttlamount.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }):0}</span></td>
                                                         
                                                      </tr>
                                                      <tr>
                                                          <td></td>  
                                                          <td>AMOUNT RECEIVED</td>
                                                          <td><span class="text-success large pt-1 fw-bold">{amountreceived? amountreceived.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }):0}</span></td>
                                                          
                                                      </tr>
                                                      <tr>
                                                          <td></td>  
                                                          <td>CHANGE</td>
                                                          <td><span class="text-success large pt-1 fw-bold">{amountchange?amountchange.toLocaleString('en-US', { style: 'currency', currency: 'PHP' }):0}</span></td>
                                                         
                                                      </tr>
                                                        </>
                                                        )
                                                    }
                                                     
                                                    </tbody>
                                                   </table>
                                                  </div>
                                                  <div className="col-lg-6">
                                                  {
                                                    preparing? (
                                                      <>
                                                        <span className="badge bg-warning float-end">{inv.server_status} <i className="fa fa-spinner fa-spin"></i></span>
                                                      </>
                                                    ):(
                                                    
                                                    <>
                                                    
                                                    <span className={inv.server_status? 'badge bg-success float-end':'badge bg-danger float-end'}>{inv.server_status? inv.server_status:'pending'} {inv.server_status? (<><i className="fa fa-check"></i></>):(<><i className="fa fa-exclamation-circle"></i></>)} </span>
                                                     
                                                    </>
                                                    )
                                                  }
                                                  
                                                  <img src={imageUrls} style={{maxHeight:'200px',borderRadius:'10px'}}/>
                                                  
                                                
                                                  </div>
                                                  
                                              </div>
                                              
                                                 
                                              </div>  
                                              
  
                                                  
                                        </div>
                                      </div>
                                    )}
                                  }
                                  )
                        ) : (
                         
                           
                          <></>

                       
                        )}
                        
                         </div>
                         </div>
                        
                       
                        
                        </div>
                </div>
                
           

             
                        
              

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default OrdersviewerContent;
import {React,useState,useEffect,useRef} from "react";
import Clocks from "./Clocks";
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import './style.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

function PayrollContent(){
  const printRef = useRef();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [date, setDate] = useState(new Date());  
  const [empid,setEmpID]=useState('');
  const [empname,setfName]=useState('');
  const [myToken, setToken] = useState('');
  const [myTokenID, setTokenID] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [isGetting, setisGetting] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [branches, setBranches] = useState([]);
  const [levels, setLevels] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [dailyrate, setDailyrate] = useState('');
  const [bonus, setBonus] = useState('');
  const [others, setOthers] = useState(null);
  const [philhealth, setPhilhealth] = useState('');
  const [pagibig, setPagibig] = useState('');
  const [sss, setSss] = useState('');
  const [tax, setTax] = useState('');
  const [myshift, setShift] = useState('');
  const [cutoffID, setCutoffID] = useState('');
  const [period, setPeriod] = useState('');
  const [attendance, setAllattendance] = useState([]);
  const [tdays, setTotaldays] = useState('');
  const [thrs, setTotalhrs] = useState('');
  const [tpay, setTotalpay] = useState('');
  const [lates, setLates] = useState('');
  const [undertime, setUndertime] = useState('');
  const [absent, setAbsent] = useState('');



  const handlePrint = () => {
    const printContent = printRef.current;
    const WindowPrint = window.open('', '', 'width=700,height=800');
   // WindowPrint.document.write(printContent.innerHTML);
    WindowPrint.document.write(`
        <html>
          <head>
            <title>Print Modal</title>
            <style>
              /* Include your styles here */
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
              }
              .modal-content {
                border: 1px solid #ddd;
                padding: 20px;
                border-radius: 5px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
              }
              .modal-header, .modal-footer {
                padding: 10px;
                border-bottom: 1px solid #eee;
              }
              .modal-header {
                border-bottom: none;
              }
              .modal-body {
                margin: 10px 0;
              }
              .modal-footer {
                border-top: 1px solid #eee;
                text-align: right;
              }
            </style>
            <!-- Include Bootstrap CSS if necessary -->
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
          </head>
          <body>
            ${printContent.innerHTML}
          </body>
        </html>
      `);
      WindowPrint.document.close();
      WindowPrint.focus();
      WindowPrint.print();
      WindowPrint.close();
  };
  const handlephilhealth = (e)=>{
    setPhilhealth(e.target.value);
  };
  const handlepagibig = (e)=>{
    setPagibig(e.target.value);
  };
  const handlesss = (e)=>{
    setSss(e.target.value);
  };
  const handletax = (e)=>{
    setTax(e.target.value);
  };
  const handlebonus = (e)=>{
    setBonus(e.target.value);
  };
  const handleothers = (e)=>{
    setOthers(e.target.value);
  };

  useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
  
   const token = getCookie('token');
   const tokenID = getCookie('tokenID');
   setToken(token);
   setTokenID(tokenID);
    if (token) {
       
      validate();
    }

    fetchAllLevels();
    fetchAllBranches();
    fetchCutoff();
  }, [myToken,myTokenID]);
  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
         
        }else{
          navigate('/login');
        }
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllBranches = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getBranches.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         //console.log(response.data);
        setBranches(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchAllLevels = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllLevels.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        //console.log(response.data);
        setLevels(response.data);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const fetchCutoff = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getCutoff.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setSelectedOption(response.data);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
  const handleselectChange = (selectedOption) => {
    fetchAllEmployees(selectedOption['value']);
    setCutoffID(selectedOption['value']);
    console.log(`Option selected:`, selectedOption);
  };

  const fetchAllEmployees = async (cutoffID) => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('cutoffID', cutoffID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllEmployeespayroll.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setEmployees(response.data);
       

        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  const handleSubmit=async()=>{
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('empID', empid);
    formData.append('cutoffID', cutoffID);
    formData.append('philhealth', philhealth);
    formData.append('pagibig', pagibig);
    formData.append('sss', sss);
    formData.append('tax', tax);
    formData.append('bonus', bonus);
    formData.append('others', others);

    setisSubmitting(true);
 
    try {
      const response = await axios.post('https://megaxsolutions.com/api/middleware/addDeductionsandbonus.php', formData, {
          headers: {
              'Content-Type': 'application/form-data'
          }
      });
      console.log(response.data);
      if(response.data['success']){
        fetchAllEmployees(cutoffID);
        Swal.fire({
            title: "Great!",
            text: "Changes Saved",
            icon: "success"
          });
      }else{
        Swal.fire({
            title: "Ops!",
            text: "Changes not Saved"+ response.data['success'],
            icon: "info"
          });
      }
      setisSubmitting(false);
  } catch (error) {
      console.error('Error submitting form', error);
  }

  };
  const handlePayslip=(empIDs,name,period,rate,bonus,phil,pag,sss,tax,totaldays,totalhrs,totalpay,lates,undertimes,absents)=>{
    setEmpID(empIDs);
    setfName(name);
    setDailyrate(rate);
    setBonus(bonus);
    setPhilhealth(phil);
    setPagibig(pag);
    setSss(sss);
    setTax(tax);
    setPeriod(period);
    setTotalpay(totalpay);
    setTotaldays(totaldays);
    setTotalhrs(totalhrs);
    setLates(lates);
    setUndertime(undertimes);
    setAbsent(absents);
    
  };
const handleGet=(empIDs,name,cID,rate,bonus,phil,pag,sss,tax,other)=>{
  setEmpID(empIDs);
  setfName(name);
  setCutoffID(cID);
  setDailyrate(rate);
  setBonus(bonus);
  setPhilhealth(phil);
  setPagibig(pag);
  setSss(sss);
  setTax(tax);
  setOthers(other);
  
};
const handleRefresh=async(empID)=>{
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('cutoffID', cutoffID);
    formData.append('empID', empID);
   //console.log(cutoffID);
    setisLoading(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/recalculatePayroll.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        fetchAllEmployees(cutoffID);
        setisLoading(false);
    } catch (error) {
        console.error('Error submitting form', error);
    }   
};
const handlelogs=async(empID,cutoffID)=>{
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('cutoffID', cutoffID);
    formData.append('empID', empID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getAllattendance.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        console.log(response.data);
        setAllattendance(response.data);
        
    } catch (error) {
        console.error('Error submitting form', error);
    }   
};
const handleDateChange = (newDate) => {
    setDate(newDate);
    console.log(newDate);
    fetchShift(newDate);
  };

  const fetchShift = async (date) => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    formData.append('date', date);
    formData.append('empID', empid);
   setisGetting(true);
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/getEmployeeshift.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
        setisGetting(false);
        console.log(response.data);
        setShift(response.data);
       
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');

 // Filtered ticket orders based on the search query
 const filteredOrders = employees.filter(item =>
   item.name.toLowerCase().includes(searchQuery.toLowerCase())
 );
return(
    <>
    <main id="main" className="main">
    <div className="modal fade" id="attendancecentered" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Employee Attendance Logs</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">

                          <div class="card">
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <h5 class="card-title">{empname}</h5>
                                    </div>  
                                       
                                </div>
                                
                                <div className="col-lg-12">
                                    <table className="table table-hover"> 
                                       <thead>
                                       <tr>
                                            
                                            <th>EmpID</th>
                                            <th>Date</th>
                                            <th>Log</th>
                                            <th>Type</th>

                                        </tr>         
                                       </thead>
                                        <tbody>
                                        {attendance? (
                                        attendance.map(item=>
                                            {
                                            return(
                                                <>
                                                <tr>
                                                
                                                <td>{item.empID}</td>
                                                <td>{item.date}</td>
                                                <td>{item.date_time}</td>
                                                <td>{item.clock_type}</td>
                                                </tr>
                                                
                                                    
                                                </>
                                            )
                                    })) : (
                                        <div>Fetching ....</div>
                                    )}    
                                        </tbody>
                                    </table>
                                    
                                   
                                </div>
                                
                                 
                                
                              </div>

                            </div>
                          </div>

                          </div>
                        </div>
                                    
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      </div>
                  </div>
                </div>
              </div>
              
            <div className="modal fade" id="calendarcentered" tabindex="-1">
                <div className="modal-dialog">
                  <div className="modal-content" >
                    <div className="modal-header">
                    <h5 className="modal-title">Employee PaySlip  </h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" ref={printRef}>
                    <div class="row">
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">
                          
                          <div class="card">
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <h5 class="card-title">{empname}</h5>
                                    </div>  
                                       
                                </div>
                                
                                <div className="row">
                                    <div className="row">
                                    <div className="col-lg-6">
                                        <label>Pay Period: {period}</label>     
                                        </div>
                                        <div className="col-lg-6">
                                        <label>Daily Rate: &#x20B1;{dailyrate}</label>      
                                        </div>
                                        <div className="col-lg-6">
                                        <label>Total Hrs: {thrs}</label>     
                                        </div>
                                        <div className="col-lg-6">
                                        <label>Total Days: {tdays}</label>    
                                        </div>
                                        <br/>
                                        <div className="col-lg-6">
                                        <label>Absent {absent}</label>    
                                        </div>
                                        <div className="col-lg-6">
                                        <label>Lates: &#x20B1;{lates}</label>    
                                        </div>
                                        <div className="col-lg-12">
                                        <label>Undertime: &#x20B1;{undertime}</label>    
                                        </div>
                                        <br/>
                                        <div className="col-lg-12">
                                        <label>PhilHealth: &#x20B1;{philhealth}</label>   
                                        </div>
                                        <div className="col-lg-12">
                                        <label>SSS: &#x20B1;{sss}</label>  
                                        </div>
                                        <div className="col-lg-12">
                                        <label>Pagibig: &#x20B1;{pagibig}</label>   
                                        </div>
                                        
                                        <div className="col-lg-12">
                                        <label>Witholding Tax: &#x20B1;{tax}</label>  
                                        </div>
                                        <br/>
                                        <div className="col-lg-12">
                                        <label>Bonus: &#x20B1;{bonus}</label>
                                        </div>
                                        <div className="col-lg-12">
                                        <label><strong>Total Pay: &#x20B1;{tpay}</strong></label>
                                        </div>
                                    </div>
                                  
                                   
                                  
                                  
                                </div>
                                
                                 
                                
                              </div>

                            </div>
                          </div>

                          </div>
                        </div>
                                    
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={handlePrint}>Print</button>
                      </div>
                  </div>
                </div>
              </div>
              
            <div className="modal fade" id="verticalycentered" tabindex="-1">
                <div className="modal-dialog modal-l">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Deductions and Bonus</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <div class="row">

                      
                        <div class="col-xxl-12 col-md-12">
                          <div class="card info-card sales-card">

                          <div class="card">
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                    <h5 class="card-title">{empname}</h5>
                                    </div>  
                                    <div className="col-lg-6 shadow">
                                        <label>Daily Rate: </label>
                                        <h3>&#x20B1;{dailyrate}</h3>
                                    </div>    
                                </div>
                              
                              <div class="row g-3 mt-2">
                                <div class="col-md-4 shadow">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <label for="fName" class="form-label">PhilHealth</label>
                                        </div>  
                                           
                                    </div>
                                  
                                </div>
                                <div class="col-md-8 shadow">
                                <input  type="text" className="form-control" value={philhealth} onChange={handlephilhealth}/>
                                        
                                </div>

                                <div class="col-md-4 shadow">
                                     <div className="row">
                                        <div className="col-lg-12">
                                        <label for="fName" class="form-label">Pag-ibig</label>
                                        </div>  
                                            
                                    </div>
                                </div>
                                <div class="col-md-8 shadow">
                                <input  type="text" className="form-control" value={pagibig} onChange={handlepagibig}/>
                                   </div>

                                <div class="col-md-4 shadow">
                                    <div className="row">
                                        <div className="col-lg-12">
                                        <label for="fName" class="form-label">SSS</label>
                                        </div>  
                                         
                                    </div>
                                </div>
                                <div class="col-md-8 shadow">
                                <input  type="text" className="form-control" value={sss} onChange={handlesss}/>
                                 </div>
                                <div class="col-md-4 shadow">
                                <div className="row">
                                        <div className="col-lg-12">
                                        <label for="fName" class="form-label">TAX</label>
                                        </div>  
                                           
                                    </div>
                                </div>
                                <div class="col-md-8 shadow">
                                <input  type="text" className="form-control" value={tax} onChange={handletax}/>
                                  </div>
                                <div class="col-md-4 shadow">
                                <div className="row">
                                        <div className="col-lg-12">
                                        <label for="fName" class="form-label">Bonus</label>
                                        </div>  
                                         
                                    </div>
                                </div>
                                <div class="col-md-8 shadow">
                                <input  type="text" className="form-control" value={bonus} onChange={handlebonus}/>
                                </div>

                                <div class="col-md-4 shadow">
                                <div className="row">
                                        <div className="col-lg-12">
                                        <label for="fName" class="form-label">Others</label>
                                        </div>  
                                         
                                    </div>
                                </div>
                                <div class="col-md-8 shadow">
                                <input  type="text" className="form-control" value={others} onChange={handleothers}/>
                                </div>
                                
                                
                                
                                
                              </div>

                            </div>
                          </div>

                          </div>
                        </div>

                      
                        

                      </div>
                                    
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting?true:false} >Save changes {isSubmitting?(<i className="fa fa-spinner fa-spin"></i>):''}</button>
                    </div>
                  </div>
                </div>
              </div>

<div className="pagetitle">
  <div className="row">
      <div className="col-lg-6">
      <h1>Payroll</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
          <li className="breadcrumb-item active">Employees</li>
        </ol>
       </nav>
      </div>
      <div className="col-lg-6 text-right">
      <Clocks/>
      </div>
  </div>
  
  
</div>

<section className="section dashboard">
  <div className="row">

    
    <div className="col-lg-12">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
                <div className="row mb-5">
                    <div className="col-lg-6">
                    <label>Search</label>
                    <div className="col-lg-12"><input type="text" id="search" 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                     placeholder="search by name" name="search" className="form-control"/></div>
                     
                                     
                    </div>
                    <div className="col-lg-6">
                    <label>Cut-Off</label>
                    <Select
                    onChange={handleselectChange}
                    options={selectedOption}
                    isSearchable
                    placeholder="Select Cut-off"
                    isDisabled={isLoading?true:false}
                    /> 
                    </div>
                
                     </div>
                                  
            </div>
           
            <div className="card-body" style={{overflowY:'scroll', maxHeight:450}}>
            
              <table className="table datatable">
                <thead>
                  <tr>
                    <th scope="col">ID #</th>
                    <th scope="col">Name</th>
                    <th scope="col">Daily Rate</th>
                    <th scope="col">Total Hrs.</th>
                    <th scope="col">Total Present</th>
                    <th scope="col">Total Absent</th>
                    <th scope="col">Total lates</th>
                    <th scope="col">Total Undertime</th>
                    <th scope="col">Bonus</th>
                    <th scope="col">SSS</th>
                    <th scope="col">Pag-ibig</th>
                    <th scope="col">Philhealth</th>
                    <th scope="col">Tax</th>
                    <th scope="col">Others</th>
                    <th scope="col">Total Pay Amount</th>
                    <th scope="col">Action</th>
                   
                  </tr>
                </thead>
                <tbody>

                {isLoading ? (
                            
                            <tr>
                               <th scope="row"><div className="waving col-lg-12"></div></th>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                                <td><div className="waving col-lg-12"></div></td>
                            </tr>
                        ) : (
                          filteredOrders.map(item => {
                           

        
                            return (
                              <tr key={item.id}>
                                <th scope="row">{item.empID}</th>
                                <td>{item.name}</td>
                                <td>&#x20B1;{item.dailyrate}</td>
                                <td>{item.totalhrs}</td>
                                <td>{item.totaldays}</td>
                                <td>{item.absent}</td>
                                <td>({item.lates})</td>
                                <td>({item.undertime})</td>
                                <td>{item.bonus}</td>
                                <td>({item.sss})</td>
                                <td>({item.pagibig})</td>
                                <td>({item.philhealth})</td>
                                <td>({item.tax})</td>
                                <td>({item.others})</td>
                                <td>&#x20B1;{item.totalpay}</td>
                                <td>
                                  <div className="row">
                                    <div className="col-lg-12">
                                    <div class="btn-group" role="group" aria-label="Basic outlined example">
                                    <button className="btn btn-outline-primary" onClick={()=>handleGet(item['empID'],item['name'],item['cutoffID'],item.dailyrate,item.bonus,item.philhealth,item.pagibig,item.sss,item.tax,item.others)} data-bs-toggle="modal" data-bs-target="#verticalycentered" title="Edit"><i className="fa fa-pencil"></i></button>
                                    <button className="btn btn-outline-primary" onClick={()=>handlePayslip(item['empID'],item['name'],item['period'],item.dailyrate,item.bonus,item.philhealth,item.pagibig,item.sss,item.tax,item.totaldays,item.totalhrs,item.totalpay,item.lates,item.undertime,item.absent)} data-bs-toggle="modal" data-bs-target="#calendarcentered" title="payslip"><i className="fa fa-folder"></i></button>
                                    <button className="btn btn-outline-primary" onClick={()=>handleRefresh(item['empID'],item['cutoffID'])} title="Refresh"><i className="fa fa-refresh"></i></button>
                                    <button className="btn btn-outline-primary" onClick={()=>handlelogs(item['empID'],item['cutoffID'])} title="attendance logs" data-bs-toggle="modal" data-bs-target="#attendancecentered"><i className="fa fa-list"></i></button>
                                  
                                    </div>

                                    
                                    </div>
                                   
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
               

                 
                  
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</main>

    </>
);
}
export default PayrollContent;